import { connect } from 'react-redux';

import { getOfficesShortList, getPublicZoneList } from '../../Offices/officesActions';
import { getOrganizationMembersList } from '../../Projects/projectsActions';
import {
  editSubTask,
  getSubTaskEvidence,
  getTaskLocation,
  getTasksList,
  updateTaskApproval,
} from '../tasksActions';
// Component
import TaskApproval from './TaskApproval';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersList: state.project.orgMembersList,
  officesShortList: state.office.officesShortList,
  publicZoneList: state.office.publicZoneList,
  locationsList: state.task.locationsList,
  duplicateIsLoading: state.task.duplicateIsLoading,
  deleteIsLoading: state.task.deleteIsLoading,
  deletedCallback: state.task.deletedCallback,
  tasksList: state.task.tasksList,
  createdTaskInfo: state.task.createdTaskInfo,
  duplicateTaskInfo: state.task.duplicateTaskInfo,
  isLoading: state.task.isLoading,
  loadingTaskAssigneeId: state.project.loadingTaskAssigneeId,
  // pagination
  totalTasksCount: state.task.tasksListCount,
  pageSize: state.task.taskListPageSize,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersList: params =>
    dispatch(getOrganizationMembersList(params)),
  getTasksList: params => dispatch(getTasksList(params)),
  getOfficesShortList: params => dispatch(getOfficesShortList(params)),
  updateTaskApproval: params => dispatch(updateTaskApproval(params)),
  editSubTask: params => dispatch(editSubTask(params)),
  getSubTaskEvidence: params => dispatch(getSubTaskEvidence(params)),
  getTaskLocation: params => dispatch(getTaskLocation(params)),
  getPublicZoneList: params => dispatch(getPublicZoneList(params)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(TaskApproval);
