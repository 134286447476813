import styled, { css } from 'styled-components';

import { RoundImageTooltip } from '../../styledComponents/createProject';
import { ActionTooltip, ChecklistsTooltip } from '../../styledComponents/tasks';

export const ActionIconSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30px);
  gap: 12px;
  justify-content: center;
  align-items: center;
`;
export const RoundIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? width : `30px`)};
  height: ${({ height }) => (height ? height : `30px`)};
  border-radius: 50px;
  border: 1px solid;
  border-color: ${({ type }) =>
    type === 'edit' ? `#434343` : type === 'duplicate' ? `#6776CB` : `#EF717D`};
  background-color: ${({ theme }) => theme.colors.main.white};
  cursor: pointer;
  &:hover {
    box-shadow: 3px 3px 10px #c2cce1;
  }
  &:hover ${ActionTooltip} {
    display: flex;
  }
`;

export const CheckListTooltipContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: fit-content; /* Add width */
  height: fit-content; /* Add height */
  &:hover ${ChecklistsTooltip} {
    display: flex;
  }
`;

export const CollapseSection = styled.div`
  display: grid;
  height: ${({ open }) => (open ? `auto` : `0`)};
  transition: height 0.5s ease;
  overflow: hidden;
`;

export const TaskInfoSection = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(100px, 120px)) minmax(200px, 250px);
  gap: 30px;
  justify-content: space-evenly;
  align-items: center;
  padding: 22px 25px;
  border-radius: 10px;
  border: 1px solid #c2cce1;
  background-color: ${({ theme }) => theme.colors.main.white};
  margin: 0px 20px 20px;
`;

export const SubTaskInfoSection = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: start;
  padding: 22px 25px;
  border-radius: 10px;
  border: 1px solid #c2cce1;
  background-color: ${({ theme }) => theme.colors.main.white};
  margin: 0px 20px 20px;
`;
export const TaskStatusButton = styled.div`
  border-radius: ${({ theme }) => theme.borderRadii.button};
  padding: 5px;
  background-color: ${({ bgColor, status, theme }) =>
    bgColor
      ? bgColor
      : status && status === 'partially complete'
      ? theme.colors.main.warning
      : status && status === 'completed'
      ? theme.colors.main.success
      : status && status === 'totally missed'
      ? theme.colors.main.error
      : theme.colors.main.gray};
  color: ${({ textColor, status, theme }) =>
    textColor ? textColor : theme.colors.main.white};
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
`;
export const ApprovalBadge = styled.div`
  border-radius: 35px;
  border: 1px solid;
  border-color: ${({ bgColor, status, theme }) =>
    bgColor
      ? bgColor
      : status && status === 'pending'
      ? theme.colors.main.warning
      : status && status === 'approved'
      ? theme.colors.main.success
      : status && status === 'rejected'
      ? theme.colors.main.error
      : theme.colors.main.gray};
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.colors.main.white};
  color: ${({ bgColor, status, theme }) =>
    bgColor
      ? bgColor
      : status && status === 'pending'
      ? theme.colors.main.warning
      : status && status === 'approved'
      ? theme.colors.main.success
      : status && status === 'rejected'
      ? theme.colors.main.error
      : theme.colors.main.gray};
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
`;

export const AssigneeRoundImg = styled.div`
  width: 40px;
  height: 40px;
  background-image: ${({ image }) => image && `url(${image})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  position: relative;
  &:hover ${RoundImageTooltip} {
    display: flex;
  }
`;

export const CheckListDropdownContainer = styled.div`
  position: absolute;
  /* left: ${({ left }) => left || '0px'}; */
  /* right: -300px; */
  max-height: ${({ height }) => height || '300px'};
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  grid-row-gap: ${({ gap }) => gap || '0'};
  width: ${({ width }) => width || '260px'};
  padding: ${({ padding }) => padding || '0 0 10px'};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: ${({ theme }) => theme.dropdown.borderRadius};
  z-index: 5;
  background-color: ${({ theme }) => theme.colors.main.white};

  ::-webkit-scrollbar {
    width: ${({ theme }) => theme.dropdown.scrollbar.width};
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.dropdown.scrollbar.trackBgColor};
    border-radius: ${({ theme }) => theme.dropdown.scrollbar.thumbBorderRadius};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.dropdown.scrollbar.thumbBgColor};
    border-radius: ${({ theme }) => theme.dropdown.scrollbar.thumbBorderRadius};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.dropdown.scrollbar.thumbHoverBgColor};
  }
  top: 100px;
  /* bottom: ${({ bottom }) => bottom || '-70px'}; */

  @media (max-width: 1350px) {
    top: 100px;
    right: 0px;
    max-width: 100vw;
    overflow: auto;
  }
`;

export const CheckListDropdownItem = styled.div`
  padding: ${({ padding }) => padding || '7px 15px'};
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.dropdown.option.hoverBgColor};
  }
`;

export const DefaultChecklistNotFoundModalContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
`;
