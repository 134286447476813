import { useTranslation } from 'react-i18next';

import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import {
  ColoredText,
  CommonFlex,
  CommonText,
  Container,
  RoundLetterSection,
  TableText,
} from '../../styledComponents/common';
import { AssignedProjectBox, TableItem } from '../../styledComponents/teams';
import { CommFirstRoundLetterComp } from '../Teams/CommonComponents';
import {
  TaskLocationsTableHeaderContainer,
  TaskLocationsTableRowContainer,
} from './taskLocationsStyles';

const TaskLocationsTable = props => {
  const { t } = useTranslation();

  const { locationsList, isLoading, handleShowLocation } = props;

  return (
    <Container style={{ paddingBottom: '20px' }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <TaskLocationsTableHeaderContainer>
            <TableItem>
              <TableText>{t('location_name')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('zone')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('registration_number')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('location_address')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('location_radius')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('location')}</TableText>
            </TableItem>
          </TaskLocationsTableHeaderContainer>
          {locationsList && locationsList.length > 0 ? (
            locationsList.map((location, index) => (
              <TaskLocationsTableRowContainer key={location.id}>
                <TableItem>
                  <CommFirstRoundLetterComp
                    text={location.name}
                    backColor={index}
                    underline={false}
                  />
                </TableItem>
                <TableItem>
                  <CommonText>
                    {location.office && location.office.id
                      ? location.office.name
                      : '-'}
                  </CommonText>
                </TableItem>
                <TableItem>
                  <CommonText>
                    {location.house_reg_no ? location.house_reg_no : '-'}
                  </CommonText>
                </TableItem>
                <TableItem>
                  <CommonText>{location.address}</CommonText>
                </TableItem>
                <TableItem>
                  <CommonText>{location.radius} m</CommonText>
                </TableItem>
                <TableItem>
                  <ColoredText
                    type="warning"
                    hover
                    onClick={() => handleShowLocation(location)}
                    fontSize="14px"
                    style={{ cursor: 'pointer' }}
                  >
                    {t('see_location')}
                  </ColoredText>
                </TableItem>
              </TaskLocationsTableRowContainer>
            ))
          ) : (
            <NoDataComponent
              title={t('no_task_locations_found')}
              padding="5vh 0"
              imageHeight="140px"
            />
          )}
        </>
      )}
    </Container>
  );
};
export default TaskLocationsTable;
