//Effects
import { all, put, takeLatest } from 'redux-saga/effects';

//Constants
import {
  DASHBOARD_TOP_LOW_PERFORMERS,
  DASHBOARD_TOP_LOW_PERFORMERS_FAILURE,
  DASHBOARD_TOP_LOW_PERFORMERS_SUCCESS,
  GET_DASHBOARD_LATE_COUNT,
  GET_DASHBOARD_LATE_COUNT_FAILURE,
  GET_DASHBOARD_LATE_COUNT_SUCCESS,
  GET_DASHBOARD_PERFORMANCE,
  GET_DASHBOARD_PERFORMANCE_FAILURE,
  GET_DASHBOARD_PERFORMANCE_SUCCESS, //New Api
  GET_DASHBOARD_STATUS,
  GET_DASHBOARD_STATUS_FAILURE,
  GET_DASHBOARD_STATUS_SUCCESS,
  GET_DASHBOARD_TASK_PROGRESS,
  GET_DASHBOARD_TASK_PROGRESS_FAILURE,
  GET_DASHBOARD_TASK_PROGRESS_SUCCESS,
  GET_DASHBOARD_TASK_SUMMARY,
  GET_DASHBOARD_TASK_SUMMARY_FAILURE,
  GET_DASHBOARD_TASK_SUMMARY_SUCCESS,
  GET_DASHBOARD_USER_ANALYTICS,
  GET_DASHBOARD_USER_ANALYTICS_FAILURE,
  GET_DASHBOARD_USER_ANALYTICS_SUCCESS,
} from '../../modules/constants';
//Service
import { defaultApi } from '../../utils/axiosApi';

//New Api calls
export function* dashboardStatusRequest({ payload }) {
  // const { } = payload;
  try {
    const url = `albait/dashboard/status/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_STATUS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_STATUS_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardTaskProgressRequest({ payload }) {
  // const {} = payload;
  try {
    const url = `albait/dashboard/public-zone-wise-task-progress/`;
    // const url = `albait/dashboard/officewise-task-progress/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_TASK_PROGRESS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_TASK_PROGRESS_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardTaskSummaryRequest({ payload }) {
  // const {} = payload;
  try {
    const url = `albait/dashboard/task-summary/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_TASK_SUMMARY_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_TASK_SUMMARY_FAILURE,
      payload: err,
    });
  }
}
export function* dashboardPeformanceRequest({ payload }) {
  // const {date} = payload;
  try {
    const url = `albait/dashboard/performance/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_PERFORMANCE_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_PERFORMANCE_FAILURE,
      payload: err,
    });
  }
}
export function* dashboardLateCountRequest({ payload }) {
  // const { date } = payload;
  try {
    const url = `albait/dashboard/late-count/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_LATE_COUNT_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_LATE_COUNT_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardTopLowPerformersRequest({ payload }) {
  // const { } = payload;
  try {
    const url = `albait/dashboard/top-low-performers/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: DASHBOARD_TOP_LOW_PERFORMERS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: DASHBOARD_TOP_LOW_PERFORMERS_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardUserAnalyticsRequest({ payload }) {
  const { date } = payload;
  try {
    const url = `albait/dashboard/today-activity/`;
    let method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_USER_ANALYTICS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_USER_ANALYTICS_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    //new api
    takeLatest(GET_DASHBOARD_STATUS, dashboardStatusRequest),
    takeLatest(GET_DASHBOARD_TASK_PROGRESS, dashboardTaskProgressRequest),
    takeLatest(GET_DASHBOARD_TASK_SUMMARY, dashboardTaskSummaryRequest),
    takeLatest(GET_DASHBOARD_PERFORMANCE, dashboardPeformanceRequest),
    takeLatest(GET_DASHBOARD_LATE_COUNT, dashboardLateCountRequest),
    takeLatest(DASHBOARD_TOP_LOW_PERFORMERS, dashboardTopLowPerformersRequest),
    takeLatest(GET_DASHBOARD_USER_ANALYTICS, dashboardUserAnalyticsRequest),
  ]);
}
