import {
  GET_ALL_INTEGRATIONS_LIST,
  GET_INTEGRATION_WORKFLOWS_LIST,
  GET_ENABLED_INTEGRATIONS_LIST,
  GET_INTEGRATION_ENABLED_WORKFLOWS_LIST,
  ENABLE_INTEGRATION_WORKFLOW,
  RUN_INTEGRATION_WORKFLOW,
} from "../../modules/constants";

export function getAllIntegrationsList(payload) {
  return {
    type: GET_ALL_INTEGRATIONS_LIST,
    payload,
  };
}

export function getIntegrationWorkflowsList(payload) {
  return {
    type: GET_INTEGRATION_WORKFLOWS_LIST,
    payload,
  };
}

export function getEnabledIntegrationsList(payload) {
  return {
    type: GET_ENABLED_INTEGRATIONS_LIST,
    payload,
  };
}

export function getIntegrationEnabledWorkflowsList(payload) {
  return {
    type: GET_INTEGRATION_ENABLED_WORKFLOWS_LIST,
    payload,
  };
}

export function enableIntegrationWorkflow(payload) {
  return {
    type: ENABLE_INTEGRATION_WORKFLOW,
    payload,
  };
}

export function runIntegrationWorkflow(payload) {
  return {
    type: RUN_INTEGRATION_WORKFLOW,
    payload,
  };
}
