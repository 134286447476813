import styled, { css } from 'styled-components';

export const ProjectListContainer = styled.div`
  margin-top: 20px;
`;
export const ProjectDetailsContainer = styled.div`
  padding: ${({ padding }) => padding || '0 30px'};
`;
export const ProjectStatusBadge = styled.span`
  height: 24px;
  border-radius: 14px;
  padding: 0px 10px;
  background-color: ${({ bgColor, status, theme }) =>
    bgColor
      ? bgColor
      : status && status === 'to do'
      ? '#e5ebf2'
      : status && status === 'complete'
      ? theme.colors.main.primaryLight
      : theme.colors.main.blueLight};
  color: ${({ textColor, status, theme }) =>
    textColor
      ? textColor
      : status && status === 'to do'
      ? theme.colors.main.gray
      : status && status === 'complete'
      ? theme.colors.main.success
      : theme.colors.main.blue};
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
`;
export const ProjectListStatusBadge = styled.div`
  border-radius: ${({ theme }) => theme.borderRadii.button};
  padding: 5px;
  background-color: ${({ bgColor, status, theme }) =>
    bgColor
      ? bgColor
      : status && status === 'to do'
      ? theme.colors.main.gray
      : status && status === 'complete'
      ? theme.colors.main.success
      : theme.colors.main.blue};
  color: ${({ textColor, status, theme }) =>
    textColor ? textColor : theme.colors.main.white};
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
`;
export const ProjectExpandButton = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  background-color: ${({ bgColor, theme }) =>
    bgColor || theme.colors.main.textMain};
  margin: ${({ margin }) => (margin ? margin : 'auto')};
  cursor: ${({ cursor }) => (cursor ? cursor : 'pointer')};
  &:hover {
    background-color: ${({ hoverBgColor, theme }) =>
      hoverBgColor || theme.colors.hover.grayMedium};
  }
`;
export const ExpandIcon = styled.img`
  height: 14px;
  width: 14px;
  transform: ${({ open }) => (open ? `rotate(90deg)` : `rotate(0deg)`)};
  transition: transform 0.8s ease;
`;
export const EditProjectButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height || '36px'};
  font-size: ${({ fontSize }) => fontSize || `13px`};
  padding: 0 8px;
  background-color: ${({ bgColor, type, theme }) =>
    bgColor
      ? bgColor
      : type === 'archive'
      ? theme.colors.border.secondaryLight
      : theme.colors.main.whiteBg};
  border: 1px solid
    ${({ borderColor, type, theme }) =>
      borderColor
        ? borderColor
        : type === 'delete' || type === 'archive'
        ? theme.colors.border.secondaryLight
        : theme.colors.border.primary};
  color: ${({ textColor, type, theme }) =>
    textColor
      ? textColor
      : type === 'delete'
      ? theme.colors.main.error
      : theme.colors.main.textMain};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  cursor: pointer;
  &:hover {
    background-color: ${({ type, theme }) =>
      type === 'edit'
        ? theme.colors.border.primary
        : type === 'archive'
        ? '#d2d8e3'
        : theme.colors.main.whiteBg};
    border-color: ${({ type, theme }) =>
      type === 'delete'
        ? theme.colors.border.error
        : type === 'archive'
        ? '#d2d8e3'
        : theme.colors.border.primary};
    color: ${({ type, theme }) => type === 'edit' && theme.colors.main.white};
  }
`;
export const DeadlineText = styled.div`
  color: ${props => (props.name ? `#2F394E` : `rgba(47, 57, 78, 0.7)`)};
  font-size: ${props => (props.fontSize ? props.fontSize : `13px`)};
  font-weight: ${props => props.title && `600`};
  display: flex;
  align-items: center;
`;

export const BudgetLeftSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

export const ProjectCardLabel = styled.span`
  color: rgba(47, 57, 78, 0.7);
  font-size: 13px;
`;
export const ProjectCardText = styled.span`
  font-size: ${({ fontSize }) => fontSize || `13px`};
  font-weight: ${({ fontWeight }) => fontWeight || '500'};
  color: #2f394e;
`;

export const PercentageBarSection = styled.div`
  display: grid;
  grid-template-columns: 80% 20%;
  align-items: center;
  margin-top: 15px;
  @media screen and (max-width: 1400px) {
    grid-template-columns: 70% 30%;
  }
`;
export const PercentageText = styled.span`
  font-size: 13px;
  margin-left: 10px;
`;

export const ProjectCardNoDataContainer = styled.div`
  margin-top: 15px;
  @media screen and (max-width: 1400px) {
    margin-top: 8px;
  }
`;

export const ProjectCardNoDataImg = styled.img`
  height: 36px;
  width: 36px;

  @media screen and (min-width: 1400px) {
    height: 42px;
    width: 42px;
  }
`;

export const ProjectCardNoDataText = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: #4e85f3;
  @media screen and (max-width: 1400px) {
    margin-top: 7px;
  }
`;

export const ProjectDetailsInvoiceTaskContainer = styled.div``;
