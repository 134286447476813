import { TaskStatusOptions } from 'modules/constants';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import excelIcon from '../../assets/img/icons/excel-green.svg';
import plusIcon from '../../assets/img/icons/plus_white.svg';
import ProjectDropdown from '../../components/CommonFilter/ProjectDropdown';
import FilterDropDownStyle from '../../components/DropdownStyle/FilterDropDownStyle';
import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import BottomPagination from '../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../components/Pagination/TopPagination/TopPagination';
import Select from '../../components/ReactSelectDropdown';
import {
  ButtonIcon,
  ColoredButton,
  ColoredButtonWithIcon,
  PrimaryButton,
  WhiteButton,
  WhiteButtonWithIcon,
} from '../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonText,
  FormAlert,
  HeaderContainer,
  PageTitle,
} from '../../styledComponents/common';
import { exportToExcel } from '../../utils/exportToExcel';
import DeleteTaskModal from './DeleteTaskModal';
import TaskFilter from './TaskFilter';
import TasksList from './TasksList';

const Tasks = ({
  selectedOrganization,
  getOrganizationMembersList,
  orgMembersList,
  loadingTaskAssigneeId,
  isLoading,
  officesShortList,
  getOfficesShortList,
  getTasksList,
  tasksList,
  deleteTask,
  deletedCallback,
  deleteIsLoading,
  createdTaskInfo,
  createTask,
  // pagination
  totalTasksCount,
  pageSize,
  history,
  locationsList,
  getTaskLocation,
  getPublicZoneList,
  publicZoneList,
}) => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const statusFromParams = urlParams.get('status');
  const [zoneFromParams, setZoneFromParams] = useState(null);
  const tmpZoneFromParams = urlParams.get('zone');
  if (statusFromParams || tmpZoneFromParams) {
    const newUrl = `${window.location.pathname}`;
    window.history.replaceState({}, '', newUrl);
  }
  useEffect(() => {
    tmpZoneFromParams && setZoneFromParams(tmpZoneFromParams);
  }, [tmpZoneFromParams]);

  const [selectedDate, setSelectedDate] = useState({
    startDate:
      statusFromParams || tmpZoneFromParams
        ? moment('2024-04-01')
        : moment(new Date()),
    endDate: moment(new Date()),
  });
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState({
    label: t('all_locations'),
    value: null,
  });

  const [selectedMember, setSelectedMember] = useState('');
  const [taskStatus, setTaskStatus] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState('');
  const [expandedTaskId, setExpandedTaskId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [taskIdDelete, setTaskIdDelete] = useState(null);

  const [reportLoading, setReportLoading] = useState(false);
  const [allReportLoading, setAllReportLoading] = useState(false);

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [selectedPublicZone, setSelectedPublicZone] = useState('');
  const statusOptions = TaskStatusOptions?.map(option => ({
    ...option,
    label: t(option.key),
  }));

  const onPublicZoneChange = e => {
    setSelectedPublicZone(e);
    setSelectedLocation({
      label: t('all_locations'),
      value: null,
    });
  };
  const selectLocation = e => {
    setCurrentPageNumber(1);
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setSelectedLocation(e);
  };
  useEffect(() => {
    statusFromParams
      ? statusOptions.map(statusOption => {
          if (statusOption.key === statusFromParams) {
            setTaskStatus(statusOption);
          }
        })
      : setTaskStatus({
          label: t('all_status'),
          value: null,
        });
    zoneFromParams &&
      statusOptions.map(statusOption => {
        if (statusOption.key === statusFromParams) {
          setTaskStatus(statusOption);
        }
      });
    getTaskLocation();
    getPublicZoneList();
  }, []);
  useEffect(() => {
    if (zoneFromParams && officesShortList?.length > 0) {
      officesShortList.map(office => {
        if (office.name === zoneFromParams) {
          setSelectedOffice({
            label: office.name,
            value: office.id,
          });
        }
      });
      setZoneFromParams(null);
    } else if (!selectedOffice) {
      setSelectedOffice({
        label: t('all_zones'),
        value: null,
      });
    }
  }, [officesShortList, zoneFromParams]);

  useEffect(() => {
    if (deletedCallback) {
      getTasks();
    }
  }, [deletedCallback]);

  useEffect(() => {
    if (createdTaskInfo) {
      getTasksList({
        startDate: moment(selectedDate.startDate).format('YYYY-MM-DD'),
        endDate: moment(selectedDate.endDate).format('YYYY-MM-DD'),
        page: currentPageNumber,
      });
    }
  }, [createdTaskInfo]);
  const handleSearchByName = () => {
    setCurrentSearchedTerm(searchTerm);
    setSearch(!search);
    setCurrentPageNumber(1);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setSearch(!search);
  };

  const handlePageChange = page => {
    setCurrentPageNumber(page);
  };

  const selectOffice = e => {
    setCurrentPageNumber(1);
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setSelectedOffice(e);
    setSelectedLocation({
      label: t('all_locations'),
      value: null,
    });
  };

  const selectMember = person => {
    setSelectedMember(person);
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setCurrentPageNumber(1);
  };

  const clearMember = () => {
    setSelectedMember('');
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setCurrentPageNumber(1);
  };

  const onSearchTermChange = e => {
    const { value } = e.target;
    if (value !== '') {
      setSelectedMember('');
    }
    setSearchTerm(value);
  };

  const handleStatusChange = e => {
    setTaskStatus(e);
  };

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const toggleDeleteModal = taskId => {
    setIsOpen(!isOpen);
    if (taskId) {
      setTaskIdDelete(taskId);
    }
  };

  const handleDeleteTask = () => {
    if (taskIdDelete) {
      const payload = {
        task_id: taskIdDelete,
      };
      deleteTask(payload);
      toggleDeleteModal();
    }
  };

  const handleExportToExcel = async () => {
    if (selectedOffice && selectedOffice.value) {
      const url = `albait/export/task-report/?date=${moment(
        selectedDate,
      ).unix()}&office=${selectedOffice.value}`;

      setReportLoading(true);
      const response = await exportToExcel(url, null, true);
      setReportLoading(false);
    }
  };

  const handleExportAllOfficesReportToExcel = useDebouncedCallback(
    async () => {
      const baseUrl = `albait/export/task-report/?date=${moment(
        selectedDate,
      ).unix()}`;

      if (officesShortList && officesShortList.length > 0) {
        setAllReportLoading(true);
        const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

        const promises = [];
        officesShortList.forEach(async (office, i) => {
          const promise = (async index => {
            await delay(300 * index);
            const url = `${baseUrl}&office=${office.id}`;
            await exportToExcel(url, `office${office.name}.xlsx`, false);
          })(i);

          promises.push(promise);
        });

        await Promise.all(promises);

        setAllReportLoading(false);
      }
    },
    60000,
    { leading: true, trailing: false },
  );

  const getTasks = () => {
    const payload = {
      startDate: moment(selectedDate.startDate).format('YYYY-MM-DD'),
      endDate: moment(selectedDate.endDate).format('YYYY-MM-DD'),
      page: currentPageNumber,
    };
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }
    if (selectedPublicZone && selectedPublicZone.value) {
      payload.public_zone_id = selectedPublicZone.value;
    }
    if (selectedLocation && selectedLocation.value) {
      payload.location_id = selectedLocation.value;
    }
    if (selectedMember && selectedMember.id) {
      payload.assignee_id = selectedMember.id;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.task_name = searchTerm.toLowerCase().trim();
    }
    if (taskStatus && taskStatus.value && taskStatus.value !== 'all') {
      payload.status = taskStatus.value;
    }
    // Remove null or undefined properties
    const filteredPayload = Object.fromEntries(
      Object.entries(payload).filter(([_, v]) => v != null),
    );

    getTasksList(filteredPayload);
  };
  useEffect(() => {
    getTasks();
  }, [
    currentPageNumber,
    selectedDate,
    selectedOffice,
    selectedMember,
    // searchTerm,
    search,
    taskStatus,
    selectedLocation,
    selectedPublicZone,
  ]);

  return (
    <div className="content">
      <DeleteTaskModal
        isOpen={isOpen}
        toggle={() => toggleDeleteModal()}
        handleDelete={handleDeleteTask}
        isLoading={deleteIsLoading}
      />
      <CommonGrid alignItem="center">
        <PageTitle>{t('all_tasks')}</PageTitle>
        <CommonFlex gap="12px">
          {/* {localStorage.getItem('user_role') === 'admin' &&
          !localStorage.getItem('viewer_role') ? (
            <ColoredButton
              type="blue"
              onClick={() => handleExportAllOfficesReportToExcel()}
              padding={allReportLoading ? '0 45px' : '0 25px'}
            >
              {allReportLoading ? (
                <ButtonTextLoader color="#fff" loadingText=" " size={6} />
              ) : (
                <>{t('generate_all_report')}</>
              )}
            </ColoredButton>
          ) : null} */}
          {/* {(localStorage.getItem('user_role') !== 'employee' ||
            localStorage.getItem('user_role') !== 'supervisor') &&
          selectedOffice &&
          selectedOffice.value ? (
            <WhiteButtonWithIcon
              onClick={() => handleExportToExcel()}
              color="#19AC65"
              borderColor="#19AC65"
              hoverBorderColor="#129652"
              padding={reportLoading ? '0 45px' : '0 25px'}
            >
              {reportLoading ? (
                <ButtonTextLoader color="#19AC65" loadingText=" " size={6} />
              ) : (
                <>
                  <ButtonIcon src={excelIcon} size="26px" />
                  {t('export_report')}
                </>
              )}
            </WhiteButtonWithIcon>
          ) : null} */}
          {localStorage.getItem('user_role') !== 'employee' &&
            !localStorage.getItem('viewer_role') && (
              <ColoredButtonWithIcon
                onClick={() => history.push(`/user/create-task`)}
              >
                <ButtonIcon src={plusIcon} alt="" />
                {t('create_task')}
              </ColoredButtonWithIcon>
            )}
        </CommonFlex>
      </CommonGrid>
      <HeaderContainer columns="1fr">
        <TaskFilter
          getOrganizationMembersList={getOrganizationMembersList}
          organizationMembersList={orgMembersList}
          officesShortList={officesShortList}
          getOfficesShortList={getOfficesShortList}
          selectedOffice={selectedOffice}
          selectOffice={selectOffice}
          locationList={locationsList}
          selectedLocation={selectedLocation}
          selectLocation={selectLocation}
          selectedMember={selectedMember}
          selectMember={selectMember}
          clearMember={clearMember}
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          taskStatus={taskStatus}
          selectedDate={selectedDate}
          startDate={selectedDate.startDate}
          endDate={selectedDate.endDate}
          handleDateChange={handleDateChange}
          handleStatusChange={handleStatusChange}
          handleSearchByName={handleSearchByName}
          handleClearSearch={handleClearSearch}
          selectedPublicZone={selectedPublicZone}
          onPublicZoneChange={onPublicZoneChange}
          publicZoneList={publicZoneList}
          getTaskLocation={getTaskLocation}
        />
      </HeaderContainer>

      {totalTasksCount && totalTasksCount > 0 && pageSize ? (
        <TopPagination
          itemName={t('Tasks')}
          totalCount={totalTasksCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}

      <TasksList
        selectedOrganization={selectedOrganization}
        loadingTaskAssigneeId={loadingTaskAssigneeId}
        expandedTaskId={expandedTaskId}
        setExpandedTaskId={setExpandedTaskId}
        toggleDeleteModal={toggleDeleteModal}
        history={history}
        tasksList={tasksList}
        isLoading={isLoading}
        createTask={createTask}
      />

      {totalTasksCount && totalTasksCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={totalTasksCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
};

export default Tasks;
