import { connect } from 'react-redux';

import { getOfficesShortList } from '../../../Offices/officesActions';
import { getOrganizationMembersList } from '../../../Projects/projectsActions';
import {
  getRouteMapLocations,
  getSingleEmployeeClockInOutList,
  getTaskCheckInOutList,
} from '../routeMapActions';
import RouteMapFilter from './RouteMapFilter';

const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  officesShortList: state.office.officesShortList,
  lastAttendanceTimestamp: state.routeMap.lastAttendanceTimestamp,
});

const mapDispatchToProps = dispatch => ({
  getRouteMapLocations: payload => dispatch(getRouteMapLocations(payload)),
  getOfficesShortList: payload => dispatch(getOfficesShortList(payload)),
  getOrganizationMembersList: details =>
    dispatch(getOrganizationMembersList({ ...details })),
  getSingleEmployeeClockInOutList: payload =>
    dispatch(getSingleEmployeeClockInOutList(payload)),
  getTaskCheckInOutList: payload => dispatch(getTaskCheckInOutList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteMapFilter);
