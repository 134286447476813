import styled from "styled-components";

export const ProjectListTableHeader = styled.div`
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
`;

export const ProjectListTableRow = styled.div`
  padding: 20px;

  :nth-child(even) {
    background-color: #f8f9fc;
  }
  :last-child {
    border-radius: ${({ theme }) =>
      `0 0 ${theme.borderRadii.card} ${theme.borderRadii.card}`};
  }
`;
