import { connect } from 'react-redux';

import { getOrganizationMembersList } from '../../Projects/projectsActions';
import {
  editSubTask,
  getTaskDetails,
  getTaskType,
  updateTaskApproval,
} from '../tasksActions';
// Component
import TaskDetails from './TaskDetails';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,
  isLoading: state.task.isLoading,
  taskDetails: state.task.taskDetails,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersList: params =>
    dispatch(getOrganizationMembersList(params)),
  getTaskDetails: params => dispatch(getTaskDetails(params)),
  getTaskType: params => dispatch(getTaskType(params)),
  updateTaskApproval: params => dispatch(updateTaskApproval(params)),
  editSubTask: params => dispatch(editSubTask(params)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
