import { t } from 'i18next';

import {
  VERIFY_REQUEST,
  VERIFY_REQUEST_FAILURE,
  VERIFY_REQUEST_SUCCESS,
  VERIFY_RESET,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

// ------------------------------------
// Action handler methods
// ------------------------------------
export const verifyRequestHandler = (state, action) => {
  return {
    ...state,
  };
};

export const verifyRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification('success', 'Verification Success', 3000);
    return {
      ...state,
      isLoading: false,
      token: data.data.token,
      userData: data.data,
      redirect: true,
      failure: false,
    };
  }
  return {
    ...state,
    isLoading: false,
    failure: false,
  };
};

export const verifyRequestFailureHandler = (state, action) => {
  if (action.payload.response === undefined) {
    createNotification('error', t('No internet connection found'), 3000);
    return {
      ...state,
      isLoading: false,
      redirect: false,
    };
  }
  const {
    payload: {
      response: { data },
    },
  } = action;
  if (data.non_field_errors) {
    createNotification('error', data.non_field_errors[0], 3000);
  }
  return {
    ...state,
    isLoading: false,
    redirect: false,
    failure: true,
    token: null,
  };
};

export const verifyResetHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    redirect: false,
    failure: false,
    token: null,
    userData: null,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [VERIFY_REQUEST]: verifyRequestHandler,
  [VERIFY_REQUEST_SUCCESS]: verifyRequestSuccessHandler,
  [VERIFY_REQUEST_FAILURE]: verifyRequestFailureHandler,
  [VERIFY_RESET]: verifyResetHandler,
};

// default initial state
const initialState = {
  isLoading: false,
  redirect: false,
  failure: false,
  token: null,
  userData: null,
};

export default function verifyReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
