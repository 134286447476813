import { connect } from 'react-redux';

import { getOfficesShortList, getPublicZoneList } from '../Offices/officesActions';
import { getOrganizationMembersList } from '../Projects/projectsActions';
// Component
import Tasks from './Tasks';
import {
  createTask,
  deleteTask,
  duplicateTask,
  getTaskLocation,
  getTasksList,
} from './tasksActions';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersList: state.project.orgMembersList,
  officesShortList: state.office.officesShortList,
  publicZoneList: state.office.publicZoneList,
  duplicateIsLoading: state.task.duplicateIsLoading,
  locationsList: state.task.locationsList,
  deleteIsLoading: state.task.deleteIsLoading,
  deletedCallback: state.task.deletedCallback,
  tasksList: state.task.tasksList,
  createdTaskInfo: state.task.createdTaskInfo,
  overlap: state.task.overlap,
  duplicateTaskInfo: state.task.duplicateTaskInfo,
  isLoading: state.task.isLoading,
  loadingTaskAssigneeId: state.project.loadingTaskAssigneeId,
  // pagination
  totalTasksCount: state.task.tasksListCount,
  pageSize: state.task.taskListPageSize,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersList: params =>
    dispatch(getOrganizationMembersList(params)),
  getTasksList: params => dispatch(getTasksList(params)),
  createTask: params => dispatch(createTask(params)),
  deleteTask: params => dispatch(deleteTask(params)),
  getOfficesShortList: params => dispatch(getOfficesShortList(params)),
  getTaskLocation: params => dispatch(getTaskLocation(params)),
  getPublicZoneList: params => dispatch(getPublicZoneList(params)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
