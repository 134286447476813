import { t } from 'i18next';
import { TaskStatusOptions } from 'modules/constants';
import { Fragment, useEffect, useState } from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';
import { dashboardStatusColors } from 'styles/light/colors';

import noDataImg from '../../assets/img/common/nodata_top_sites.svg';
import {
  ColorCircle,
  PieChartContainer,
} from '../../styledComponents/dashboard';
import { topProjectsCardChartOptions } from '../../utils/chartOptions';
import { toHHMMSS } from '../../utils/helper';
import TopProjectsLoader from '../ContentLoader/TopProjectsLoader';
import {
  CardTitle,
  DougnutGraphDiv,
  GraphAndDataContainer,
  NoDataContainer,
  NoDataImg,
  NoDataText,
  ProjectData,
  ProjectInfoDiv,
  ProjectNameDiv,
  ProjectTimeDiv,
  ProjectsDataContainer,
  TopProjectsCardContainer,
  TopProjectsLoaderContainer,
} from './cardStyles';

const initialChartData = {
  labels: [null],
  datasets: [
    {
      data: [0],
    },
  ],
};

const TopProjectsCard = ({ task, isLoading, history }) => {
  const [chartData, setChartData] = useState(initialChartData);
  const handleOnClick = () => {
    history.push(`/user/tasks?zone=${task.office}`);
  };

  const statusNames = TaskStatusOptions?.filter(status => status.value)?.map(
    status => status.key,
  );

  useEffect(() => {
    let tempValues = [];
    let tempChartLabels = [];
    let tempBackgroundColors = [];
    if (task && Object.keys(task).length > 0) {
      let allZeros = true;
      for (const key in task) {
        if (key !== 'office') {
          tempValues.push(task[key]);
          tempChartLabels.push(t(key));
          if (task[key] !== 0) {
            allZeros = false;
          }
          tempBackgroundColors.push(dashboardStatusColors[key]);
        }
      }
      if (allZeros) {
        tempBackgroundColors = ['#C2CCE1'];
        tempChartLabels = ['No tasks'];
        tempValues = [1];
      }

      setChartData({
        labels: tempChartLabels,
        datasets: [
          {
            label: `# ${t('of_tasks')}`,
            hoverBorderColor: '#ffffff',
            borderWidth: 0,
            data: tempValues,
            backgroundColor: tempBackgroundColors,
          },
        ],
      });
    }
  }, []);

  return (
    <TopProjectsCardContainer
      onClick={handleOnClick}
      cursor="pointer"
      background="#ffffff"
    >
      <CardTitle fontSize="14px">{task.office}</CardTitle>
      <PieChartContainer>
        {isLoading && (
          <TopProjectsLoaderContainer>
            <TopProjectsLoader />
          </TopProjectsLoaderContainer>
        )}
        {!isLoading && !task && (
          <NoDataContainer marginTop="0px">
            <NoDataImg
              height="90px"
              width="120px"
              src={noDataImg}
              alt="no-data"
            />
            <NoDataText>There is no data to be displayed!</NoDataText>
          </NoDataContainer>
        )}
        {!isLoading && task && Object.keys(task).length > 0 && (
          <GraphAndDataContainer>
            <DougnutGraphDiv>
              <Doughnut
                options={topProjectsCardChartOptions()}
                data={chartData}
              />
            </DougnutGraphDiv>
            <ProjectsDataContainer>
              {statusNames?.map(statusName => (
                <ProjectInfoDiv key={statusName}>
                  <ProjectNameDiv>
                    <ColorCircle
                      background={dashboardStatusColors[statusName]}
                    />
                    <ProjectData>{t(statusName)}</ProjectData>
                    <ProjectData>{task[statusName]}</ProjectData>
                  </ProjectNameDiv>
                </ProjectInfoDiv>
              ))}
            </ProjectsDataContainer>
          </GraphAndDataContainer>
        )}
      </PieChartContainer>
    </TopProjectsCardContainer>
  );
};

export default TopProjectsCard;
