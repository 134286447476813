import avatar5 from 'assets/img/avatar_5.svg';
import assign from 'assets/img/icons/assign.svg';
import removeAssignee from 'assets/img/icons/remove_assignee.svg';
import searchIcon from 'assets/img/icons/search.svg';
import DropdownLoader from 'components/DropdownLoader/DropdownLoader';
import { t } from 'i18next';
import { Fragment, useEffect, useRef, useState } from 'react';
import {
  AssignListContainer,
  AssignListItem,
  AssignListText,
  AssigneeSearchBarContainer,
  AssigneeSearchBarWrapper,
  AssigneeSearchIcon,
  AssigneeSearchInput,
  CommonFlex,
  CommonText,
  SearchBarWithAssignListContainer,
  ShowMoreAssigneeList,
} from 'styledComponents/common';
import {
  AssigneeRoundImage,
  AssigneeRoundImageSection,
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
  RoundImageCross,
  RoundcheckListItemImage,
} from 'styledComponents/createProject';

import {
  CheckListDropdownContainer,
  CheckListDropdownItem,
} from './tasksStyles';

const CheckListDropdown = ({
  handleAddFromDropdown,
  defaultCheckListOptions,
  isLoading = false,
  setShowChecklistDropdown,
  searchText,
  mainComponentRef,
}) => {
  const wrapperRef1 = useRef(null);

  const [checkListItems, setCheckListItems] = useState(defaultCheckListOptions);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (searchText && searchText.length > 0) {
      let newArr = defaultCheckListOptions.filter(item => {
        return item?.name?.toLowerCase().includes(searchText.toLowerCase());
      });
      setCheckListItems([...newArr]);
    } else {
      setCheckListItems([...defaultCheckListOptions]);
    }
  }, [searchText]);

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  //function
  useOutsideClickHandler(wrapperRef1);

  function useOutsideClickHandler(ref1) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref1.current &&
          !ref1.current.contains(event.target) &&
          mainComponentRef.current &&
          !mainComponentRef.current.contains(event.target)
        ) {
          setShowChecklistDropdown(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref1, mainComponentRef]);
  }
  return (
    <CheckListDropdownContainer ref={wrapperRef1} autoPlacement width="100%">
      <SearchBarWithAssignListContainer>
        {/* <AssigneeSearchBarWrapper>
          <AssigneeSearchBarContainer>
            <AssigneeSearchIcon src={searchIcon} alt="" />
            <AssigneeSearchInput
              value={searchTerm}
              onChange={e => onSearchTermChange(e)}
              placeholder={`${t('search')}...`}
              autoFocus
            />
          </AssigneeSearchBarContainer>
        </AssigneeSearchBarWrapper> */}

        <DropdownLoader loading={isLoading} />
        {checkListItems && checkListItems.length > 0 ? (
          checkListItems.map((checkListItem, i) => {
            return (
              <CheckListDropdownItem
                key={i}
                onClick={() => {
                  handleAddFromDropdown(checkListItem);
                }}
                columns="30px auto"
                padding="10px 20px"
              >
                {checkListItem.name}
              </CheckListDropdownItem>
            );
          })
        ) : (
          <CommonFlex padding="20px" justifyContent="center">
            <CommonText>{t('not_found')}</CommonText>
          </CommonFlex>
        )}
      </SearchBarWithAssignListContainer>
    </CheckListDropdownContainer>
  );
};

export default CheckListDropdown;
