export const colors = {
  textMain: '#451e1e',
  textSecondary: '#000000',
  labelText: '#452E1E',
  placeholderText: '#746F6B',
  sidebarHover: '#ffffff',
  sidebadBackground: '#4F3423',
  tableRowBackground: '#F8F4F3',

  openBackground: '#C2CCE1',
  openText: '#ffffff',
  notCompletedBackground: '#FBE7E8',
  notCompletedText: '#A30D11',
  completedBackground: '#EBF9F1',
  completedText: '#1F9254',
  missedBackground: '#FEF2E5',
  missedText: '#CD6200',

  checkEvidenceBackground: '#EE7E1C',

  sidebarActiveColor: '#ffffff',
  sidebarActiveBackgroundColor: '#916246',

  primary: '#4F3423', // dropdown bg color
  secondary: '#FBFBFB', //
  primaryLight: '#FEF2E5',
  // primaryLight: '#eaf9f7',
  primaryDisabled: '#dce2ef',

  success: '#4AB37B',
  successMedium: '#d4f4ee',
  successLight: '#CCEBDA',

  warning: '#EA9F48',
  warningMedium: '#fad59d',
  warningLight: '#fdf3e4',

  error: '#EF717D',
  errorMedium: '#ffacb4',
  errorLight: '#fff6f7',
  errorDisabled: '#ffc7cc',

  purple: '#6f7aff',
  purpleMedium: '#9a96f9',
  purpleLight: '#d9d7fc',

  blue: '#4d94fb',
  blueMedium: '#98c3ff',
  blueLight: '#d2e4fe',

  violet: '#6776CB',

  gray: '#7f9dbf', // mark as paid
  grayMedium: '#B0CBD6', // save as draft
  grayLight: '#CDE2EA',
  grayHover: '#8DADBA',

  sand: '#AF9B89',
  sandMedium: '#CCBAA9',
  sandLight: '#F4E3D4',

  // plan gray color
  planGray: '#6686a2',
  playGrayLight: '#eaf2f9',

  blackButton: '#465065',
  focusShadow: '#F5F5F5',

  white: '#fff',
  whiteBg: '#fcfdfe',
};

export const hoverColors = {
  primary: '#2E1D16',
  error: '#ed5160',
  warning: '#f09b16',
  purpleMedium: '#8985ea',
  blue: '#3f86f0',
  gray: '#6d8caf',
  grayMedium: '#8ea1b5',
  planGray: '#617f9a',
  blackButton: '#1f2939',
};

export const dashboardColors = {
  total_employees: '#7F9DBF',
  working_today: '#41CB8F',
  not_working_today: '#C83A6E',
  total: '#916246',
  open: '#D9A777',
  to_do: '#D9A777',
  'to do': '#D9A777',
  not_completed: '#4A90E2',
  missed: '#F7B955',
  completed: '#72CC6D',
  pending_approval: '#B48963',
  approved: '#27AE60',
  rejected: '#E0627A',
};

export const dashboardStatusColors = {
  to_do: '#7f9dbf',
  completed: '#4AB37B',
  not_completed: '#EA9F48',
  missed: '#EF717D',
};

export const borderColors = {
  primary: colors.primary,
  secondary: '#c2cce1',
  secondaryLight: '#e4e7eb',
  blackButton: '#1f2939',
  error: colors.error,
};

export const hoverBorderColors = {
  secondaryLight: '#434343b3',
};
