import { t } from 'i18next';
import { CommonFlex, CommonText } from 'styledComponents/common';

import no_data from '../../assets/img/no_data.svg';
import TopProjectsCard from './TopProjectsCard';
import {
  DashBoardOverviewColumnCardsContainer,
  DashBoardOverviewColumnContainer,
} from './cardStyles';

const DashBoardOverviewColumn = ({ title, data, history, isLoading }) => {
  return (
    <DashBoardOverviewColumnContainer>
      <CommonText fontSize="16px" fontWeight="600">
        {t(title)}
      </CommonText>
      <DashBoardOverviewColumnCardsContainer>
        {data?.length > 0 ? (
          data?.map((item, index) => (
            <TopProjectsCard
              task={item}
              isLoading={isLoading}
              key={index}
              history={history}
            />
          ))
        ) : (
          <CommonFlex justifyContent="center">
            <img src={no_data} alt="" width="70%" />
          </CommonFlex>
        )}
      </DashBoardOverviewColumnCardsContainer>
    </DashBoardOverviewColumnContainer>
  );
};

export default DashBoardOverviewColumn;
