import ComponentCircleLoader from 'components/Loaders/ComponentCircleLoader';
import TimeRangeComponentNew from 'custom_modules/Timesheet/Daily/TimeRangeComponentNew';
import TimeDuration from 'custom_modules/Timesheet/Daily/timeDuration';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import AddMultipleAssigneeDropdown from '../../../components/CustomDropdown/AddMultipleAssigneeDropdown';
import AddSingleMemberDropdown from '../../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import Select from '../../../components/ReactSelectDropdown';
import { filterOption } from '../../../components/ReactSelectDropdown/ReactSelectDropdown';
import { ClearSearchButton } from '../../../components/Search/searchStyles';
import DatePicker from '../../../components/SingleDatePicker/SingleDatePicker';
import { PrimaryButton, RadiusButton } from '../../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  Container,
  ContentHeader,
  FormAlert,
  InputField,
  InputLabel,
  InputTextArea,
  InputWithLabelSection,
  PageTitle,
  RedStar,
} from '../../../styledComponents/common';
import {
  ToggleButton,
  ToggleButtonRound,
} from '../../../styledComponents/invoice';
import { checkHtml, checkUrl } from '../../../utils/helper';
import TimeRangeComponent from '../../Timesheet/Daily/TimeRangeComponent';
import AddLocationModal from '../CreateTask/AddLocationModal';
import CheckList from '../CreateTask/checkList';
import CheckLists from '../CreateTask/checkLists';
import {
  AssigneeSection,
  InputSection,
  TimeRangeSection,
} from '../CreateTask/createTaskStyles';
import CreateEditTaskForm from '../create-editTaskForm';

const DuplicateTask = ({
  isLoading,
  orgMembersList,
  officesShortList,
  addTaskLocationLoading,
  getTaskDetails,
  taskDetails,
  createTask,
  callback,
  locationsList,
  createdTaskLocationInfo,
  memberShortListIsLoading,
  getOrganizationMembersList,
  getTaskType,
  getTaskLocation,
  addTaskLocation,
  getOfficesShortList,
  taskTypeIsLoading,
  createdTaskInfo,
  taskTypeList,
  history,
  location,
  getPublicZoneList,
  publicZoneList,
  getDefaultSubTasks,
  defaultSubTasks,
  newDefaultSubTask,
  isAddDefaultSubTaskLoading,
  addDefaultSubTask,
  clearNewDefaultSubTask,
}) => {
  const { t, i18n } = useTranslation();

  const [taskName, setTaskName] = useState('');
  const [selectedOffice, setSelectedOffice] = useState('');
  const [officeOptions, setOfficeOptions] = useState([]);
  const [selectedPublicZone, setSelectedPublicZone] = useState('');
  const [publicZoneOptions, setPublicZoneOptions] = useState([]);
  const [defaultCheckListOptions, setDefaultCheckListOptions] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [requiresApproval, setRequiresApproval] = useState(false);
  const [taskDescription, setTaskDescription] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [taskLocationOptions, setTaskLocationOptions] = useState([]);
  // const [radiusInput, setRadiusInput] = useState(100);
  // const [isCustomRadius, setIsCustomRadius] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).add(5, 'minutes'),
  );
  const [endDate, setEndDate] = useState(moment(new Date()).add(65, 'minutes'));
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [selectedTaskType, setSelectedTaskType] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);

  const [saveErrors, setSaveErrors] = useState({});
  const radiusOptions = [20, 50, 100];
  const [statusOptions, setStatusOptions] = useState([]);
  const [taskId, setTaskId] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [checklistData, setChecklistData] = useState([]);

  const [isSameLocationWarningModalOpen, setIsSameLocationWarningModalOpen] =
    useState(false);
  const popupToggleSameLocationWarningModal = () => {
    setIsSameLocationWarningModalOpen(!isSameLocationWarningModalOpen);
  };
  const handleUpdateCheckList = data => {
    const newData = checklistData.map(checklist =>
      checklist.id === data.id ? data : checklist,
    );
    setChecklistData([...newData]);
  };

  const setDefaultLocation = (office = selectedOffice) => {
    if (
      selectedLocation?.office !== office?.value &&
      selectedLocation?.id !== createdTaskLocationInfo?.id
    ) {
      const officeLocations = taskLocationOptions?.filter(
        location => location?.office?.id === office?.value,
      );

      const defaultLocation = officeLocations?.filter(
        location => location.name === office?.label,
      );
      if (selectedLocation?.office?.id !== selectedOffice?.value) {
        if (defaultLocation?.length >= 1) {
          setSelectedLocation(defaultLocation[0]);
        } else if (officeLocations?.length >= 1) {
          setSelectedLocation(taskLocationOptions[0]);
        } else {
          setSelectedLocation(officeLocations[0]);
        }
      }
    }
  };

  useEffect(() => {
    setDefaultLocation();
  }, [taskLocationOptions]);
  useEffect(() => {
    // getOfficesShortList();
    getTaskLocation();
    if (location.search) {
      const queryParsed = queryString.parse(location.search);
      setTaskId(queryParsed.id);
      getTaskDetails({ task_id: queryParsed.id });
    }
    getTaskType();
    getPublicZoneList();
  }, []);

  useEffect(() => {
    if (publicZoneList && publicZoneList.length > 0) {
      let newArr = [];
      publicZoneList.forEach(zone => {
        newArr.push({
          label: zone.name,
          value: zone.id,
        });
      });
      // newArr?.length > 0 && setSelectedPublicZone(newArr[0]);
      setPublicZoneOptions(newArr);
    }
  }, [publicZoneList]);

  useEffect(() => {
    if (selectedPublicZone) {
      getDefaultSubTasks({
        public_zone_id: selectedPublicZone.value,
      });
    }
    if (selectedPublicZone.value) {
      getOfficesShortList({
        public_zone_id: selectedPublicZone.value,
      });
    }
  }, [selectedPublicZone]);

  useEffect(() => {
    if (defaultSubTasks) {
      let newArr = [];
      defaultSubTasks.forEach(subTask => {
        newArr.push({
          name: subTask.name,
          id: subTask.id,
          default_sub_task_id: subTask.id,
        });
      });
      setDefaultCheckListOptions(newArr);
    }
  }, [defaultSubTasks]);

  useEffect(() => {
    if (taskDetails) {
      taskDetails.name && setTaskName(taskDetails.name);
      taskDetails.office &&
        taskDetails.office.id &&
        setSelectedOffice({
          label: taskDetails.office.name,
          value: taskDetails.office.id,
        });
      taskDetails.public_zone &&
        taskDetails.public_zone.id &&
        setSelectedPublicZone({
          label: taskDetails.public_zone.name,
          value: taskDetails.public_zone.id,
        });
      if (taskDetails.assignees && taskDetails.assignees.length > 0) {
        let newArr = [];
        taskDetails.assignees.forEach((assignee, i) => {
          newArr.push({
            ...assignee,
            name:
              `${assignee.first_name} ${assignee.last_name}`.trim() ||
              assignee.first_name,
          });
        });

        setSelectedAssignees(newArr);
      }
      if (taskDetails.location && taskDetails.location.id) {
        setSelectedLocation({
          label: taskDetails.location.name,
          value: taskDetails.location.id,
          ...taskDetails.location,
        });
      }
      if (taskDetails.description) {
        setTaskDescription(taskDetails.description);
      }
      if (taskDetails.task_type && taskDetails.task_type.id) {
        statusOptions.map((option, i) => {
          if (option.value === taskDetails.task_type.id) {
            setSelectedTaskType({
              label: option.label,
              value: option.value,
            });
          }
        });
      }
      if (
        taskDetails.required_start_date &&
        moment(taskDetails.required_start_date).diff(moment()) > 1
      ) {
        if (taskDetails.required_start_date) {
          setStartDate(moment(taskDetails.required_start_date));
          setStartTime(
            moment(taskDetails.required_start_date).format('HH:mm:ss'),
          );
        }
        if (taskDetails.required_end_date) {
          setEndDate(moment(taskDetails.required_end_date));

          setEndTime(moment(taskDetails.required_end_date).format('HH:mm:ss'));
        }
      }
      if (taskDetails.sub_tasks?.length > 0) {
        setChecklistData(
          taskDetails.sub_tasks.map(sub_task => {
            return {
              id: sub_task.id,
              name: sub_task.name,
              default_sub_task_id: null,
            };
          }),
        );
      } else setChecklistData([]);
    }
  }, [taskDetails]);

  useEffect(() => {
    // if (officesShortList && officesShortList.length === 1) {
    //   getOrganizationMembersList({ office_id: officesShortList[0].id });
    //   getTaskLocation({ office_id: officesShortList[0].id });
    // }
    const checkSelectedOffice = officesShortList?.filter(
      office => office.id === selectedOffice?.value,
    );
    if (officesShortList && officesShortList.length > 0) {
      let newArr = [];
      officesShortList.forEach((office, i) => {
        newArr.push({
          label: office.name,
          value: office.id,
        });
      });
      if (!(checkSelectedOffice?.length > 0)) {
        onOfficeChange({
          label: officesShortList[0].name,
          value: officesShortList[0].id,
        });
      }
      setOfficeOptions(newArr);
    } else if (officesShortList?.length === 0) {
      setOfficeOptions([]);
      setSelectedOffice(null);
      setTaskLocationOptions([]);
      setSelectedLocation(null);
    }
  }, [officesShortList]);

  useEffect(() => {
    if (selectedOffice && selectedOffice.value) {
      getOrganizationMembersList({ office_id: selectedOffice.value });
      getTaskLocation({ office_id: selectedOffice.value });
    }
  }, [selectedOffice]);

  useEffect(() => {
    if (locationsList && locationsList.length > 0) {
      let newArr = [];
      locationsList.forEach(location => {
        newArr.push({
          label: location.name,
          value: location.id,
          ...location,
        });
      });
      setTaskLocationOptions(newArr);
    } else if (locationsList && locationsList.length === 0) {
      setTaskLocationOptions([]);
    }
  }, [locationsList]);

  useEffect(() => {
    if (taskTypeList && taskTypeList.length > 0) {
      let newArr = [];
      if (i18n.language && i18n.language === 'en') {
        taskTypeList.forEach(type => {
          newArr.push({
            label: type.name,
            value: type.id,
          });
        });
      } else {
        taskTypeList.forEach(type => {
          newArr.push({
            label: type.arabic_name,
            value: type.id,
          });
        });
      }
      setStatusOptions(newArr);
    }
  }, [taskTypeList]);

  useEffect(() => {
    if (createdTaskInfo && isSubmitted) {
      history.goBack();
      // history.push({
      //   pathname: '/user/tasks',
      //   state: { from: 'create' },
      // });
    }
  }, [createdTaskInfo]);

  const onTaskNameChange = e => {
    let errors = { ...saveErrors };
    delete errors['taskName'];
    setSaveErrors(errors);
    setTaskName(e.target.value);
  };

  const onOfficeChange = e => {
    setSelectedOffice(e);
    setDefaultLocation(e);
  };

  const onPublicZoneChange = e => {
    setSelectedPublicZone(e);
  };

  const approvalToggle = () => {
    setRequiresApproval(!requiresApproval);
  };

  const handleAssignOrRemoveMember = (user, action) => {
    if (user && user.id) {
      let newArr = [...selectedAssignees];
      if (action === 'remove') {
        newArr = newArr.filter(member => member.id !== user.id);
      } else {
        newArr.push(user);
      }
      setSelectedAssignees(newArr);
    }
  };

  const handleAssignMember = user => {
    handleAssignOrRemoveMember(user, 'assign');
  };

  const handleRemoveMember = user => {
    handleAssignOrRemoveMember(user, 'remove');
  };

  const onTaskDescriptionChange = e => {
    let errors = { ...saveErrors };
    delete errors['taskDescription'];
    setSaveErrors(errors);
    setTaskDescription(e.target.value);
  };

  const onLocationChange = e => {
    setSelectedLocation(e);
  };

  const onStartDateChange = date => {
    let errors = { ...saveErrors };
    delete errors['timeDifferenceStartTime'];
    setSaveErrors(errors);
    setStartDate(date);
  };
  const onEndDateChange = date => {
    let errors = { ...saveErrors };
    delete errors['timeDifferenceEndTime'];
    setSaveErrors(errors);
    setEndDate(date);
  };

  const handleStartTime = time => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors['timeDifferenceStartTime'];
      setSaveErrors(errors);
      const start_time = time.replace(/ /g, '');
      setStartTime(moment(start_time, 'h:mmA').format('HH:mm:ss'));
    } else {
      setStartTime('');
    }
  };
  const handleEndTime = time => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors['timeDifferenceEndTime'];
      setSaveErrors(errors);
      const end_time = time.replace(/ /g, '');
      setEndTime(moment(end_time, 'h:mmA').format('HH:mm:ss'));
    } else {
      setEndTime('');
    }
  };

  const onDifficultyChange = e => {
    setSelectedTaskType(e);
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const handleAddCheckList = data => {
    checklistData.push(data);
    setChecklistData([...checklistData]);
  };

  const handleDeleteCheckList = id => {
    const newData = checklistData.filter(
      checklistData => checklistData.id !== id,
    );
    setChecklistData([...newData]);
  };

  const handleSave = () => {
    if (checkError()) {
      if (selectedOffice && selectedOffice.value) {
        const start_date = moment(
          startDate.format('YYYY-MM-DD') + ' ' + startTime,
        );
        const end_date = moment(endDate.format('YYYY-MM-DD') + ' ' + endTime);
        let assigneeIds = [];
        if (selectedAssignees && selectedAssignees.length > 0) {
          selectedAssignees.forEach(element => {
            assigneeIds.push(element.id);
          });
        }
        let payload = {
          name: taskName,
          office_id: selectedOffice.value,
          public_zone_id: selectedPublicZone.value,
          assignees: assigneeIds,
          description: taskDescription,
          location_id: selectedLocation.value,
          // task_type: selectedTaskType.value,
          required_start_date: moment(start_date).format(
            'YYYY-MM-DDTHH:mm:ssZ',
          ),
          required_end_date: moment(end_date).format('YYYY-MM-DDTHH:mm:ssZ'),
          sub_tasks: checklistData ? checklistData : [],
          // sub_tasks: checklistData.map(checkList => {
          //   return { name: checkList.name };
          // }),
        };
        const officeLocation = officesShortList.find(
          office => office.id === selectedOffice.value,
        ).location;
        if (
          officeLocation.latitude === selectedLocation.latitude &&
          officeLocation.longitude === selectedLocation.longitude &&
          isSameLocationWarningModalOpen === false
        ) {
          setIsSameLocationWarningModalOpen(true);
          return;
        }
        createTask(payload);
        setIsSubmitted(true);
      }
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!taskName.trim()) {
      formIsValid = false;
      saveErrors['taskName'] = 'Task name is required';
    } else if (checkUrl(taskName)) {
      formIsValid = false;
      saveErrors['taskName'] = 'Task name can not contain url.';
    } else if (checkHtml(taskName)) {
      formIsValid = false;
      saveErrors['taskName'] = 'Invalid Task name';
    } else if (taskName.length > 255) {
      formIsValid = false;
      saveErrors['taskName'] = 'Maximum length 255 characters';
    }
    if (selectedAssignees.length === 0) {
      formIsValid = false;
      saveErrors['selectedAssignees'] = 'Assignee is required';
    }
    if (!selectedLocation?.value) {
      formIsValid = false;
      saveErrors['selectedLocation'] = 'Location is required';
    }
    if (taskDescription?.length === 0) {
      formIsValid = false;
      saveErrors['taskDescription'] = 'Description is required';
    } else if (checkUrl(taskDescription)) {
      formIsValid = false;
      saveErrors['taskDescription'] = 'Task description can not contain url.';
    } else if (checkHtml(taskDescription)) {
      formIsValid = false;
      saveErrors['taskDescription'] = 'Invalid Task description';
    } else if (taskDescription.length > 512) {
      formIsValid = false;
      saveErrors['taskDescription'] = 'Maximum length 512 characters';
    }
    if (!startDate) {
      formIsValid = false;
    }
    if (!startTime || !endTime) {
      formIsValid = false;
    }
    if (
      moment(endDate.format('YYYY-MM-DD') + ' ' + endTime).diff(
        moment(startDate.format('YYYY-MM-DD') + ' ' + startTime),
      ) < 1
    ) {
      formIsValid = false;
      saveErrors['timeDifferenceEndTime'] = t(
        'end_time_must_be_after_start_time',
      );
    } else if (
      moment(endDate.format('YYYY-MM-DD') + ' ' + endTime).diff(
        moment(startDate.format('YYYY-MM-DD') + ' ' + startTime),
      ) < 1800000
    ) {
      formIsValid = false;
      saveErrors['timeDifferenceEndTime'] =
        'Minmium 30 minutes time difference required';
    }
    if (
      moment(startDate.format('YYYY-MM-DD') + ' ' + startTime).diff(moment()) <
      1
    ) {
      formIsValid = false;
      saveErrors['timeDifferenceStartTime'] = t('past_time_cant_be_set');
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  return (
    <div className="content">
      <PageTitle>{t('create_task')}</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.goBack()}
          subTitle={t('return')}
        />
      </ContentHeader>
      {isLoading && !taskDetails ? (
        <ComponentCircleLoader />
      ) : (
        <Container padding="30px" gap="20px">
          <CommonText name title fontSize="16px">
            {t('add_task_details')}
          </CommonText>
          <InputSection>
            <CreateEditTaskForm
              {...{
                selectedOffice,
                officeOptions,
                officesShortList,
                filterOption,
                taskName,
                saveErrors,
                memberShortListIsLoading,
                selectedAssignees,
                orgMembersList,
                handleAssignMember,
                handleRemoveMember,
                startTime,
                startDate,
                onStartDateChange,
                handleStartTime,
                endTime,
                endDate,
                onEndDateChange,
                handleEndTime,
                selectedLocation,
                taskLocationOptions,
                taskDescription,
                onOfficeChange,
                CreateProjectDropdown,
                onTaskNameChange,
                popupToggle,
                onTaskDescriptionChange,
                onLocationChange,
                publicZoneOptions,
                publicZoneList,
                selectedPublicZone,
                onPublicZoneChange,
                isSameLocationWarningModalOpen,
                popupToggleSameLocationWarningModal,
                handleSave,
                isLoading,
                popupOpen,
                addTaskLocation,
                addTaskLocationLoading,
                createdTaskLocationInfo,
                getTaskLocation,
                getOfficesShortList,
              }}
              creating={true}
            />
            <InputWithLabelSection style={{ gridColumn: `1 / span 2` }}>
              <CheckLists
                checklistData={checklistData}
                deleteCheckList={handleDeleteCheckList}
                updateSubTaskRequest={handleUpdateCheckList}
              />
            </InputWithLabelSection>
            <InputWithLabelSection
              margin="0px 15px"
              style={{ gridColumn: `1 / span 2` }}
            >
              <CheckList
                {...{
                  newDefaultSubTask,
                  isAddDefaultSubTaskLoading,
                  addDefaultSubTask,
                  selectedPublicZone,
                  clearNewDefaultSubTask,
                  checklistData,
                }}
                handleAddCheckList={handleAddCheckList}
                defaultCheckListOptions={defaultCheckListOptions}
              />
            </InputWithLabelSection>
            <PrimaryButton
              margin="80px 0 150px 0"
              disabled={
                !taskName ||
                !selectedOffice ||
                selectedAssignees.length === 0 ||
                !selectedLocation ||
                !startTime ||
                taskDescription?.length === 0 ||
                !endTime
              }
              onClick={() => handleSave()}
            >
              {isLoading ? (
                <ButtonTextLoader
                  loadingText={t('create_task')}
                  fontSize="13px"
                />
              ) : (
                t('create_task')
              )}
            </PrimaryButton>
            <div />
          </InputSection>
        </Container>
      )}
    </div>
  );
};

export default DuplicateTask;
