import axios from 'axios';

import { createNotification } from '../modules/notificationManager';

export const exportToExcel = async (url, fileName, showMessage) => {
  const baseURL = `${process.env.REACT_APP_API_URL}/api/`;
  const token = localStorage.getItem('access_token');
  const headers = { 'Content-Type': 'blob', Authorization: `Bearer ${token}` };
  const config = {
    method: 'GET',
    url: `${baseURL}${url}`,
    responseType: 'arraybuffer',
    headers,
  };

  try {
    const response = await axios(config);

    const outputFilename = fileName || `SubTaskReport_${new Date().toLocaleString()}.xlsx`;

    console.log("🚀 ~ exportToExcel ~ outputFilename:", outputFilename)
    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();

    if (showMessage) {
      createNotification('success', 'Report exported successfully', 2500);
    }

    return {
      success: true,
    };
  } catch (error) {
    if (showMessage) {
      createNotification('error', 'Error exporting report', 3000);
    }

    // console.log(error);
    throw Error(error);
  }
};
