/* eslint-disable react/display-name */
import Select from 'react-select';

import { DropdownIndicator, IndicatorSeparator } from './DropdownIndicator';

export const filterOption = (option, inputValue) => {
  if (option.label.toLowerCase().includes(inputValue.toLowerCase())) {
    return true;
  } else {
    return false;
  }
};

export default props => {
  return (
    <Select
      components={{ IndicatorSeparator, DropdownIndicator }}
      {...props}
    />
  );
};
