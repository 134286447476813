import { connect } from "react-redux";

import Members from "./Members";

// Actions
import {
  getMembersList,
  getInvitedMembersList,
  removeMember,
  inviteMember,
  deleteMemberInvitation,
  updateMember,
} from "./membersActions";
import { updateMemberwiseTimesheetApprovalSettings } from "../Settings/TimesheetSettings/timesheetSettingsActions";
import { getOfficesShortList } from "../Offices/officesActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.member.isLoading,
  deleteInvitationLoading: state.member.deleteInvitationLoading,
  deleteMemberLoading: state.member.deleteMemberLoading,
  selectedOrganization: state.organization.selectedOrganization,
  membersList: state.member.members,
  updatedData: state.member.updatedData,
  invitedMembersList: state.member.invitedMembersList,
  officesShortList: state.office.officesShortList,
  // pagination
  totalMembersCount: state.member.totalMembersCount,
  pageSize: state.member.pageSize,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getMembersList: (details) => dispatch(getMembersList({ ...details })),
  getInvitedMembersList: (details) =>
    dispatch(getInvitedMembersList({ ...details })),
  inviteMember: (details) => dispatch(inviteMember({ ...details })),
  removeMember: (details) => dispatch(removeMember({ ...details })),
  getOfficesShortList: (details) =>
    dispatch(getOfficesShortList({ ...details })),
  deleteMemberInvitation: (details) =>
    dispatch(deleteMemberInvitation({ ...details })),

  // settings
  updateMemberwiseTimesheetApprovalSettings: (details) =>
    dispatch(updateMemberwiseTimesheetApprovalSettings({ ...details })),
    updateMember: (details) => dispatch(updateMember({ ...details })),

});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Members);
