import { connect } from 'react-redux';

import { getOrganizationMembersList } from '../Projects/projectsActions';
// Component
import Offices from './Offices';
import {
  clearOfficeCreatedUpdated,
  getOfficesList,
  getPublicZoneList,
  setOfficeDetails,
} from './officesActions';

// Store props to map with current state
const mapStateToProps = state => ({
  orgMembersList: state.project.orgMembersList,
  isLoading: state.office.isLoading,
  officesList: state.office.officesList,
  publicZoneList: state.office.publicZoneList,
  officeDetails: state.office.officeDetails,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersList: params =>
    dispatch(getOrganizationMembersList(params)),
  getOfficesList: params => dispatch(getOfficesList(params)),
  setOfficeDetails: params => dispatch(setOfficeDetails(params)),
  getPublicZoneList: params => dispatch(getPublicZoneList(params)),
  clearOfficeCreatedUpdated: () => dispatch(clearOfficeCreatedUpdated()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Offices);
