import ReactDateRangePicker from 'components/ReactDateRangePicker/ReactDateRangePicker';
import { TaskStatusOptions } from 'modules/constants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddSingleMemberDropdown from '../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import FilterDropDownStyle from '../../components/DropdownStyle/FilterDropDownStyle';
import Select from '../../components/ReactSelectDropdown';
import { filterOption } from '../../components/ReactSelectDropdown/ReactSelectDropdown';
import SearchWithButton from '../../components/Search/SearchWithButton';
import {
  FilterLabel,
  InputWithLabelSection,
} from '../../styledComponents/common';
import { FilterContainer } from '../../styledComponents/tasks';

const TaskFilter = ({
  selectedOrganization,
  getOrganizationMembersList,
  organizationMembersList,
  getOfficesShortList,
  officesShortList,
  selectedOffice,
  selectOffice,
  selectedMember,
  selectProject,
  selectMember,
  clearMember,
  taskStatus,
  handleStatusChange,
  selectedDate,
  handleDateChange,
  handleSearchByName,
  handleClearSearch,
  searchTerm,
  onSearchTermChange,
  selectLocation,
  selectedLocation,
  locationList,
  publicZoneList,
  selectedPublicZone,
  onPublicZoneChange,
  getTaskLocation,
}) => {
  const { t } = useTranslation();
  const [officeOptions, setOfficeOptions] = useState([
    {
      label: t('all_zones'),
      value: null,
    },
  ]);
  const [publicZoneOptions, setPublicZoneOptions] = useState([
    {
      label: t('all_public_zones'),
      value: null,
    },
  ]);

  const [taskLocationOptions, setTaskLocationOptions] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const statusOptions = TaskStatusOptions?.map(option => ({
    ...option,
    label: t(option.key),
  }));
  const [selectedDates, setSelectedDates] = useState(selectedDate);
  const selectLastMonth = () => {
    setSelectedDates({
      startDate: moment().subtract(29, 'days'),
      endDate: moment(),
    });
  };
  const selectLastWeek = () => {
    setSelectedDates({
      startDate: moment().subtract(7, 'days'),
      endDate: moment(),
    });
  };
  const onDateApply = () => {
    handleDateChange(selectedDates);
  };
  useEffect(() => {
    getOrganizationMembersList({ office_id: null });
  }, []);

  useEffect(() => {
    if (selectedPublicZone.value) {
      getOfficesShortList({
        public_zone_id: selectedPublicZone.value,
      });
    } else getOfficesShortList();
  }, [selectedPublicZone]);

  useEffect(() => {
    if (selectedOffice?.value) {
      getTaskLocation({
        office_id: selectedOffice.value,
      });
    } else getTaskLocation();
  }, [selectedOffice]);

  useEffect(() => {
    if (officesShortList && officesShortList.length === 1) {
      getOrganizationMembersList({ office_id: officesShortList[0].id });
    }
    if (officesShortList && officesShortList.length >= 1) {
      let newArr = [
        {
          label: t('all_zones'),
          value: null,
        },
      ];
      officesShortList.forEach((office, i) => {
        newArr.push({
          label: office.name,
          value: office.id,
        });
      });
      const checkSelectedOffice = newArr.find(
        office => office.value === selectedOffice?.value,
      );

      if (!checkSelectedOffice) {
        selectOffice({
          label: t('all_zones'),
          value: null,
        });
      }
      setOfficeOptions(newArr);
    }
  }, [officesShortList]);
  useEffect(() => {
    if (publicZoneList && publicZoneList.length > 0) {
      let newArr = [
        {
          label: t('all_public_zones'),
          value: null,
        },
      ];
      publicZoneList.forEach(zone => {
        newArr.push({
          label: zone.name,
          value: zone.id,
        });
      });
      newArr?.length > 0 && onPublicZoneChange(newArr[0]);
      setPublicZoneOptions(newArr);
    }
  }, [publicZoneList]);

  useEffect(() => {
    if (
      selectedOffice &&
      selectedOffice.value &&
      officesShortList &&
      officesShortList.length > 1
    ) {
      getOrganizationMembersList({ office_id: selectedOffice.value });
    }
  }, [selectedOffice]);

  useEffect(() => {
    if (locationList && locationList.length > 0) {
      let newArr = [
        {
          label: t('all_locations'),
          value: null,
        },
      ];
      locationList.forEach(location => {
        newArr.push({
          label: location.name,
          value: location.id,
          ...location,
        });
      });
      setTaskLocationOptions(newArr);
    }
  }, [locationList]);
  //   useEffect(() => {
  //     if (projectAssigneeList && projectAssigneeList.length > 0) {
  //       let assigneeList = [];
  //       projectAssigneeList.map((assignee) => {
  //         assigneeList.push({
  //           id: assignee.user.id,
  //           name: `${assignee.user.first_name}${
  //             assignee.user.last_name ? ` ${assignee.user.last_name}` : ``
  //           }`,
  //           avatar: assignee.user.avatar ? assignee.user.avatar : avatar5,
  //         });
  //       });
  //       setMemberOptions(assigneeList);
  //     } else if (projectAssigneeList && projectAssigneeList.length === 0) {
  //       setMemberOptions([]);
  //     }
  //   }, [projectAssigneeList]);

  //   useEffect(() => {
  //     if ((selectedProject && !selectedProject.value) || !selectedProject) {
  //       setMemberOptions(organizationMembersList);
  //     }
  //   }, [selectedProject]);

  const onMemberSelect = person => {
    selectMember(person);
  };

  const clearSelectedMember = () => {
    clearMember();
  };

  return (
    <FilterContainer admin={localStorage.getItem('user_role') === 'admin'}>
      <InputWithLabelSection width="200px">
        <FilterLabel>{t('public_zone')}</FilterLabel>
        <Select
          isSearchable
          value={selectedPublicZone}
          options={publicZoneOptions}
          placeholder={`${t('select_public_zone')}...`}
          onChange={e => {
            onPublicZoneChange(e);
          }}
          styles={CreateProjectDropdown()}
          filterOption={filterOption}
        />
      </InputWithLabelSection>
      <InputWithLabelSection width="200px">
        <FilterLabel>{t('zone')}</FilterLabel>
        <Select
          isSearchable
          value={selectedOffice}
          options={officeOptions}
          placeholder="Select Office..."
          onChange={e => {
            selectOffice(e);
          }}
          styles={CreateProjectDropdown()}
          filterOption={filterOption}
        />
      </InputWithLabelSection>
      <InputWithLabelSection width="200px">
        <FilterLabel>{t('location')}</FilterLabel>
        <Select
          isSearchable
          value={selectedLocation}
          options={taskLocationOptions}
          placeholder={`${t('select_location')}...`}
          onChange={selectLocation}
          styles={CreateProjectDropdown()}
          filterOption={filterOption}
        />
      </InputWithLabelSection>
      {localStorage.getItem('user_role') === 'admin' && (
        <InputWithLabelSection>
          <FilterLabel>{t('assignee')}</FilterLabel>
          <div style={{ width: `40px` }}>
            <AddSingleMemberDropdown
              assigneeManage={selectedMember}
              updateState={onMemberSelect}
              membersList={organizationMembersList}
              clearAssignee={() => clearMember()}
            />
          </div>
        </InputWithLabelSection>
      )}
      <SearchWithButton
        itemName={""}
        label={t("search_task")}
        searchTermName={t('task_name')}
        searchInput={searchTerm}
        onInputChange={onSearchTermChange}
        handleSearch={handleSearchByName}
        handleClearSearch={handleClearSearch}
      />
      <InputWithLabelSection>
        <FilterLabel>{t('date_range')}</FilterLabel>
        {/* <DatePicker
          id="createIssuedDate"
          date={selectedDate}
          onDateChange={handleDateChange}
          dateDisplayFormat={'DD/MM/YYYY'}
          placeholder={'Select date'}
          allowPreviousDates
        /> */}
        <ReactDateRangePicker
          startDate={selectedDates.startDate ? selectedDates.startDate : null}
          startDateId="custom-date-range-start-date"
          endDate={selectedDates.endDate ? selectedDates.endDate : null}
          endDateId="custom-date-range-start-date"
          onDatesChange={setSelectedDates}
          dateDisplayFormat="DD/MM/YYYY"
          onDateSelect={onDateApply}
          selectLastWeek={selectLastWeek}
          selectLastMonth={selectLastMonth}
        />
      </InputWithLabelSection>
      <InputWithLabelSection width="160px">
        <FilterLabel>{t('status')}</FilterLabel>
        <Select
          isSearchable={false}
          value={taskStatus}
          options={statusOptions}
          placeholder="Status"
          onChange={e => {
            handleStatusChange(e);
          }}
          styles={FilterDropDownStyle({
            height: '40px',
            width: '130px',
            menuWidth: '130px',
          })}
        />
      </InputWithLabelSection>
    </FilterContainer>
  );
};

export default TaskFilter;
