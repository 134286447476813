import {
  CLEAR_OFFICE_CREATED_UPDATED,
  CLEAR_OFFICE_DETAILS,
  CREATE_OFFICE,
  GET_OFFICES_LIST,
  GET_OFFICES_LIST_SHORT,
  GET_PUBLIC_ZONES_LIST,
  SET_OFFICE_DETAILS,
  UPDATE_OFFICE,
} from '../../modules/constants';

export function getOfficesList(value) {
  return {
    type: GET_OFFICES_LIST,
    payload: value,
  };
}

export function getOfficesShortList(value) {
  return {
    type: GET_OFFICES_LIST_SHORT,
    payload: value,
  };
}

export function createOffice(value) {
  return {
    type: CREATE_OFFICE,
    payload: value,
  };
}

export function updateOffice(value) {
  return {
    type: UPDATE_OFFICE,
    payload: value,
  };
}

export function setOfficeDetails(value) {
  return {
    type: SET_OFFICE_DETAILS,
    payload: value,
  };
}

export function clearOfficeDetails() {
  return {
    type: CLEAR_OFFICE_DETAILS,
  };
}

export function clearOfficeCreatedUpdated() {
  return {
    type: CLEAR_OFFICE_CREATED_UPDATED,
  };
}

export function getPublicZoneList(value) {
  return {
    type: GET_PUBLIC_ZONES_LIST,
    payload: value,
  };
}
