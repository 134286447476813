import { connect } from "react-redux";
import IntegrationDetails from "./IntegrationDetails";

// Actions
import {
  getIntegrationWorkflowsList,
  getEnabledIntegrationsList,
  getIntegrationEnabledWorkflowsList,
  enableIntegrationWorkflow,
  runIntegrationWorkflow,
} from "../integrationsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,

  isLoading: state.integrations.isLoading,
  syncIsLoading: state.integrations.syncIsLoading,

  integrationWorkflowsList: state.integrations.integrationWorkflowsList,
  enabledIntegrationsList: state.integrations.enabledIntegrationsList,
  enabledWorkflowsList: state.integrations.enabledWorkflowsList,
  enabledWorkflowData: state.integrations.enabledWorkflowData,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getIntegrationWorkflowsList: (payload) =>
    dispatch(getIntegrationWorkflowsList(payload)),
  getEnabledIntegrationsList: (payload) =>
    dispatch(getEnabledIntegrationsList(payload)),
  getIntegrationEnabledWorkflowsList: (payload) =>
    dispatch(getIntegrationEnabledWorkflowsList(payload)),
  enableIntegrationWorkflow: (payload) =>
    dispatch(enableIntegrationWorkflow(payload)),
  runIntegrationWorkflow: (payload) =>
    dispatch(runIntegrationWorkflow(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationDetails);
