import { t } from 'i18next';
import {
  LOGIN_PROFILE_CREATED_RESET,
  REDEEM_CODE,
  REDEEM_CODE_FAILURE,
  REDEEM_CODE_SUCCESS,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

// ------------------------------------
// Action handler methods
// ------------------------------------
export const handleRedeemRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const handleRedeemRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification('success', 'Coupons added successfully', 2000);
    return {
      ...state,
      isLoading: false,
      registerNeeded: false,
      invalidCodes: [],
      redirect: true,
    };
  }
  return {
    ...state,
    registerNeeded: false,
    invalidCodes: [],
    isLoading: false,
  };
};

export const handleRedeemRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification('error', t('No internet connection found'), 3000);
    return {
      ...state,
      isLoading: false,
      redirect: false,
    };
  }
  let invalid = [];
  if (
    response &&
    response.data &&
    response.data.codes &&
    response.data.codes.invalid_codes
  ) {
    invalid = response.data.codes.invalid_codes;
  }
  let registrationNeeded = false;
  if (response && response.data && response.data.email) {
    registrationNeeded = true;
  }
  return {
    ...state,
    isLoading: false,
    invalidCodes: invalid,
    registerNeeded: registrationNeeded,
    redirect: false,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [REDEEM_CODE]: handleRedeemRequest,
  [REDEEM_CODE_SUCCESS]: handleRedeemRequestSuccess,
  [REDEEM_CODE_FAILURE]: handleRedeemRequestFailure,
  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  redirect: false,
  isLoading: false,
  invalidCodes: [],
  registerNeeded: false,
  passwordChanged: false,
};

export default function resetPasswordReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
