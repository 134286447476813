import NoDataComponent from 'components/NoDataComponent/NoDataComponent';
import { t } from 'i18next';
import { useState } from 'react';
import { Spinner } from 'reactstrap';
import { CommonFlex, CommonText } from 'styledComponents/common';

import AudioPlayer from '../TaskApproval/AudioPlayer';
import EvidencesModal from './EvidencesModal';
import { EvidenceThumbnail } from './taskDetailsStyles';

const TaskEvidence = ({ evidences }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedEvidence, setSelectedEvidence] = useState(false);

  const popupToggle = index => {
    setPopupOpen(!popupOpen);
    setSelectedEvidence(index);
  };
  return (
    <>
      <EvidencesModal
        isOpen={popupOpen}
        toggle={popupToggle}
        evidences={evidences?.filter(evidence => evidence.image)}
        selectedEvidence={selectedEvidence}
      />
      <CommonFlex gap="16px" direction="column" alignItems="start" width="100%">
        <CommonText name title fontSize="16px">
          {t('evidence')}
        </CommonText>
        {!evidences ? (
          <CommonFlex justifyContent={'center'} margin="auto">
            <Spinner size={25} />
          </CommonFlex>
        ) : (
          <>
            {evidences?.length > 0 ? (
              <>
                <CommonFlex gap="16px">
                  {evidences
                    ?.filter(evidence => evidence.image)
                    ?.map((evidence, index) => (
                      <EvidenceThumbnail
                        key={index}
                        width="auto"
                        onClick={() => popupToggle(index)}
                      >
                        <img
                          src={evidence.image}
                          alt=""
                          width="100%"
                          height={'100px'}
                        />
                      </EvidenceThumbnail>
                    ))}
                </CommonFlex>
                <CommonFlex gap="16px" wrap="wrap">
                  {evidences
                    ?.filter(evidence => evidence.audio)
                    ?.map((evidence, index) => (
                      <AudioPlayer key={index} audio={evidence.audio} />
                    ))}
                </CommonFlex>
              </>
            ) : (
              <CommonFlex width="100%" justifyContent="center">
                <NoDataComponent
                  title={t('no_evidence_found')}
                  padding="5vh 0"
                  imageHeight="140px"
                />
              </CommonFlex>
            )}
          </>
        )}
      </CommonFlex>
    </>
  );
};

export default TaskEvidence;
