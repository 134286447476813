import i18next, { t } from 'i18next';
import { useEffect, useState } from 'react';
import { TableRowAndBudgetCollapseContainer } from 'styledComponents/ProjectsNew';
import {
  CommonFlex,
  CommonImage,
  CommonText,
  Container,
  TableText,
} from 'styledComponents/common';
import { TableItem } from 'styledComponents/invoice';
import {
  CheckBoxCircle,
  CheckListStatus,
  CheckListTableContainer,
} from 'styledComponents/tasks';

import deleteIcon from '../../../assets/img/icons/delete.svg';
import editIcon from '../../../assets/img/icons/edit-pen.svg';
import TickWhite from '../../../assets/img/icons/tick_2.svg';
import SubTaskNameInput from '../EditTask/subTaskNameInput';
import { RoundIcon } from '../tasksStyles';

const CheckLists = ({
  checklistData,
  deleteCheckList,
  updateSubTaskRequest,
}) => {
  const [subTaskInEdit, setSubTaskInEdit] = useState(null);
  const handleChecklistDelete = id => {
    deleteCheckList(id);
  };
  const handleSubTaskEditButtonClick = id => {
    setSubTaskInEdit(id);
  };
  const handleCancelSubTaskEdit = () => {
    setSubTaskInEdit(null);
  };

  useEffect(() => {}, [checklistData]);
  return (
    <div>
      <CommonFlex gap="6px">
        <CommonText Text name title fontSize="16px">
          {t('checklists')}
        </CommonText>
        <CommonText Text name title fontSize="16px">
          {checklistData?.length > 0 && (
            <>
              {
                checklistData?.filter(
                  checkList => checkList.status === 'completed',
                ).length
              }
              /{checklistData?.length}
            </>
          )}
        </CommonText>
      </CommonFlex>
      {checklistData?.length > 0 && (
        <Container overflow="hidden">
          <CheckListTableContainer heading>
            <TableItem />
            <TableItem>
              <TableText>{t('name')}</TableText>
            </TableItem>
            <TableItem>
              <TableText margin="auto">{t('status')}</TableText>
            </TableItem>
            <TableItem />
          </CheckListTableContainer>
          {checklistData?.length > 0 &&
            checklistData.map((item, index) => (
              <TableRowAndBudgetCollapseContainer key={index}>
                <CheckListTableContainer>
                  <TableItem>
                    <CheckBoxCircle
                      checked={item?.status === 'completed'}
                      // onClick={() => onSingleDateAllPersonSelect(i)}
                    >
                      <img
                        src={TickWhite}
                        alt="tick"
                        width="16px"
                        height="16px"
                      />
                    </CheckBoxCircle>
                  </TableItem>
                  <TableItem key={index}>
                    {subTaskInEdit === item.id ? (
                      <SubTaskNameInput
                        data={item}
                        handleUpdateSubTaskName={updateSubTaskRequest}
                        cancelFunction={handleCancelSubTaskEdit}
                      />
                    ) : (
                      <TableText>{item.name}</TableText>
                    )}
                  </TableItem>
                  <TableItem key={index}>
                    <TableText margin="auto">
                      <CheckListStatus
                        status={item.status ? item.status : 'to do'}
                      >
                        {i18next.language && i18next.language === 'en'
                          ? t(item.status ? item.status : 'to do').toUpperCase()
                          : t(item.status ? item.status : 'to do')}
                      </CheckListStatus>
                    </TableText>
                  </TableItem>
                  <TableItem key={index}>
                    <CommonFlex gap="5px">
                      <RoundIcon
                        height="24px"
                        width="24px"
                        onClick={() => handleChecklistDelete(item.id)}
                      >
                        <img
                          src={deleteIcon}
                          alt="tick"
                          width="16px"
                          height="16px"
                        />
                      </RoundIcon>
                      {!item.default_sub_task_id && (
                        <RoundIcon
                          height="24px"
                          width="24px"
                          type="edit"
                          onClick={() => handleSubTaskEditButtonClick(item.id)}
                        >
                          <CommonImage src={editIcon} alt="" size="14px" />
                          {/* <TaskActionTooltip toolTipText={t('edit_task')} /> */}
                        </RoundIcon>
                      )}
                    </CommonFlex>
                  </TableItem>
                </CheckListTableContainer>
              </TableRowAndBudgetCollapseContainer>
            ))}
        </Container>
      )}
    </div>
  );
};

export default CheckLists;
