import AuthArtwork from '../../assets/img/common/auth_artwork.svg';
import AlbaitLogo from '../../assets/img/logo/albait-logo.png';
import Logo from '../../assets/img/logo/login_logo.svg';
import {
  AuthPageArtwork,
  AuthPageLeftContainer,
  LogoImg,
  SloganText,
} from '../../styledComponents/authLayout';
import { CommonFlex, CommonImage } from '../../styledComponents/common';

const AuthPageLeftSection = () => {
  return (
    <AuthPageLeftContainer>
      <CommonFlex gap="40px">
        <LogoImg src={Logo} alt="" />
        <CommonImage src={AlbaitLogo} alt="" style={{ width: '150px' }} />
      </CommonFlex>

      <SloganText>Apploye Etqan</SloganText>
      <AuthPageArtwork src={AuthArtwork} alt="" />
    </AuthPageLeftContainer>
  );
};

export default AuthPageLeftSection;
