//Effects
import { all, put, select, takeLatest } from 'redux-saga/effects';

import {
  CREATE_OFFICE,
  CREATE_OFFICE_FAILURE,
  CREATE_OFFICE_SUCCESS,
  GET_OFFICES_LIST,
  GET_OFFICES_LIST_FAILURE,
  GET_OFFICES_LIST_SHORT,
  GET_OFFICES_LIST_SHORT_FAILURE,
  GET_OFFICES_LIST_SHORT_SUCCESS,
  GET_OFFICES_LIST_SUCCESS,
  GET_PUBLIC_ZONES_LIST,
  GET_PUBLIC_ZONES_LIST_FAILURE,
  GET_PUBLIC_ZONES_LIST_SUCCESS,
  UPDATE_OFFICE,
  UPDATE_OFFICE_FAILURE,
  UPDATE_OFFICE_SUCCESS,
} from '../../modules/constants';
//Service
import { defaultApi } from '../../utils/axiosApi';

export function* handleGetOfficesList({ payload }) {
  const { page, office_name } = payload;
  try {
    const url = `/albait/offices/${page ? `?page=${page}` : ''}${
      office_name
        ? page
          ? `&name=${office_name}`
          : `?name=${office_name}`
        : ''
    }`;
    const response = yield defaultApi(url, 'GET', null);
    yield put({
      type: GET_OFFICES_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_OFFICES_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* handleGetOfficesShortList({ payload }) {
  const public_zone_id = payload?.public_zone_id;
  try {
    const url = `/albait/offices/short/${
      public_zone_id ? `?public_zone_id=${public_zone_id}` : ''
    }`;
    const response = yield defaultApi(url, 'GET', null);
    yield put({
      type: GET_OFFICES_LIST_SHORT_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_OFFICES_LIST_SHORT_FAILURE,
      payload: err,
    });
  }
}

export function* handleCreateOffice({ payload }) {
  try {
    const url = `/albait/offices/`;
    const response = yield defaultApi(url, 'POST', payload);
    yield put({
      type: CREATE_OFFICE_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: CREATE_OFFICE_FAILURE,
      payload: err,
    });
  }
}

export function* handleUpdateOffice({ payload }) {
  const { office_id, ...data } = payload;
  try {
    const url = `/albait/offices/${office_id}/`;
    const response = yield defaultApi(url, 'PUT', data);
    yield put({
      type: UPDATE_OFFICE_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_OFFICE_FAILURE,
      payload: err,
    });
  }
}

export function* handleGetPublicZoneList({ payload }) {
  try {
    const url = `/albait/public-zone/`;
    const response = yield defaultApi(url, 'GET', null);
    yield put({
      type: GET_PUBLIC_ZONES_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_PUBLIC_ZONES_LIST_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    // Get tasks list
    takeLatest(GET_OFFICES_LIST, handleGetOfficesList),
    takeLatest(GET_PUBLIC_ZONES_LIST, handleGetPublicZoneList),
    takeLatest(GET_OFFICES_LIST_SHORT, handleGetOfficesShortList),
    takeLatest(CREATE_OFFICE, handleCreateOffice),
    takeLatest(UPDATE_OFFICE, handleUpdateOffice),
  ]);
}
