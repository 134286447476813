import { t } from 'i18next';
import { Modal, ModalBody } from 'reactstrap';

import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import { ColoredButton, WhiteButton } from '../../styledComponents/buttons';
import { CommonText } from '../../styledComponents/common';
import {
  DefaultChecklistNotFoundModalButtonSection,
  ModalButtonSection,
} from '../../styledComponents/members';
import { DefaultChecklistNotFoundModalContainer } from './tasksStyles';

const DefaultChecklistNotFoundModal = ({
  isOpen,
  toggle,
  handleCreate,
  isLoading,
  handleNoClick,
  handleYesClick,
  isAddDefaultSubTaskLoading,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh`, minWidth: `600px` }}>
      <ModalBody style={{ padding: `30px`, backgroundColor: '#977C70', minHeight: `270px`  }}>
        <DefaultChecklistNotFoundModalContainer>
          <CommonText
            fontWeight="400"
            color="#ffffff"
            style={{ paddingLeft: `15px`,  textAlign: `right` }}
          >
            {t('subtask_not_found')}
          </CommonText>
          <CommonText fontSize="15px" color="#ffffff" style={{ paddingLeft: `15px`, textAlign: `right` }}>
            {t('would_you_mark_this_subtask_as_a_standard_task?')}
          </CommonText>
          <DefaultChecklistNotFoundModalButtonSection>
            <ColoredButton
              bgColor="#977C70"
              color="#ffffff"
              border="2px solid #ffffff"
              padding={isLoading ? '0 15px' : '0 30px'}
              onClick={handleNoClick}
            >
              {t('no')}
            </ColoredButton>{' '}
            <WhiteButton type="cancel" onClick={handleYesClick}>
              {isAddDefaultSubTaskLoading ? (
                <ButtonTextLoader loadingText={t('yes')} fontSize="13px" />
              ) : (
                t('yes')
              )}
            </WhiteButton>
          </DefaultChecklistNotFoundModalButtonSection>{' '}
        </DefaultChecklistNotFoundModalContainer>
      </ModalBody>
    </Modal>
  );
};

export default DefaultChecklistNotFoundModal;
