import LinearActivityBar from 'components/ProgressBar/LinearActivityBar';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../assets/img/avatar_5.svg';
import arrowWhiteIcon from '../../assets/img/icons/arrow_right_white.svg';
import deleteIcon from '../../assets/img/icons/delete.svg';
import duplicateIcon from '../../assets/img/icons/duplicate-new.svg';
import editIcon from '../../assets/img/icons/edit-pen.svg';
import tick from '../../assets/img/icons/tick-white-bold.svg';
import { CardText } from '../../components/DashboardCards/cardStyles';
import DropdownLoader from '../../components/DropdownLoader/DropdownLoader';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import AssigneeTooltip from '../../components/Tooltip/AssigneeTooltip';
import CommonTooltip from '../../components/Tooltip/CommonTooltip';
import TaskActionTooltip from '../../components/Tooltip/TaskActionTooltip';
import {
  ActionSection,
  BudgetCollapseSection,
  ClientDeadlineGrid,
  ListUpperSection,
  ProjectDuplicateDiv,
  ProjectDuplicateIcon,
  ProjectItemCount,
  ProjectListItem,
  ProjectListSection,
  ProjectTableContainer,
  ProjectTitleDiv,
  TableItem,
  TableRowAndBudgetCollapseContainer,
} from '../../styledComponents/ProjectsNew';
import { PrimaryButton } from '../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  Container,
  TableText,
} from '../../styledComponents/common';
import { AssigneeRoundImage } from '../../styledComponents/createProject';
import {
  CheckListStatus,
  IntegrationIcon,
  IntegrationIconDiv,
  TaskTable,
} from '../../styledComponents/tasks';
import { checkHtml, checkUrl, toHmins } from '../../utils/helper';
import { toHHMMSS } from '../../utils/helper';
import {
  DeleteButton,
  EditButton,
} from '../FieldServices/JobSites/jobSitesStyles';
import {
  CardBlock,
  GreenCircle,
  LateTag,
} from '../FieldServices/RouteMapNew/routeMapStyles';
import {
  ExpandIcon,
  ProjectExpandButton,
  ProjectListStatusBadge,
} from '../Projects/projectsStyles';
import { CommDropdownComp } from '../Tasks/AddAssigneeDropdown';
import {
  ActionIconSection,
  ApprovalBadge,
  AssigneeRoundImg,
  CollapseSection,
  RoundIcon,
  TaskInfoSection,
  TaskStatusButton,
} from './tasksStyles';

const TasksList = props => {
  const [duplicateTooltip, setDuplicateTooltip] = useState(null);

  const {
    selectedOrganization,
    expandedTaskId,
    setExpandedTaskId,
    visibilityChange,
    loadingTaskAssigneeId,
    toggleDeleteModal,
    isLoading,
    tasksList,
    createTask,
    history,
  } = props;

  const projectBudgetAndBillableIsLoading = false;
  const [isCheckInOut, setIsCheckInOut] = useState(false);

  const updateAssingeeList = (person, projectId, taskId) => {
    // if (
    //   selectedOrganization &&
    //   selectedOrganization.id &&
    //   projectId &&
    //   taskId &&
    //   person &&
    //   person.id
    // ) {
    //   const payload = {
    //     organization_id: selectedOrganization.id,
    //     project_id: projectId,
    //     task_id: taskId,
    //     user_id: person.id,
    //   };
    //   addTaskAssignee(payload);
    // }
  };

  const handleDeleteAssignee = (assigneeId, projectId, taskId) => {
    // if (
    //   selectedOrganization &&
    //   selectedOrganization.id &&
    //   projectId &&
    //   taskId &&
    //   assigneeId
    // ) {
    //   const payload = {
    //     organization_id: selectedOrganization.id,
    //     project_id: projectId,
    //     task_id: taskId,
    //     user_id: assigneeId,
    //   };
    //   deleteTaskAssignee(payload);
    // }
  };

  const handleExpandBudgetSection = taskId => {
    if (expandedTaskId && expandedTaskId === taskId) {
      setExpandedTaskId(null);
    } else {
      setExpandedTaskId(taskId);
    }
  };

  const duplicateTask = task => {
    const payload = {
      name: task.name,
      office_id: task.office.id,
      description: task.description,
      location: task.location ? task.location.id : null,
      task_type: task.task_type && task.task_type.id ? task.task_type.id : null,
      required_start_date: task.required_start_date,
      required_end_date: task.required_end_date,
    };
    createTask(payload);
  };

  const { t, i18n } = useTranslation();

  return (
    <Container style={{ paddingBottom: '20px' }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : tasksList && tasksList.length > 0 ? (
        <>
          <TaskTable heading>
            <TableItem>
              <TableText>{t('task_name')}</TableText>
            </TableItem>
            {/* <TableItem /> */}
            <TableItem>
              <TableText>{t('task_code')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('zone')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('location')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('assignee')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('status')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('approval')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('percentage')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('end_date')}</TableText>
            </TableItem>
            <TableItem />
          </TaskTable>
          {tasksList.map((task, index) => {
            const completedSubTasks = task.sub_tasks.filter(
              sub_task => sub_task.status === 'completed',
            ).length;
            const totalSubTasks = task.sub_tasks.length;
            const percentage =
              totalSubTasks > 0
                ? Math.ceil((completedSubTasks * 100) / totalSubTasks)
                : task.status === 'completed'
                ? 100
                : 0;
            const lastCheckinout = task?.checkinout?.sort(
              (a, b) => b.checkin_timestamp - a.checkin_timestamp,
            )[0];
            return (
              <TableRowAndBudgetCollapseContainer key={task.id}>
                <TaskTable>
                  <TableItem
                    style={{
                      gridTemplateColumns: 'auto auto',
                      alignItems: 'center',
                      gridColumnGap: '10px',
                    }}
                  >
                    <TableText
                      hoverPointer
                      hoverUnderline
                      name
                      fontWeight={
                        expandedTaskId && expandedTaskId === task.id && '600'
                      }
                      onClick={() =>
                        history.push(`/user/task-details?id=${task.id}`)
                      }
                    >
                      {task.name} - {completedSubTasks}/{totalSubTasks}
                    </TableText>
                  </TableItem>
                  {/* <TableItem>
                    <TableText name>
                      {completedSubTasks}/{totalSubTasks}
                    </TableText>
                  </TableItem> */}
                  <TableItem>
                    <TableText name>{task.task_code}</TableText>
                  </TableItem>{' '}
                  <TableItem>
                    <TableText name>{task.office?.name}</TableText>
                  </TableItem>{' '}
                  <TableItem>
                    <TableText name>{task.location?.name}</TableText>
                  </TableItem>{' '}
                  <TableItem padding="10px 0 10px 10px">
                    {task.assignees &&
                    task.assignees.length > 0 &&
                    task.assignees[0].id ? (
                      <AssigneeRoundImg
                        image={task.assignees[0].avatar || avatar5}
                      >
                        <AssigneeTooltip
                          toolTipText={`${task.assignees[0].first_name}${
                            task.assignees[0].last_name
                              ? ` ${task.assignees[0].last_name}`
                              : ``
                          }`}
                          phone={task.assignees[0].phone}
                          top="-60px"
                          left="-55px"
                        />
                      </AssigneeRoundImg>
                    ) : null}
                  </TableItem>
                  <TableItem>
                    <CheckListStatus
                      status={task.status ? task.status : 'to do'}
                    >
                      {i18next.language && i18next.language === 'en'
                        ? t(task?.status).toUpperCase()
                        : t(task?.status)}
                    </CheckListStatus>
                  </TableItem>
                  <TableItem>
                    <CheckListStatus
                      status={
                        task.approval_status ? task.approval_status : 'pending'
                      }
                    >
                      {i18next.language && i18next.language === 'en'
                        ? t(task?.approval_status).toUpperCase()
                        : t(task?.approval_status)}
                    </CheckListStatus>
                  </TableItem>
                  <TableItem>
                    <CommonFlex direction="column" gap="2px">
                      <TableText name>{percentage}%</TableText>
                      <LinearActivityBar
                        width="50px"
                        mobileWidth="50px"
                        height="10px"
                        mobileHeight="8px"
                        borderRadius="5px"
                        red="#FE5151"
                        yellow="#F2C741"
                        green="#20bead"
                        percent={percentage}
                        marginTop="0"
                        backgroundColor="#B5B5B5"
                      />
                    </CommonFlex>
                  </TableItem>
                  <TableItem>
                    {moment(task.required_end_date).format('DD/MM/YYYY')}
                  </TableItem>
                  <TableItem alignContent="start">
                    <CommonFlex gap="12px" wrap="wrap">
                      <ProjectExpandButton
                        margin="0"
                        onClick={() => handleExpandBudgetSection(task.id)}
                      >
                        <ExpandIcon
                          src={arrowWhiteIcon}
                          alt=""
                          open={expandedTaskId && expandedTaskId === task.id}
                        />
                      </ProjectExpandButton>{' '}
                      {localStorage.getItem('user_role') !== 'employee' &&
                        !localStorage.getItem('viewer_role') && (
                          <RoundIcon
                            type="duplicate"
                            height="24px"
                            width="24px"
                            onClick={() =>
                              history.push(`/user/duplicate-task?id=${task.id}`)
                            }
                          >
                            <CommonImage
                              src={duplicateIcon}
                              alt=""
                              size="14px"
                            />
                            <TaskActionTooltip toolTipText={t('duplicate')} />
                          </RoundIcon>
                        )}
                      {localStorage.getItem('user_role') !== 'employee' &&
                        !localStorage.getItem('viewer_role') && (
                          <>
                            {task.status &&
                            task.status === 'to do' &&
                            moment(task.required_start_date).isAfter(
                              moment(),
                            ) &&
                            !(task.checkinout?.length > 0) ? (
                              <RoundIcon
                                height="24px"
                                width="24px"
                                type="edit"
                                onClick={() =>
                                  history.push(`/user/edit-task?id=${task.id}`)
                                }
                              >
                                <CommonImage
                                  src={editIcon}
                                  alt=""
                                  size="14px"
                                />
                                <TaskActionTooltip
                                  toolTipText={t('edit_task')}
                                />
                              </RoundIcon>
                            ) : (
                              <div />
                            )}
                            {/* <RoundIcon
                        type="duplicate"
                        onClick={() => duplicateTask(task)}
                      >
                        <CommonImage src={duplicateIcon} alt="" size="14px" />
                        <TaskActionTooltip toolTipText={t('duplicate')} />
                      </RoundIcon> */}
                            {!moment(task.required_start_date).isBefore(
                              moment().subtract(30, 'minutes'),
                            ) &&
                            task.status === 'to do' &&
                            !(task.checkinout?.length > 0) ? (
                              <RoundIcon
                                height="24px"
                                width="24px"
                                onClick={() => toggleDeleteModal(task.id)}
                              >
                                <CommonImage
                                  src={deleteIcon}
                                  alt=""
                                  size="14px"
                                />
                                <TaskActionTooltip
                                  toolTipText={t('delete_task')}
                                />
                              </RoundIcon>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                    </CommonFlex>
                  </TableItem>
                </TaskTable>
                <CollapseSection
                  open={expandedTaskId && expandedTaskId === task.id}
                >
                  <TaskInfoSection>
                    <CardBlock>
                      <CardText name fontSize="13px" title nowrap>
                        {task.required_start_date &&
                          moment(task.required_start_date).format(
                            'h:mm a',
                          )}{' '}
                        -{' '}
                        {task.required_end_date &&
                          moment(task.required_end_date).format('h:mm a')}
                      </CardText>
                      <CardText fontSize="12px" label title>
                        {t('start_end')}
                      </CardText>
                    </CardBlock>
                    <CardBlock>
                      <CardText name fontSize="13px" title nowrap>
                        {lastCheckinout?.checkin_timestamp ? (
                          <>
                            {moment
                              .unix(lastCheckinout.checkin_timestamp)
                              .format('h:mm a')}
                            {moment
                              .unix(lastCheckinout.checkin_timestamp)
                              .isAfter(moment(task?.required_start_date)) ? (
                              <LateTag>{t('late')}</LateTag>
                            ) : (
                              <GreenCircle>
                                <img src={tick} alt="" width="100%" />
                              </GreenCircle>
                            )}
                          </>
                        ) : (
                          `-`
                        )}
                      </CardText>
                      <CardText fontSize="12px" label title>
                        {t('checked_in')}
                      </CardText>{' '}
                      {lastCheckinout?.checkin_timestamp && (
                        <CommonText>
                          {moment
                            .unix(lastCheckinout.checkin_timestamp)
                            .format('DD/MM/YYYY')}
                        </CommonText>
                      )}
                    </CardBlock>
                    <CardBlock>
                      <CardText name fontSize="13px" title nowrap>
                        {lastCheckinout?.checkout_timestamp ? (
                          <>
                            {moment
                              .unix(lastCheckinout.checkout_timestamp)
                              .format('h:mm a')}
                            {moment
                              .unix(lastCheckinout.checkout_timestamp)
                              .isAfter(moment(task?.required_end_date)) ? (
                              <LateTag>{t('late')}</LateTag>
                            ) : (
                              <GreenCircle>
                                <img src={tick} alt="" width="100%" />
                              </GreenCircle>
                            )}
                          </>
                        ) : (
                          `-`
                        )}
                      </CardText>
                      <CardText fontSize="12px" label title>
                        {t('checked_out')}
                      </CardText>{' '}
                      {lastCheckinout?.checkout_timestamp && (
                        <CommonText>
                          {moment
                            .unix(lastCheckinout.checkout_timestamp)
                            .format('DD/MM/YYYY')}
                        </CommonText>
                      )}
                    </CardBlock>
                    <CardBlock>
                      <CardText name fontSize="13px" title nowrap>
                        {lastCheckinout?.checkout_timestamp &&
                        lastCheckinout?.checkin_timestamp
                          ? toHmins(
                              lastCheckinout?.checkout_timestamp -
                                lastCheckinout?.checkin_timestamp,
                            )
                          : `-`}
                      </CardText>
                      <CardText fontSize="12px" label title>
                        {t('worked')}
                      </CardText>
                    </CardBlock>
                    {task.task_type && task.task_type.id && (
                      <CardBlock>
                        <CardText name fontSize="13px" title nowrap>
                          {i18n.language && i18n.language === 'en'
                            ? task.task_type.name
                            : task.task_type.arabic_name}
                        </CardText>
                        <CardText fontSize="12px" label title>
                          {t('task_type')}
                        </CardText>
                      </CardBlock>
                    )}

                    <CardBlock>
                      <CardText name fontSize="13px" title>
                        {task.location &&
                          task.location.address &&
                          task.location.address}
                      </CardText>
                      <CardText fontSize="12px" label title>
                        {t('location_address')}
                      </CardText>
                    </CardBlock>
                  </TaskInfoSection>
                </CollapseSection>
              </TableRowAndBudgetCollapseContainer>
            );
          })}
        </>
      ) : (
        <NoDataComponent
          title={t('no_tasks_found')}
          padding="5vh 0"
          imageHeight="140px"
        />
      )}
    </Container>
  );
};

export default TasksList;
