const commonContainerStyles = (styles, theme) => ({
  ...styles,
  pointerEvents: 'auto',
});

const commonControlStyles = (styles, state, theme) => ({
  ...styles,
  color: theme.dropdown.textColor,
  fontSize: theme.dropdown.fontSize,
  border: `1px solid ${
    state.menuIsOpen
      ? theme.dropdown.borderColor.focus
      : theme.dropdown.borderColor.default
  }`,
  borderRadius: theme.dropdown.borderRadius,
  background: theme.dropdown.bgColor,
  boxShadow: state.menuIsOpen && theme.dropdown.focusShadow,
  height: 'auto',
  cursor: state.isDisabled ? 'not-allowed' : 'pointer',
  '&:hover': {
    borderColor: state.menuIsOpen
      ? theme.dropdown.borderColor.focus
      : theme.dropdown.borderColor.hover,
    boxShadow: theme.dropdown.focusShadow,
  },
});

const commonOptionStyles = (styles, state, theme) => ({
  ...styles,
  color: state.isSelected
    ? theme.dropdown.option.selectedTextColor
    : theme.dropdown.option.textColor,
  cursor: state.isDisabled ? 'not-allowed' : 'pointer',
  fontFamily: theme.defaultFontFamily,
  fontSize: theme.dropdown.fontSize,
  fontWeight: state.isSelected && theme.dropdown.option.selectedFontWeight,
  backgroundColor: state.isSelected
    ? theme.dropdown.option.selectedBgColor
    : theme.dropdown.bgColor,
  '&:hover': {
    backgroundColor: !state.isSelected && theme.dropdown.option.hoverBgColor,
  },
  '&:active': {
    backgroundColor: state.isSelected
      ? theme.dropdown.option.selectedBgColor
      : theme.dropdown.option.hoverBgColor,
  },
});

const commonMenuListStyles = (styles, theme) => ({
  ...styles,
  borderRadius: theme.dropdown.scrollbar.borderRadius,
  '::-webkit-scrollbar': {
    width: theme.dropdown.scrollbar.width,
  },
  '::-webkit-scrollbar-track': {
    background: theme.dropdown.scrollbar.trackBgColor,
    borderRadius: theme.dropdown.scrollbar.thumbBorderRadius,
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.dropdown.scrollbar.thumbBgColor,
    borderRadius: theme.dropdown.scrollbar.thumbBorderRadius,
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: theme.dropdown.scrollbar.thumbHoverBgColor,
  },
  padding: '0px',
});

const commonMenuStyles = (styles, state, theme) => ({
  ...styles,
  marginTop: '12px',
  border: `1px solid ${theme.dropdown.menu.borderColor}`,
  borderRadius: theme.dropdown.menu.borderRadius,
  outline: 'none',
  boxShadow: 'none',
  zIndex: '5',
});

const commonSingleValueStyles = (styles, theme) => ({
  ...styles,
  fontFamily: theme.defaultFontFamily,
  color: theme.dropdown.textColor,
});

const commonPlaceholderStyles = (styles, theme) => ({
  ...styles,
  color: theme.dropdown.placeholder.color,
});

const commonDropdownIndicatorStyles = (styles, state, theme) => ({
  ...styles,
  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  transition: 'transform 0.35s ease',
});

const commonInputStyles = (styles, state, theme) => ({
  ...styles,
  fontFamily: theme.defaultFontFamily,
  fontSize: theme.dropdown.fontSize,
  color: theme.dropdown.textColor,
});

export {
  commonContainerStyles,
  commonControlStyles,
  commonOptionStyles,
  commonMenuListStyles,
  commonMenuStyles,
  commonSingleValueStyles,
  commonPlaceholderStyles,
  commonDropdownIndicatorStyles,
  commonInputStyles,
};
