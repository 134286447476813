import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { colors } from 'styles/light/colors';

const CircularProgressbarNewWithChildren = ({ percentage }) => {
  colors;
  return (
    <CircularProgressbarWithChildren
      value={percentage}
      strokeWidth={8}
      styles={{
        root: { width: '100%' },
        path: { stroke: colors.success },
        trail: { stroke: '#f7f7f7' },
        text: { fill: colors.success, fontSize: '8px' },
      }}
    >
      <div style={{ fontSize: 8, margin: 'auto' }}>
        <strong>{percentage}%</strong>
      </div>
    </CircularProgressbarWithChildren>
  );
};

export default CircularProgressbarNewWithChildren;
