import styled, { css } from 'styled-components';

const projectColors = ['#9A96F9', '#82C4BB', '#FECF34', '#F9828B'];

export const FilterContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  /* grid-template-columns: ${({ columns, admin }) =>
    columns
      ? columns
      : admin
      ? `200px 200px auto 240px 266px 160px`
      : `200px 200px 240px 266px 160px`}; */
  justify-content: start;
  grid-column-gap: ${({ gap }) => gap || '22px'};
  /* @media (max-width: 800px) {
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    grid-column-gap: 10px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    justify-content: start;
    grid-row-gap: 15px;
  }
   @media (max-width: 500px) {
    justify-content: center;
  } */
`;
export const FilterLeftGrid = styled.div`
  display: grid;
  grid-template-columns: 20% auto 55%;
  justify-content: space-between;
  grid-column-gap: 30px;
  @media (max-width: 800px) {
    grid-column-gap: 10px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    justify-content: start;
    grid-row-gap: 15px;
  }
`;
export const SearchTaskSection = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-column-gap: 30px;
  @media (max-width: 800px) {
    grid-column-gap: 10px;
  }
  @media (max-width: 374px) {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }
`;
export const TaskTable = styled.div`
  display: grid;
  grid-template-columns:
    minmax(140px, 1.8fr)
    /* minmax(100px, 1.8fr) 40px */
    minmax(60px, 90px) minmax(70px, 90px) minmax(70px, 100px) minmax(60px, 90px)
    minmax(80px, 150px)
    100px 100px
    100px 140px;
  grid-column-gap: 15px;
  justify-content: space-between;
  padding: 0 15px;
  border-bottom: ${({ heading, theme }) =>
    heading && `1px solid ${theme.colors.border.secondary}`};

  :nth-of-type(even) {
    background-color: ${({ heading }) => !heading && `#f8f9fc`};
  }
  /* Align last three columns to center */
  & > :nth-child(n + 5) {
    justify-self: center;
  }
  /* Alignment for the very last column */
  & > :last-child {
    display: flex;
    justify-self: start;
    align-items: center;
  }
`;

export const SubTaskTable = styled.div`
  display: grid;
  grid-template-columns:
    minmax(230px, 300px) minmax(120px, 160px) minmax(110px, 130px)
    minmax(60px, 90px) 125px minmax(120px, 200px) minmax(120px, 200px) 30px;
  grid-column-gap: 15px;
  width: 100%;
  justify-content: space-between;
  padding: 0 15px;
  border-bottom: ${({ heading, theme }) =>
    heading && `1px solid ${theme.colors.border.secondary}`};

  :nth-of-type(even) {
    background-color: ${({ heading }) => !heading && `#f8f9fc`};
  }
  /* Align last three columns to center */
  & > :nth-child(n + 4) {
    justify-self: center;
  }
  /* Alignment for the very last column */
  & > :last-child {
    display: flex;
    justify-self: start;
    align-items: center;
  }
`;

export const ActionTooltip = styled.div`
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: -38px;
  left: -50px;
  padding: 0px 4px;
  border-radius: ${({ theme }) => theme.borderRadii.tooltip};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.textMain};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  background-color: ${({ theme }) => theme.colors.main.white};
  min-width: 120px;
  height: 30px;
  z-index: 5;
  &::before,
  ::after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: ${({ theme }) => theme.colors.main.white};
    border-width: 5px;
    margin-left: -5px;
  }
  &::before {
    border-color: rgba(194, 204, 225, 0);
    border-top-color: ${({ theme }) => theme.colors.border.secondary};
    border-width: 6px;
    margin-left: -6px;
  }
`;

export const ChecklistsTooltip = styled.div`
  position: absolute;
  display: none;
  justify-content: center;
  justify-items: center;
  align-items: center;
  top: -58px;
  left: ${({ left }) => (left ? left : '0%')};

  padding: 2px 4px;
  border-radius: ${({ theme }) => theme.borderRadii.tooltip};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.textMain};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  background-color: ${({ theme }) => theme.colors.main.white};
  min-width: 120px;
  height: 50px;
  text-align: center;
  z-index: 500;
  &::before,
  ::after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: ${({ theme }) => theme.colors.main.white};
    border-width: 5px;
    margin-left: -5px;
  }
  &::before {
    border-color: rgba(194, 204, 225, 0);
    border-top-color: ${({ theme }) => theme.colors.border.secondary};
    border-width: 6px;
    margin-left: -6px;
  }
`;

export const RoundComplete = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  border: ${({ complete, theme }) =>
    `1px solid ${
      complete ? theme.colors.border.primary : theme.colors.border.secondary
    }`};
  cursor: pointer;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: auto;
  background-color: ${({ complete, theme }) =>
    complete && theme.colors.main.primary};
  &:hover {
    background-color: rgba(32, 190, 173, 0.5);
    border: none;
  }
  &:hover ${ActionTooltip} {
    display: flex;
  }
`;
export const CompletedDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0 0 25px;
`;
export const CompletedButton = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 20px;
  grid-column-gap: 3px;
  padding: 5px 15px;
  border-radius: ${({ theme }) => theme.button.borderRadius};
  background-color: ${({ theme }) => theme.button.blueButton.bgColor};
  color: ${({ theme }) => theme.button.blueButton.textColor};
  font-size: 13px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.button.blueButton.hoverBgColor};
  }
`;

export const CollapseTable = styled.div`
  display: grid;
  max-height: ${props => (props.active ? `${props.length * 100}px` : `0`)};
  overflow: ${props => (props.active ? `visible` : `hidden`)};
  transition: max-height 0.8s ease;
  background-color: white;
  @media (max-width: 450px) {
    padding: 0 10px;
  }
`;

export const CreateTaskContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ loading }) => (loading ? `100%` : `50% 50%`)};
  justify-content: space-between;
  grid-column-gap: 25px;
`;
export const CreateTaskInput = styled.input`
  padding-left: 15px;
  color: ${({ textColor, theme }) => textColor || theme.input.textColor};
  font-size: ${({ fontSize, theme }) => fontSize || theme.input.fontSize};
  border: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: ${({ fontSize, theme }) =>
      fontSize || theme.input.placeholder.size};
    color: ${({ theme }) => theme.input.placeholder.color};
  }
`;

export const CreateTaskButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 10px;
  padding-right: 25px;
`;

export const TaskParentProjectName = styled.div`
  font-size: ${({ fontSize, theme }) => fontSize || theme.font.pageText.size};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.white};
  background: ${({ index }) => projectColors[index % 4]};
  margin-left: 25px;
  padding: 5px 10px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
`;
export const NumberOfTasks = styled.div`
  margin-left: 25px;
  font-size: 13px;
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
`;

export const NoTaskDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  padding: 30px 0px;
  margin: 20px 25px 0px;
`;
export const NoTaskArtwork = styled.img`
  height: 84px;
  width: 84px;
`;
export const NoTaskText = styled.div`
  color: ${({ theme }) => theme.colors.main.labelText};
  margin-top: 10px;
`;

export const IntegrationIconDiv = styled.div`
  position: relative;
`;
export const IntegrationIcon = styled.img`
  height: ${({ size }) => size || '18px'};
  width: ${({ size }) => size || '18px'};
  margin: ${({ margin }) => margin || '0'};
`;

export const CheckListTableContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns ? columns : `40px 3fr 1fr 40px`};
  grid-column-gap: 15px;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: ${({ heading, theme }) =>
    heading && `1px solid ${theme.colors.border.secondary}`};
  :nth-of-type(even) {
    background-color: ${({ heading, theme }) =>
      !heading && `${theme.colors.main.tableRowBackground}`};
  }
`;

export const CheckBoxCircle = styled.div`
  display: grid;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  align-content: center;
  grid-template-columns: auto;
  border: 1px solid
    ${({ checked, theme }) =>
      theme.colors.main.success ? checked : theme.colors.main.success};
  border-radius: 50%;
  width: 22px;
  height: 22px;
  cursor: ${({ notAllowed }) => (notAllowed ? 'not-allowed' : 'pointer')};
  background-color: ${({ checked, theme }) =>
    checked && theme.colors.main.success};
  &:hover {
    border-color: ${({ notAllowed, checked, theme }) =>
      !notAllowed &&
      (checked ? theme.colors.main.primary : theme.colors.main.success)};
    background-color: ${({ notAllowed, checked, theme }) =>
      !notAllowed && (checked ? '' : theme.colors.main.success)};
    opacity: ${({ notAllowed, checked, theme }) =>
      !notAllowed && checked ? 0.5 : 1};
  }
`;

export const CheckListNameInputField = styled.input`
  border: 2px solid #f8f4f3;
  border-radius: ${({ borderRadius }) => borderRadius || '10px'};
  color: ${({ textColor, theme }) => textColor || theme.input.textColor};
  font-size: ${({ fontSize, theme }) => fontSize || theme.input.fontSize};
  padding: 5px 10px;
  width: 100%;
  min-height: ${({ height, theme }) => height || theme.input.minHeight};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  &:hover {
    border-color: ${({ hoverBorderColor, theme }) =>
      hoverBorderColor || theme.input.borderColor.hover};
    box-shadow: ${({ theme }) => theme.input.focusShadow};
  }
  &:focus {
    outline: none;
    border-color: ${({ focusBorderColor, theme }) =>
      focusBorderColor || theme.input.borderColor.focus};
    box-shadow: ${({ theme }) => theme.input.focusShadow};
  }
`;
export const AddCheckListButton = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  gap: ${({ gap }) => gap ?? `20px`};
  padding: 5px 15px;
  font-size: 13px;
  cursor: pointer;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  padding: ${({ padding }) => padding || '0'};
  margin: ${({ margin }) => margin || '0'};
  background-color: ${({ bgColor }) => bgColor};
`;

export const CheckListStatus = styled.div`
  display: flex;
  width: fit-content;
  font-size: 12px;
  background-color: ${({ bgColor, status, theme }) =>
    bgColor ||
    (status === 'completed' || status === 'approved'
      ? theme.colors.main.completedBackground
      : status === 'not completed' || status === 'rejected'
      ? theme.colors.main.notCompletedBackground
      : status === 'to do'
      ? theme.colors.main.openBackground
      : theme.colors.main.missedBackground)};
  border-radius: ${({ borderRadius, theme }) => borderRadius || '20px'};
  color: ${({ color, status, theme }) =>
    color ||
    (status === 'completed' || status === 'approved'
      ? theme.colors.main.completedText
      : status === 'not completed' || status === 'rejected'
      ? theme.colors.main.notCompletedText
      : status === 'to do'
      ? theme.colors.main.openText
      : theme.colors.main.missedText)};
  border-radius: ${({ borderRadius, theme }) => borderRadius || '20px'};
  padding: ${({ padding, theme }) => padding || '8px'};
  cursor: ${({ cursor }) => cursor};
`;
export const TaskStatusButton = styled.div`
  display: flex;
  width: fit-content;
  font-size: 12px;
  background-color: ${({ bgColor, status, theme }) =>
    bgColor ||
    (status === 'completed' || status === 'approved'
      ? theme.colors.main.completedBackground
      : status === 'not completed' || status === 'rejected'
      ? theme.colors.main.notCompletedBackground
      : status === 'to do'
      ? theme.colors.main.openBackground
      : theme.colors.main.missedBackground)};
  color: ${({ color, status, theme }) =>
    color ||
    (status === 'completed' || status === 'approved'
      ? theme.colors.main.completedText
      : status === 'not completed' || status === 'rejected'
      ? theme.colors.main.notCompletedText
      : status === 'to do'
      ? theme.colors.main.openText
      : theme.colors.main.missedText)};
  border: 1px solid;
  border-radius: ${({ borderRadius, theme }) => borderRadius || '5px'};
  padding: ${({ padding, theme }) => padding || '8px'};
  cursor: ${({ cursor }) => cursor};
`;

export const CheckEvidenceButton = styled.div`
  display: flex;
  width: fit-content;
  font-size: 12px;
  cursor: ${({ cursor }) => cursor || 'pointer'};
  background-color: ${({ theme }) => theme.colors.main.checkEvidenceBackground};
  border-radius: ${({ borderRadius }) => borderRadius || '5px'};
  border: 1px solid;
  color: ${({ color, theme }) => color || theme.colors.main.white};
  padding: ${({ padding, theme }) => padding || '8px'};
`;

export const ArrowIconContainer = styled.div`
  width: 36px;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.main.gray};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hover.gray};
  }
`;
