import {
  ExpandIcon,
  ProjectExpandButton,
} from 'custom_modules/Projects/projectsStyles';
import { t } from 'i18next';
import i18next from 'i18next';
import { useEffect } from 'react';
import { TableRowAndBudgetCollapseContainer } from 'styledComponents/ProjectsNew';
import {
  CommonFlex,
  CommonText,
  Container,
  TableText,
} from 'styledComponents/common';
import { TableItem } from 'styledComponents/invoice';
import {
  CheckBoxCircle,
  CheckEvidenceButton,
  CheckListStatus,
  CheckListTableContainer,
  ChecklistsTooltip,
} from 'styledComponents/tasks';

import arrowWhiteIcon from '../../../assets/img/icons/arrow_right_white.svg';
import TickWhite from '../../../assets/img/icons/tick_2.svg';
import { CheckListTooltipContainer, CollapseSection } from '../tasksStyles';
import Comment from './comment';
import TaskEvidence from './taskEvidence';

const CheckListsTaskDetails = ({
  checklistData,
  deleteCheckList,
  expandedSubTaskId,
  setExpandedSubTaskId,
  updateSubTaskRequest,
  canUpdateSubTask,
  getSubTaskEvidence,
  taskId,
}) => {
  const handleChecklistDelete = id => {
    deleteCheckList(id);
  };
  const handleExpandEvidenceSection = (subTaskId, hasEvidence) => {
    if (!hasEvidence && getSubTaskEvidence) {
      getSubTaskEvidence({
        task_id: taskId,
        sub_task_id: subTaskId,
      });
    }
    if (expandedSubTaskId && expandedSubTaskId === subTaskId) {
      setExpandedSubTaskId(null);
    } else {
      setExpandedSubTaskId(subTaskId);
    }
  };
  const handleSubTaskStatusUpdate = sub_task => {
    updateSubTaskRequest(sub_task.id, {
      status: sub_task.status === 'completed' ? 'not completed' : 'completed',
    });
  };
  const checkExpandedSubTask = () => {
    const expandedSubTask = checklistData.find(
      data => data.id === expandedSubTaskId,
    );
    if (expandedSubTask) {
      !(
        !expandedSubTask.evidences ||
        expandedSubTask.evidences?.length > 0 ||
        expandedSubTask.supervisor_note !== null ||
        expandedSubTask.employee_note !== null
      ) && setExpandedSubTaskId(null);
    }
  };

  useEffect(() => {
    checkExpandedSubTask();
  }, [checklistData]);
  return checklistData?.length > 0 ? (
    <div>
      <CommonFlex gap="6px">
        {/* <img src={plusIcon} alt="" style={{ height: '15px', width: '15px' }} /> */}
        <CommonText Text name title fontSize="16px">
          {t('checklists')}
        </CommonText>
      </CommonFlex>
      <Container overflow="hidden">
        <CheckListTableContainer heading columns="40px 3fr 1fr 1fr 40px">
          <TableItem />
          <TableItem>
            <TableText>{t('name')}</TableText>
          </TableItem>
          <TableItem>
            <TableText margin="auto">{t('status')}</TableText>
          </TableItem>{' '}
          <TableItem>
            <TableText margin="auto">{t('evidence')}</TableText>
          </TableItem>
          <TableItem />
        </CheckListTableContainer>
        {checklistData?.length > 0 &&
          checklistData.map((item, index) => (
            <TableRowAndBudgetCollapseContainer key={index}>
              <CheckListTableContainer columns="40px 3fr 1fr 1fr 40px">
                <TableItem
                  onClick={() => {
                    canUpdateSubTask && handleSubTaskStatusUpdate(item);
                  }}
                >
                  <CheckBoxCircle
                    checked={item.status === 'completed'}
                    notAllowed={!canUpdateSubTask}
                  >
                    <img
                      src={TickWhite}
                      alt="tick"
                      width="16px"
                      height="16px"
                    />
                  </CheckBoxCircle>
                </TableItem>
                <TableItem key={index}>
                  <TableText>{item.name}</TableText>
                </TableItem>
                <TableItem key={index}>
                  <TableText margin="auto">
                    <CheckListStatus
                      status={item.status ? item.status : 'to do'}
                    >
                      {i18next.language && i18next.language === 'en'
                        ? t(item.status).toUpperCase()
                        : t(item.status)}
                    </CheckListStatus>
                  </TableText>
                </TableItem>
                <TableItem>
                  <CheckListTooltipContainer>
                    <TableText margin="auto">
                      <CheckEvidenceButton
                        cursor={
                          !(
                            !item.evidences ||
                            item.evidences?.length > 0 ||
                            item.supervisor_note !== null ||
                            item.employee_note !== null
                          ) && 'not-allowed'
                        }
                        onClick={() =>
                          (!item.evidences ||
                            item.evidences?.length > 0 ||
                            item.supervisor_note !== null ||
                            item.employee_note !== null) &&
                          handleExpandEvidenceSection(item.id, item.evidences)
                        }
                      >
                        {t('check_evidence')}
                      </CheckEvidenceButton>{' '}
                    </TableText>
                    {!(
                      !item.evidences ||
                      item.evidences?.length > 0 ||
                      item.supervisor_note !== null ||
                      item.employee_note !== null
                    ) && (
                      <ChecklistsTooltip>{t('no_evidences')}</ChecklistsTooltip>
                    )}
                  </CheckListTooltipContainer>
                </TableItem>
                <TableItem key={index}>
                  <ProjectExpandButton
                    cursor={
                      !(
                        !item.evidences ||
                        item.evidences?.length > 0 ||
                        item.supervisor_note !== null ||
                        item.employee_note !== null
                      ) && 'not-allowed'
                    }
                    onClick={() =>
                      (!item.evidences ||
                        item.evidences?.length > 0 ||
                        item.supervisor_note !== null ||
                        item.employee_note !== null) &&
                      handleExpandEvidenceSection(item.id, item.evidences)
                    }
                  >
                    <ExpandIcon
                      src={arrowWhiteIcon}
                      alt=""
                      open={expandedSubTaskId && expandedSubTaskId === item.id}
                    />
                  </ProjectExpandButton>
                </TableItem>
              </CheckListTableContainer>
              <CollapseSection
                open={expandedSubTaskId && expandedSubTaskId === item.id}
              >
                <Container padding="30px" borderColor="#977C70" margin="20px">
                  <TaskEvidence evidences={item.evidences} />
                </Container>
                <CommonFlex
                  direction="column"
                  alignItems="start"
                  padding="20px"
                >
                  <CommonText name title fontSize="16px">
                    {t('comments')}
                  </CommonText>
                  <Comment type="employee_note" comment={item.employee_note} />
                  <Comment
                    type="supervisor_note"
                    comment={item.supervisor_note}
                  />
                </CommonFlex>
              </CollapseSection>
            </TableRowAndBudgetCollapseContainer>
          ))}
      </Container>
    </div>
  ) : (
    <></>
  );
};

export default CheckListsTaskDetails;
