import CircularProgressbarNewWithChildren from 'components/CircularProgressbar/CircularProgressbarSmallWithChildren';
import ComponentCircleLoader from 'components/Loaders/ComponentCircleLoader';
import StaticTaskLocationModal from 'custom_modules/TaskLocations/StaticTaskLocationModal';
import { t } from 'i18next';
import i18next from 'i18next';
import moment from 'moment';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { CheckListStatus } from 'styledComponents/tasks';
import { toHHMMSS, toHmins } from 'utils/helper';

import officeIcon from '../../../assets/img/Sidebar/offices-icon.svg';
import calendarIcon from '../../../assets/img/icons/Calendar.svg';
import documentIcon from '../../../assets/img/icons/Document.svg';
import assigneeIcon from '../../../assets/img/icons/assignee.svg';
import tick from '../../../assets/img/icons/tick-white-bold.svg';
import statusIcon from '../../../assets/img/statusIcon.svg';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import { CardText } from '../../../components/DashboardCards/cardStyles';
import { ListUpperSection } from '../../../styledComponents/ProjectsNew';
import { WhiteButton } from '../../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonText,
  Container,
  ContentHeader,
  FirstWordRound,
  InputLabel,
  PageTitle,
} from '../../../styledComponents/common';
import {
  CardBlock,
  GreenCircle,
  InfoSectionCard,
  LateTag,
} from '../../FieldServices/RouteMapNew/routeMapStyles';
import EvidencesModal from './EvidencesModal';
import ApprovalSection from './approvalSection';
import CheckListsTaskDetails from './checkLists';
import Comment from './comment';
import {
  FullWidthWithTopBorder,
  LabelTextBlock,
  TaskDetailsInfoSection,
} from './taskDetailsStyles';
import TaskEvidence from './taskEvidence';

const TaskDetails = ({
  isLoading,
  orgMembersList,
  officesShortList,
  addTaskLocationLoading,
  getTaskDetails,
  taskDetails,
  editTask,
  callback,
  locationsList,
  createdTaskLocationInfo,
  memberShortListIsLoading,
  getOrganizationMembersList,
  getTaskType,
  createTask,
  getTaskLocation,
  addTaskLocation,
  getOfficesShortList,
  taskTypeIsLoading,
  taskTypeList,
  history,
  location,
  approvalMode,
  updateTaskApproval,
  selectedTab = 'submitted',
  editSubTask,
  getSubTaskEvidence,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedEvidence, setSelectedEvidence] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [expandedSubTaskId, setExpandedSubTaskId] = useState(null);
  const [seeLocationModalOpen, setSeeLocationModalOpen] = useState(false);
  const [selectedTaskLocation, setSelectedTaskLocation] = useState(null);

  const canUpdateSubTask =
    taskDetails?.approval_status === 'pending' &&
    selectedTab === 'submitted' &&
    localStorage.getItem('user_role') !== 'employee';

  const popupToggle = index => {
    setPopupOpen(!popupOpen);
    setSelectedEvidence(index);
  };
  const handleShowLocation = location => {
    setSelectedTaskLocation(location);

    setTimeout(() => {
      setSeeLocationModalOpen(true);
    }, 300);
  };

  const completedSubTasks = taskDetails?.sub_tasks?.filter(
    sub_task => sub_task.status === 'completed',
  ).length;
  const totalSubTasks = taskDetails?.sub_tasks?.length;
  const percentage =
    totalSubTasks > 0
      ? Math.ceil((completedSubTasks * 100) / totalSubTasks)
      : taskDetails?.status === 'completed'
      ? 100
      : 0;
  const lastCheckinout = taskDetails?.checkinout?.sort(
    (a, b) => b.checkin_timestamp - a.checkin_timestamp,
  )[0];

  const updateTaskStatusRequest = data => {
    const payload = { task_id: taskDetails?.id, data };
    updateTaskApproval(payload);
  };
  const updateSubTaskRequest = (sub_task_id, data) => {
    const payload = {
      task_id: taskDetails?.id,
      sub_task_id: sub_task_id,
      data,
    };
    editSubTask(payload);
    // setTimeout(() => {
    //   refreshTaskComponent();
    // }, 350);
  };
  useEffect(() => {
    if (!approvalMode) {
      if (location.search) {
        const queryParsed = queryString.parse(location.search);
        setTaskId(queryParsed.id);
        getTaskDetails({ task_id: queryParsed.id });
      }
      getTaskType();
    }
  }, []);

  return (
    <div className="content">
      <StaticTaskLocationModal
        isOpen={seeLocationModalOpen}
        toggle={() => {
          setSeeLocationModalOpen(false);
          setSelectedTaskLocation(null);
        }}
        taskLocation={selectedTaskLocation}
      />
      {!approvalMode && (
        <>
          <PageTitle>{t('task_details')}</PageTitle>
          <ContentHeader>
            <BackButtonComponent
              onClick={() => history.push('/user/tasks')}
              subTitle={t('back_to_all_tasks')}
            />
          </ContentHeader>
        </>
      )}
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <Container padding="30px" gap="20px">
          <ListUpperSection style={{ gridTemplateColumns: `1fr auto` }}>
            <CommonText name title fontSize="16px">
              {taskDetails?.name}
            </CommonText>
            {!approvalMode &&
              localStorage.getItem('user_role') !== 'employee' &&
              !localStorage.getItem('viewer_role') &&
              (taskDetails?.status === 'to do' ? (
                <WhiteButton
                  onClick={() =>
                    history.push(`/user/edit-task?id=${taskDetails?.id}`)
                  }
                >
                  {t('edit_task')}
                </WhiteButton>
              ) : (
                <div />
              ))}
          </ListUpperSection>
          <TaskDetailsInfoSection>
            <TaskDetailsSingleInfo
              icon={statusIcon}
              title="status"
              value={
                <CommonFlex>
                  <CheckListStatus
                    status={taskDetails?.status ? taskDetails?.status : 'to do'}
                  >
                    {i18next.language && i18next.language === 'en'
                      ? t(taskDetails?.status).toUpperCase()
                      : t(taskDetails?.status)}
                  </CheckListStatus>
                  <div style={{ width: 30 }}>
                    <CircularProgressbarNewWithChildren
                      percentage={percentage}
                    />
                  </div>
                </CommonFlex>
              }
            />
            <TaskDetailsSingleInfo
              icon={officeIcon}
              title="public_zone"
              value={<CommonText>{taskDetails?.public_zone?.name}</CommonText>}
            />
            <TaskDetailsSingleInfo
              icon={officeIcon}
              title="zone"
              value={<CommonText>{taskDetails?.office?.name}</CommonText>}
            />
            <TaskDetailsSingleInfo
              icon={assigneeIcon}
              title="assignee"
              value={
                taskDetails?.assignees?.length > 0 ? (
                  <CommonFlex>
                    <FirstWordRound
                      size="30px"
                      fontSize="11px"
                      backColor="#789CFF"
                    >{`${taskDetails?.assignees[0].first_name
                      .toUpperCase()
                      .slice(0, 2)}`}</FirstWordRound>
                    <CommonText>
                      {taskDetails?.assignees[0].first_name +
                        ' ' +
                        taskDetails?.assignees[0].last_name}
                    </CommonText>
                  </CommonFlex>
                ) : (
                  <></>
                )
              }
            />
            <TaskDetailsSingleInfo
              icon={calendarIcon}
              title="timeline"
              value={
                <CommonText>
                  {moment(taskDetails?.required_start_date).format(
                    'D MMM, h:mm A',
                  ) +
                    ' - ' +
                    moment(taskDetails?.required_end_date).format(
                      'D MMM, h:mm A',
                    )}
                </CommonText>
              }
            />
            <TaskDetailsSingleInfo
              icon={documentIcon}
              title="description"
              value={<CommonText>{taskDetails?.description}</CommonText>}
            />
          </TaskDetailsInfoSection>

          <InfoSectionCard style={{ maxWidth: `530px` }}>
            <CardBlock>
              <CardText name fontSize="13px" title nowrap>
                {taskDetails?.required_start_date &&
                taskDetails?.required_end_date
                  ? toHmins(
                      moment
                        .duration(
                          moment(taskDetails?.required_end_date).diff(
                            moment(taskDetails?.required_start_date),
                          ),
                        )
                        .asSeconds(),
                    )
                  : '-'}
              </CardText>
              <CardText fontSize="12px" label title>
                {t('task_duration')}
              </CardText>
            </CardBlock>{' '}
            <CardBlock>
              <CardText name fontSize="13px" title nowrap>
                {lastCheckinout?.checkin_timestamp ? (
                  <>
                    {moment
                      .unix(lastCheckinout.checkin_timestamp)
                      .format('h:mm a')}
                    {moment
                      .unix(lastCheckinout.checkin_timestamp)
                      .isAfter(moment(taskDetails?.required_start_date)) ? (
                      <LateTag>{t('late')}</LateTag>
                    ) : (
                      <GreenCircle>
                        <img src={tick} alt="" width="100%" />
                      </GreenCircle>
                    )}
                  </>
                ) : (
                  `-`
                )}
              </CardText>
              <CardText fontSize="12px" label title>
                {t('checked_in')}
              </CardText>
              {lastCheckinout?.checkin_timestamp && (
                <CommonText>
                  {moment
                    .unix(lastCheckinout.checkin_timestamp)
                    .format('DD/MM/YYYY')}
                </CommonText>
              )}
            </CardBlock>
            <CardBlock>
              <CardText name fontSize="13px" title nowrap>
                {lastCheckinout?.checkout_timestamp ? (
                  <>
                    {moment
                      .unix(lastCheckinout.checkout_timestamp)
                      .format('h:mm a')}
                    {moment
                      .unix(lastCheckinout.checkout_timestamp)
                      .isAfter(moment(taskDetails?.required_end_date)) ? (
                      <LateTag>{t('late')}</LateTag>
                    ) : (
                      <GreenCircle>
                        <img src={tick} alt="" width="100%" />
                      </GreenCircle>
                    )}
                  </>
                ) : (
                  `-`
                )}
              </CardText>
              <CardText fontSize="12px" label title>
                {t('checked_out')}
              </CardText>
              {lastCheckinout?.checkout_timestamp && (
                <CommonText>
                  {moment
                    .unix(lastCheckinout.checkout_timestamp)
                    .format('DD/MM/YYYY')}
                </CommonText>
              )}
            </CardBlock>
            <CardBlock>
              <CardText name fontSize="13px" title nowrap>
                {lastCheckinout?.checkout_timestamp &&
                lastCheckinout?.checkin_timestamp
                  ? toHmins(
                      lastCheckinout?.checkout_timestamp -
                        lastCheckinout?.checkin_timestamp,
                    )
                  : `-`}
              </CardText>
              <CardText fontSize="12px" label title>
                {t('worked')}
              </CardText>
            </CardBlock>
          </InfoSectionCard>
          <div>
            <CommonText Text name title fontSize="16px">
              {t('location')}
            </CommonText>
            <LabelTextBlock style={{ marginTop: `10px` }}>
              <InputLabel>{t('location_address')}:</InputLabel>
              <CommonText name>{taskDetails?.location?.address}</CommonText>
            </LabelTextBlock>
            {taskDetails?.location && (
              <ColoredText
                type="warning"
                hover
                onClick={() => handleShowLocation(taskDetails?.location)}
                fontSize="14px"
                style={{ cursor: 'pointer' }}
              >
                {t('see_location')}
              </ColoredText>
            )}
          </div>
          <CheckListsTaskDetails
            taskId={taskDetails?.id}
            checklistData={taskDetails?.sub_tasks || []}
            updateSubTaskRequest={updateSubTaskRequest}
            // deleteCheckList={handleDeleteCheckList}
            getSubTaskEvidence={getSubTaskEvidence}
            {...{ expandedSubTaskId, setExpandedSubTaskId, canUpdateSubTask }}
          />
          <FullWidthWithTopBorder>
            <TaskEvidence evidences={taskDetails?.evidences} />
          </FullWidthWithTopBorder>
          <FullWidthWithTopBorder>
            <CommonText name title fontSize="16px">
              {t('comments')}
            </CommonText>
            <Comment
              type="employee_note"
              comment={taskDetails?.employee_note}
            />
            <Comment
              type="supervisor_note"
              comment={taskDetails?.supervisor_note}
            />
          </FullWidthWithTopBorder>
          {(taskDetails?.status === 'completed' ||
            taskDetails?.status === 'not completed') && ////////////////
            taskDetails?.approval_status === 'pending' &&
            localStorage.getItem('user_role') !== 'employee' &&
            selectedTab === 'submitted' &&
            taskDetails?.assignees &&
            taskDetails?.assignees.length > 0 &&
            taskDetails?.assignees[0].id !==
              localStorage.getItem('user_id') && (
              <FullWidthWithTopBorder max={'auto'}>
                <CommonGrid columns={'3fr 1fr'}>
                  <ApprovalSection
                    task_id={taskDetails?.id}
                    history={history}
                    updateTaskStatusRequest={updateTaskStatusRequest}
                    percentage={percentage}
                  />
                </CommonGrid>
              </FullWidthWithTopBorder>
            )}
        </Container>
      )}
    </div>
  );
};

export default TaskDetails;

const TaskDetailsSingleInfo = ({ icon, title, value }) => {
  return (
    <CommonGrid columns="200px 1fr">
      <CommonGrid columns="15px 1fr" alignItem="center">
        <img src={icon} alt="" style={{ height: '16px', width: '16px' }} />
        <CommonText> {t(title)}</CommonText>
      </CommonGrid>
      <div>{value}</div>
    </CommonGrid>
  );
};
