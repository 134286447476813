import {
  ADD_DEFAULT_SUB_TASKS,
  ADD_SUB_TASK,
  ADD_TASK_LOCATION,
  CLEAR_NEW_DEFAULT_SUB_TASK,
  CREATE_TASK,
  DELETE_SUB_TASK,
  DELETE_TASK,
  DUPLICATE_TASK,
  EDIT_SUB_TASK,
  EDIT_TASK,
  GET_DEFAULT_SUB_TASKS,
  GET_SUB_TASKS,
  GET_SUB_TASK_EVIDENCE,
  GET_TASKS_LIST,
  GET_TASK_DETAILS,
  GET_TASK_LOCATIONS,
  GET_TASK_TYPE,
  UPDATE_TASK_STATUS,
} from '../../modules/constants';

export function getTasksList(value) {
  return {
    type: GET_TASKS_LIST,
    payload: value,
  };
}

export function createTask(value) {
  return {
    type: CREATE_TASK,
    payload: value,
  };
}

export function getTaskDetails(value) {
  return {
    type: GET_TASK_DETAILS,
    payload: value,
  };
}

export function editTask(value) {
  return {
    type: EDIT_TASK,
    payload: value,
  };
}

export function getDefaultSubTasks(value) {
  return {
    type: GET_DEFAULT_SUB_TASKS,
    payload: value,
  };
}

export function getSubTasks(value) {
  return {
    type: GET_SUB_TASKS,
    payload: value,
  };
}

export function addDefaultSubTask(value) {
  return {
    type: ADD_DEFAULT_SUB_TASKS,
    payload: value,
  };
}

export function clearNewDefaultSubTask(value) {
  return {
    type: CLEAR_NEW_DEFAULT_SUB_TASK,
    payload: value,
  };
}

export function getSubTaskEvidence(value) {
  return {
    type: GET_SUB_TASK_EVIDENCE,
    payload: value,
  };
}

export function addSubTask(value) {
  return {
    type: ADD_SUB_TASK,
    payload: value,
  };
}

export function editSubTask(value) {
  return {
    type: EDIT_SUB_TASK,
    payload: value,
  };
}

export function deleteSubTask(value) {
  return {
    type: DELETE_SUB_TASK,
    payload: value,
  };
}

export function duplicateTask(value) {
  return {
    type: DUPLICATE_TASK,
    payload: value,
  };
}

export function deleteTask(value) {
  return {
    type: DELETE_TASK,
    payload: value,
  };
}

export function getTaskLocation(value) {
  return {
    type: GET_TASK_LOCATIONS,
    payload: value,
  };
}

export function updateTaskApproval(value) {
  return {
    type: UPDATE_TASK_STATUS,
    payload: value,
  };
}
export function addTaskLocation(value) {
  return {
    type: ADD_TASK_LOCATION,
    payload: value,
  };
}

export function getTaskType(value) {
  return {
    type: GET_TASK_TYPE,
    payload: value,
  };
}
