import AddMultipleAssigneeDropdown from 'components/CustomDropdown/AddMultipleAssigneeDropdown';
import TimeRangeComponentNew from 'custom_modules/Timesheet/Daily/TimeRangeComponentNew';
import TimeDuration from 'custom_modules/Timesheet/Daily/timeDuration';
import { t } from 'i18next';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  FormAlert,
  InputField,
  InputLabel,
  InputTextArea,
  InputWithLabelSection,
  RedStar,
} from 'styledComponents/common';

import Select from '../../components/ReactSelectDropdown';
import AddLocationModal from './CreateTask/AddLocationModal';
import { TimeRangeSection } from './CreateTask/createTaskStyles';
import DefaultTaskLocationWarningModal from './DefaultTaskLocationWarningModal';

const CreateEditTaskForm = ({
  selectedOffice,
  officeOptions,
  officesShortList,
  filterOption,
  taskName,
  saveErrors,
  memberShortListIsLoading,
  selectedAssignees,
  orgMembersList,
  handleAssignMember,
  handleRemoveMember,
  startTime,
  startDate,
  onStartDateChange,
  handleStartTime,
  endTime,
  endDate,
  onEndDateChange,
  handleEndTime,
  selectedLocation,
  taskLocationOptions,
  taskDescription,
  onOfficeChange,
  CreateProjectDropdown,
  onTaskNameChange,
  popupToggle,
  onTaskDescriptionChange,
  onLocationChange,
  publicZoneOptions,
  publicZoneList,
  selectedPublicZone,
  onPublicZoneChange,
  isSameLocationWarningModalOpen,
  popupToggleSameLocationWarningModal,
  handleSave,
  isLoading,
  creating,
  popupOpen,
  addTaskLocation,
  addTaskLocationLoading,
  createdTaskLocationInfo,
  getTaskLocation,
  getOfficesShortList,
}) => {
  return (
    <>
      <AddLocationModal
        isOpen={popupOpen}
        toggle={popupToggle}
        addTaskLocation={addTaskLocation}
        addTaskLocationLoading={addTaskLocationLoading}
        createdTaskLocationInfo={createdTaskLocationInfo}
        getTaskLocation={getTaskLocation}
        getOfficesShortList={getOfficesShortList}
        officesShortList={officesShortList}
        publicZoneList={publicZoneList}
        onLocationChange={onLocationChange}
      />
      <DefaultTaskLocationWarningModal
        isOpen={isSameLocationWarningModalOpen}
        toggle={popupToggleSameLocationWarningModal}
        handleCreate={handleSave}
        isLoading={isLoading}
        creating={creating}
      />
      <CommonGrid
        style={{ gridColumn: `1 / span 2` }}
        columns="1fr 1fr"
        justifyContent="none"
        gap="40px"
      >
        <InputWithLabelSection>
          <InputLabel>
            {t('public_zone')} <RedStar>*</RedStar>
          </InputLabel>
          <Select
            isSearchable
            value={selectedPublicZone}
            options={publicZoneOptions}
            placeholder={t('select_zone')}
            isDisabled={publicZoneList && publicZoneList.length === 1}
            onChange={e => {
              onPublicZoneChange(e);
            }}
            styles={CreateProjectDropdown()}
            filterOption={filterOption}
          />
        </InputWithLabelSection>
        <InputWithLabelSection>
          <InputLabel>
            {t('zone')} <RedStar>*</RedStar>
          </InputLabel>
          <Select
            isSearchable
            value={selectedOffice}
            options={officeOptions}
            placeholder={t('select_zone')}
            isDisabled={officesShortList && officesShortList.length === 1}
            onChange={e => {
              onOfficeChange(e);
            }}
            styles={CreateProjectDropdown()}
            filterOption={filterOption}
          />
        </InputWithLabelSection>
      </CommonGrid>
      <CommonGrid
        style={{ gridColumn: `1 / span 2` }}
        columns="1fr 100px"
        justifyContent="none"
        gap="40px"
      >
        <InputWithLabelSection>
          <InputLabel>
            {t('task_name')} <RedStar>*</RedStar>
          </InputLabel>
          <InputField
            type="text"
            placeholder={t('type_task_name')}
            value={taskName}
            onChange={e => onTaskNameChange(e)}
            style={
              saveErrors &&
              saveErrors[`taskName`] && {
                border: `1px solid #fe5969`,
                backgroundColor: `rgba(252, 87, 104, 0.05)`,
              }
            }
          />
          {saveErrors && saveErrors['taskName'] && (
            <FormAlert>{saveErrors['taskName']}</FormAlert>
          )}
        </InputWithLabelSection>
        <CommonFlex justifyContent="center">
          <InputWithLabelSection>
            <InputLabel>
              {t('assignee')}
              <RedStar>*</RedStar>
            </InputLabel>

            <AddMultipleAssigneeDropdown
              numberOfVisibleMembers={8}
              isLoading={memberShortListIsLoading}
              assigneesList={selectedAssignees}
              membersList={orgMembersList}
              handleAssign={handleAssignMember}
              handleRemove={handleRemoveMember}
            />

            {saveErrors && saveErrors['selectedAssignees'] && (
              <FormAlert>{saveErrors['selectedAssignees']}</FormAlert>
            )}
          </InputWithLabelSection>
        </CommonFlex>
      </CommonGrid>
      {/* <DatePicker
        id="issuedDate"
        date={startDate}
        onDateChange={setStartDate}
        dateDisplayFormat={'DD/MM/YYYY'}
        placeholder={'Select date'}
      /> */}
      {/* <InputWithLabelSection>
        <InputLabel>Requires Approval</InputLabel>
        <CommonFlex>
          <ToggleButton
            onClick={() => approvalToggle()}
            active={requiresApproval}
          >
            <ToggleButtonRound active={requiresApproval} />
          </ToggleButton>
          <InputLabel>{requiresApproval ? `Yes` : `No`}</InputLabel>
        </CommonFlex>
      </InputWithLabelSection> */}
      <InputWithLabelSection>
        <TimeRangeSection>
          <InputLabel>
            {t('start_date')}
            <RedStar>*</RedStar>
          </InputLabel>
          <InputLabel>
            {t('start_time')}
            <RedStar>*</RedStar>
          </InputLabel>
        </TimeRangeSection>
        <TimeRangeComponentNew
          initialTime={startTime}
          selectedDate={startDate}
          onDateChange={onStartDateChange}
          setTime={handleStartTime}
        />
        {saveErrors &&
          saveErrors['timeDifferenceStartTime'] &&
          saveErrors['timeDifferenceStartTime'] !== undefined && (
            <FormAlert>{saveErrors['timeDifferenceStartTime']}</FormAlert>
          )}
      </InputWithLabelSection>
      <CommonFlex direction="column" alignItems="start" gap="5px">
        <InputWithLabelSection>
          <TimeRangeSection>
            <InputLabel>
              {t('end_date')}
              <RedStar>*</RedStar>
            </InputLabel>
            <InputLabel>
              {t('end_time')}
              <RedStar>*</RedStar>
            </InputLabel>
          </TimeRangeSection>
          <TimeRangeComponentNew
            initialTime={endTime}
            selectedDate={endDate}
            onDateChange={onEndDateChange}
            setTime={handleEndTime}
            mode="end"
          />
          {saveErrors &&
            saveErrors['timeDifferenceEndTime'] &&
            saveErrors['timeDifferenceEndTime'] !== undefined && (
              <FormAlert>{saveErrors['timeDifferenceEndTime']}</FormAlert>
            )}
        </InputWithLabelSection>
      </CommonFlex>{' '}
      <CommonGrid
        style={{ gridColumn: `1 / span 2` }}
        columns="1fr"
        justifyContent="none"
        gap="40px"
      >
        <TimeDuration
          startDate={startDate}
          endDate={endDate}
          startTime={startTime}
          endTime={endTime}
        />
      </CommonGrid>
      <InputWithLabelSection>
        <CommonGrid>
          <InputLabel>
            {t('location')} <RedStar>*</RedStar>
          </InputLabel>
          <ColoredText
            fontSize="12px"
            type="success"
            hover
            semibold
            style={{ cursor: `pointer` }}
            onClick={() => popupToggle()}
          >
            {t('add_new_location')}
          </ColoredText>
        </CommonGrid>
        <Select
          isSearchable
          value={selectedLocation}
          options={taskLocationOptions}
          placeholder={t('select_location')}
          onChange={e => {
            onLocationChange(e);
          }}
          styles={CreateProjectDropdown()}
          filterOption={filterOption}
        />
        {saveErrors &&
          saveErrors['selectedLocation'] &&
          saveErrors['selectedLocation'] !== undefined && (
            <FormAlert>{saveErrors['selectedLocation']}</FormAlert>
          )}
      </InputWithLabelSection>
      <InputWithLabelSection style={{ gridColumn: `1 / span 2` }}>
        <InputLabel>
          {t('description')}
          <RedStar>*</RedStar>
        </InputLabel>
        <InputTextArea
          text="Description"
          cols="35"
          rows="7"
          value={taskDescription}
          placeholder={t('type_description_here')}
          onChange={e => onTaskDescriptionChange(e)}
          height="auto"
          style={
            saveErrors &&
            saveErrors[`taskDescription`] && {
              border: `1px solid #fe5969`,
              backgroundColor: `rgba(252, 87, 104, 0.05)`,
            }
          }
        ></InputTextArea>
        {saveErrors &&
          saveErrors['taskDescription'] &&
          saveErrors['taskDescription'] !== undefined && (
            <FormAlert>{saveErrors['taskDescription']}</FormAlert>
          )}
      </InputWithLabelSection>
    </>
  );
};

export default CreateEditTaskForm;
