import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { CommonFlex, FormAlert } from 'styledComponents/common';
import { CheckListNameInputField } from 'styledComponents/tasks';
import { v4 as uuidv4 } from 'uuid';

import Cross from '../../../assets/img/icons/cross_red_bg.svg';
import TickGreen from '../../../assets/img/icons/tick-green.svg';

const SubTaskNameInput = ({
  data,
  handleUpdateSubTaskName,
  cancelFunction,
}) => {
  const [saveErrors, setSaveErrors] = useState();

  const [name, setName] = useState(data.name);
  const handleSave = () => {
    if (name.length == 0) {
      setSaveErrors('This Field is Required');
    } else {
      handleUpdateSubTaskName({ id: data.id, name: name });
      cancelFunction();
    }
  };
  const handleChange = value => {
    setName(value);
    setSaveErrors(null);
  };
  useEffect(() => {}, [saveErrors]);

  return (
    <div>
      <CommonFlex justifyContent="space-between">
        <CheckListNameInputField
          type="text"
          placeholder={t('checklist_name')}
          value={name}
          onChange={e => handleChange(e.target.value)}
          border="none"
          style={
            saveErrors && {
              border: `1px solid #fe5969`,
              backgroundColor: `rgba(252, 87, 104, 0.05)`,
            }
          }
          borderRadius="10px"
        />
        <CommonFlex gap="15px">
          <div onClick={() => cancelFunction()}>
            <img src={Cross} alt="tick" width="26px" height="26px" />
          </div>
          <div onClick={() => handleSave()}>
            <img src={TickGreen} alt="tick" width="26px" height="26px" />
          </div>
        </CommonFlex>
      </CommonFlex>
      {saveErrors && <FormAlert>{saveErrors}</FormAlert>}
    </div>
  );
};

export default SubTaskNameInput;
