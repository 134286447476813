import { connect } from 'react-redux';

import {
  getOfficesShortList,
  getPublicZoneList,
} from '../../Offices/officesActions';
import { getOrganizationMembersList } from '../../Projects/projectsActions';
import {
  addDefaultSubTask,
  addTaskLocation,
  clearNewDefaultSubTask,
  createTask,
  getDefaultSubTasks,
  getTaskDetails,
  getTaskLocation,
  getTaskType,
} from '../tasksActions';
// Component
import DuplicateTask from './DuplicateTask';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersList: state.project.orgMembersList,
  isLoading: state.task.isLoading,
  officesShortList: state.office.officesShortList,
  publicZoneList: state.office.publicZoneList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  addTaskLocationLoading: state.task.addTaskLocationLoading,
  locationsList: state.task.locationsList,
  createdTaskLocationInfo: state.task.createdTaskLocationInfo,
  createdTaskInfo: state.task.createdTaskInfo,
  callback: state.task.callback,
  taskDetails: state.task.taskDetails,
  taskTypeIsLoading: state.task.taskTypeIsLoading,
  taskTypeList: state.task.taskTypeList,
  defaultSubTasks: state.task.defaultSubTasks,
  isAddDefaultSubTaskLoading: state.task.isAddDefaultSubTaskLoading,
  newDefaultSubTask: state.task.newDefaultSubTask,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersList: params =>
    dispatch(getOrganizationMembersList(params)),
  getTaskDetails: params => dispatch(getTaskDetails(params)),
  createTask: params => dispatch(createTask(params)),
  addTaskLocation: params => dispatch(addTaskLocation(params)),
  getOfficesShortList: params => dispatch(getOfficesShortList(params)),
  getPublicZoneList: params => dispatch(getPublicZoneList(params)),
  getTaskType: params => dispatch(getTaskType(params)),
  getTaskLocation: params => dispatch(getTaskLocation(params)),
  getDefaultSubTasks: params => dispatch(getDefaultSubTasks(params)),
  clearNewDefaultSubTask: params => dispatch(clearNewDefaultSubTask(params)),
  addDefaultSubTask: params => dispatch(addDefaultSubTask(params)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(DuplicateTask);
