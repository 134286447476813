import { t } from 'i18next';
import { Modal, ModalBody } from 'reactstrap';

import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import { ColoredButton, WhiteButton } from '../../styledComponents/buttons';
import { CommonText } from '../../styledComponents/common';
import { ModalButtonSection } from '../../styledComponents/members';

const DefaultTaskLocationWarningModal = ({
  isOpen,
  toggle,
  handleCreate,
  isLoading,
  creating = false,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText style={{ paddingLeft: `15px` }}>
          {t('are_you_sure_the_task_location_is_the_default_zone_location')}
        </CommonText>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            {t('cancel')}
          </WhiteButton>
          <ColoredButton
            type="blue"
            padding={isLoading ? '0 15px' : '0 30px'}
            onClick={() => handleCreate()}
          >
            {isLoading ? (
              <ButtonTextLoader
                loadingText={creating ? t('create_task') : t('edit_task')}
                fontSize="13px"
              />
            ) : creating ? (
              t('create_task')
            ) : (
              t('edit_task')
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default DefaultTaskLocationWarningModal;
