import { t } from 'i18next';
import moment from 'moment-timezone';
import { Fragment, useEffect, useState } from 'react';

import arrowIcon from '../../../assets/img/icons/arrow-right-black.svg';
import deleteIcon from '../../../assets/img/icons/delete.svg';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import {
  ModalDeleteButton,
  WhiteButton,
} from '../../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  FormAlert,
  InputLabel,
  InputTextArea,
  InputWithLabelSection,
  RedStar,
} from '../../../styledComponents/common';
import { ActivityTextSection } from '../../../styledComponents/members';
import { checkHtml, checkUrl, getHours, toHHMMSS } from '../../../utils/helper';
import { getHoursAndMinutes } from '../Daily/ManualTimeModal';
import DeleteTimeRangeComponent from '../DeleteTimeRangeComponent';
import {
  DeleteButton,
  DetailsCollapseSection,
  DetailsInfoContainer,
  ExpandButton,
  IdleTimeSectionContainer,
  TimesheetDetailsPanelContainer,
  TimesheetDetailsTableRow,
} from './timesheetDetailsPanelStyles';

const TimesheetDetails = props => {
  const [expandedTimesheetId, setExpandedTimesheetId] = useState(null);

  const [reason, setReason] = useState('');
  const [saveErrors, setSaveErrors] = useState({});

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  // time picker data
  const [timePickerStartTime, setTimePickerStartTime] = useState(null);
  const [timePickerEndTime, setTimePickerEndTime] = useState(null);

  const {
    getDailyTimesheets,
    clearDailyTimesheetsList,
    timesheetsList,
    userData,
    deleteTimesheetLoading,
    deleteTimesheet,
    selectedOrganization,
    selectedProject,
    selectedTask,
    timezone,
    fromPage,
  } = props;

  useEffect(() => {
    return () => {
      clearDailyTimesheetsList();
    };
  }, []);

  useEffect(() => {
    setReason('');
    setSaveErrors({});
  }, [expandedTimesheetId]);

  const handleExpandCollapseSection = (
    timesheetId,
    startTimestamp,
    endTimestamp,
  ) => {
    if (expandedTimesheetId && expandedTimesheetId === timesheetId) {
      setExpandedTimesheetId(null);
    } else {
      setExpandedTimesheetId(timesheetId);
      setStartTime(startTimestamp);
      setEndTime(endTimestamp);
      setTimePickerStartTime(startTimestamp);
      setTimePickerEndTime(endTimestamp);
    }
  };

  const handleStartTime = (date, time) => {
    if (date && time) {
      const formatted_time = time.replace(/ /g, '');
      const start_time = `${date}T${formatted_time}`;
      if (timezone) {
        setStartTime(moment.tz(start_time, 'YYYY-MM-DDTh:mmA', timezone));
      } else {
        setStartTime(moment(start_time, 'YYYY-MM-DDTh:mmA'));
      }
    } else {
      setStartTime('');
    }
    if (saveErrors && saveErrors['time']) {
      const errors = { ...saveErrors };
      delete errors['time'];
      setSaveErrors(errors);
    }
  };
  const handleEndTime = (date, time) => {
    if (date && time) {
      const formatted_time = time.replace(/ /g, '');
      const end_time = `${date}T${formatted_time}`;
      setEndTime(moment(end_time, 'YYYY-MM-DDTh:mmA'));
      if (timezone) {
        setEndTime(moment.tz(end_time, 'YYYY-MM-DDTh:mmA', timezone));
      } else {
        setEndTime(moment(end_time, 'YYYY-MM-DDTh:mmA'));
      }
    } else {
      setEndTime('');
    }
    if (saveErrors && saveErrors['time']) {
      const errors = { ...saveErrors };
      delete errors['time'];
      setSaveErrors(errors);
    }
  };

  const checkError = (timesheetId, start, end) => {
    let formIsValid = true;
    const saveErrors = {};
    if (checkUrl(reason)) {
      formIsValid = false;
      saveErrors['reason'] = 'Reason can not contain url.';
    } else if (checkHtml(reason)) {
      formIsValid = false;
      saveErrors['reason'] = 'Invalid input in reason.';
    } else if (!startTime || !endTime) {
      formIsValid = false;
      saveErrors['time'] = 'Please select start and end time.';
    } else if (
      startTime &&
      timesheetId &&
      startTime.isBefore(start, 'minutes')
    ) {
      formIsValid = false;
      saveErrors['time'] = 'Invalid time input.';
    } else if (endTime && timesheetId && endTime.isAfter(end, 'minutes')) {
      formIsValid = false;
      saveErrors['time'] = 'Invalid time input.';
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleDeleteIdleTime = (timesheetId, time) => {
    if (timesheetId && time && time.id && userData && userData.id) {
      if (selectedOrganization && selectedOrganization.id) {
        const payload = {
          organization_id: selectedOrganization.id,
          timesheet_id: timesheetId,
          user_id: userData.id,
          start: moment(time.start_timestamp).utc().unix(),
          end: moment(time.end_timestamp).utc().unix(),
          comment: 'idle time',
          fromPage,
          date: moment(time.start_timestamp).format('YYYY-MM-DD'),
        };
        deleteTimesheet(payload);
        setTimeout(() => {
          setExpandedTimesheetId(null);
          const dailyPayload = {
            organization_id: selectedOrganization.id,
            user_id: userData.id,
            date: moment(userData.date).format('YYYY-MM-DD'),
          };
          if (selectedProject && selectedProject.value) {
            dailyPayload.project_id = selectedProject.value;
          }
          if (selectedTask && selectedTask.value) {
            dailyPayload.task_id = selectedTask.value;
          }
          if (timezone) {
            dailyPayload.timezone = timezone;
          }
          getDailyTimesheets(dailyPayload);
        }, 800);
      }
    }
  };

  const handleDeleteTimesheet = (timesheetId, start, end) => {
    if (
      timesheetId &&
      startTime &&
      endTime &&
      checkError(timesheetId, start, end) &&
      userData &&
      userData.id
    ) {
      const startTimestamp = startTime.isSame(start, 'minutes')
        ? start
        : startTime;
      const endTimestamp = endTime.isSame(end, 'minutes') ? end : endTime;

      if (selectedOrganization && selectedOrganization.id) {
        const payload = {
          organization_id: selectedOrganization.id,
          timesheet_id: timesheetId,
          user_id: userData.id,
          start: moment(startTimestamp).utc().unix(),
          end: moment(endTimestamp).utc().unix(),
          comment: reason,
          fromPage,
          date: moment(startTimestamp).format('YYYY-MM-DD'),
        };
        deleteTimesheet(payload);
        setTimeout(() => {
          setExpandedTimesheetId(null);
          const dailyPayload = {
            organization_id: selectedOrganization.id,
            user_id: userData.id,
            date: moment(userData.date).format('YYYY-MM-DD'),
          };
          if (selectedProject && selectedProject.value) {
            dailyPayload.project_id = selectedProject.value;
          }
          if (selectedTask && selectedTask.value) {
            dailyPayload.task_id = selectedTask.value;
          }
          if (timezone) {
            dailyPayload.timezone = timezone;
          }
          getDailyTimesheets(dailyPayload);
        }, 800);
      }
    }
  };
  return (
    <TimesheetDetailsPanelContainer>
      {userData && userData.id && (
        <CommonFlex justifyContent="space-between" padding="20px 25px">
          <CommonFlex gap="15px">
            <CommonImage
              src={userData.avatar}
              size="36px"
              alt=""
              style={{ borderRadius: '50%', border: '1px solid #c2cce1' }}
            />
            <CommonText fontSize="13px">{userData.name}</CommonText>
          </CommonFlex>
          <ActivityTextSection style={{ justifyItems: 'flex-end' }}>
            <CommonText fontWeight="600" fontSize="13px">
              Total Time: {userData.totalTime}
            </CommonText>
            <CommonText fontSize="13px">
              {moment(userData.date).format('ddd, MMM DD, YYYY')}
            </CommonText>
          </ActivityTextSection>
        </CommonFlex>
      )}

      <TimesheetDetailsTableRow header>
        <CommonText fontSize="12px" $label fontWeight="500">
          Start Time
        </CommonText>
        <CommonText fontSize="12px" $label fontWeight="500">
          End Time
        </CommonText>
        <CommonText fontSize="12px" $label fontWeight="500">
          Total Time
        </CommonText>
      </TimesheetDetailsTableRow>
      {timesheetsList.map((timesheet, index) => (
        <Fragment key={timesheet.id}>
          <TimesheetDetailsTableRow
            active={expandedTimesheetId && expandedTimesheetId === timesheet.id}
          >
            <CommonText fontSize="13px">
              {timesheet.start_timestamp.format('h:mm a')}
            </CommonText>
            <CommonText fontSize="13px">
              {timesheet.end_timestamp.format('h:mm a')}
            </CommonText>
            <CommonText fontSize="13px">
              {getHours(toHHMMSS(timesheet.duration))}
            </CommonText>
            <ExpandButton
              onClick={() =>
                handleExpandCollapseSection(
                  timesheet.id,
                  timesheet.start_timestamp,
                  timesheet.end_timestamp,
                )
              }
              active={
                expandedTimesheetId && expandedTimesheetId === timesheet.id
              }
            >
              <CommonImage src={arrowIcon} alt="" />
            </ExpandButton>
          </TimesheetDetailsTableRow>
          <DetailsCollapseSection
            open={expandedTimesheetId && expandedTimesheetId === timesheet.id}
            maxHeight={
              timesheet.idle_times && timesheet.idle_times.length > 0
                ? `${286 + 20 + 30 * timesheet.idle_times.length}px`
                : '286px'
            }
          >
            <DetailsInfoContainer>
              {timesheet.idle_times && timesheet.idle_times.length > 0 ? (
                <IdleTimeSectionContainer>
                  <CommonGrid columns="80px auto">
                    <ColoredText type="error" fontSize="13px">
                      Idle Time
                    </ColoredText>
                    <CommonGrid columns="155px 90px 40px">
                      {timesheet.idle_times.map(time => (
                        <Fragment key={time.id}>
                          <CommonText fontSize="13px">
                            {time.start_timestamp.format('h:mm a')} -{' '}
                            {time.end_timestamp.format('h:mm a')}
                          </CommonText>
                          <ColoredText type="blue" fontSize="13px">
                            {getHours(toHHMMSS(time.duration))}
                          </ColoredText>
                          <DeleteButton
                            onClick={() =>
                              handleDeleteIdleTime(timesheet.id, time)
                            }
                          >
                            <CommonImage src={deleteIcon} alt="" size="10px" />
                          </DeleteButton>
                        </Fragment>
                      ))}
                    </CommonGrid>
                  </CommonGrid>
                </IdleTimeSectionContainer>
              ) : null}
              <CommonGrid
                columns="60px auto"
                justifyContent="flex-start"
                margin={'15px 0 0'}
              >
                <CommonText fontSize="12px" $label fontWeight="500" margin="0">
                  Project
                </CommonText>
                <CommonText fontSize="13px" margin="0">
                  {timesheet.project.name}
                </CommonText>
              </CommonGrid>
              {timesheet.task && (
                <CommonGrid
                  columns="60px auto"
                  justifyContent="flex-start"
                  margin="10px 0 0"
                >
                  <CommonText
                    fontSize="12px"
                    $label
                    fontWeight="500"
                    margin="0"
                  >
                    Task
                  </CommonText>
                  <CommonText fontSize="13px" margin="0">
                    {timesheet.task.name}
                  </CommonText>
                </CommonGrid>
              )}
              <CommonGrid
                columns="auto 50% auto"
                margin="20px 0 15px"
                alignItem="center"
              >
                <CommonText fontSize="12px">Delete Time</CommonText>
                <CommonGrid columns="100%" style={{ gridRowGap: '3px' }}>
                  <DeleteTimeRangeComponent
                    startTime={timePickerStartTime}
                    setStartTime={handleStartTime}
                    endTime={timePickerEndTime}
                    setEndTime={handleEndTime}
                  />
                  {saveErrors &&
                    saveErrors['time'] &&
                    saveErrors['time'] !== undefined && (
                      <FormAlert margin="0">{saveErrors['time']}</FormAlert>
                    )}
                </CommonGrid>

                {moment(endTime).isAfter(moment(startTime)) ? (
                  <ColoredText type="blue" fontSize="12px">
                    {getHoursAndMinutes(
                      moment(endTime).diff(moment(startTime), 'minutes'),
                    )}
                  </ColoredText>
                ) : (
                  <ColoredText type="error" fontSize="11.5px">
                    {t('end_time_must_be_after_start_time')}
                  </ColoredText>
                )}
              </CommonGrid>
              <InputWithLabelSection>
                <InputLabel>
                  Reason <RedStar>*</RedStar>
                </InputLabel>
                <InputTextArea
                  id="reason-text-area"
                  placeholder="Why do you want to delete the timesheet?"
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                  height="60px"
                  error={
                    saveErrors &&
                    saveErrors['reason'] &&
                    saveErrors['reason'] !== undefined
                  }
                />
                {saveErrors &&
                  saveErrors['reason'] &&
                  saveErrors['reason'] !== undefined && (
                    <FormAlert margin="0 0 0 5px">
                      {saveErrors['reason']}
                    </FormAlert>
                  )}
              </InputWithLabelSection>
              <CommonGrid
                justifyContent="flex-end"
                gap="12px"
                margin="12px 0 0"
              >
                <WhiteButton
                  type="cancel"
                  height="36px"
                  onClick={() => {
                    setExpandedTimesheetId(null);
                  }}
                >
                  Cancel
                </WhiteButton>
                <ModalDeleteButton
                  height="36px"
                  padding={deleteTimesheetLoading && '0 10px'}
                  disabled={!reason.trim()}
                  onClick={() =>
                    handleDeleteTimesheet(
                      timesheet.id,
                      timesheet.start_timestamp,
                      timesheet.end_timestamp,
                    )
                  }
                >
                  {deleteTimesheetLoading ? (
                    <ButtonTextLoader
                      loadingText="Deleting"
                      fontSize="12px"
                      fontWeight="400"
                    />
                  ) : (
                    'Delete'
                  )}
                </ModalDeleteButton>
              </CommonGrid>
            </DetailsInfoContainer>
          </DetailsCollapseSection>
        </Fragment>
      ))}
    </TimesheetDetailsPanelContainer>
  );
};

export default TimesheetDetails;
