import React from 'react';
import { useTranslation } from 'react-i18next';

import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent';
import TaskDetails from '../TaskDetails/TaskDetails';

const ApprovalTasksList = props => {
  const { t } = useTranslation();

  const {
    handleClearSearch,
    handleSearchByName,
    onSearchTermChange,
    searchTerm,
    isLoading,
    tasksList,
    popupOpen,
    setPopupOpen,
    setEvidenceImage,
    updateTaskStatusRequest,
    selectedTab,
    editSubTask,
    updateTaskApproval,
    getSubTaskEvidence,
  } = props;

  return (
    <>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : tasksList && tasksList.length > 0 ? (
        tasksList.map(task => (
          <TaskDetails
            taskDetails={task}
            approvalMode
            selectedTab={selectedTab}
            editSubTask={editSubTask}
            updateTaskApproval={updateTaskApproval}
            getSubTaskEvidence={getSubTaskEvidence}
          />
        ))
      ) : (
        <NoDataComponent
          title={t('no_tasks_found')}
          padding="5vh 0"
          imageHeight="140px"
        />
      )}
    </>
  );
};

export default ApprovalTasksList;
