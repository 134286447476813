import { t } from 'i18next';
import { createNotification } from 'modules/notificationManager';
import { useEffect, useRef, useState } from 'react';
import { WhiteButton } from 'styledComponents/buttons';
import {
  CommonFlex,
  CommonGrid,
  Container,
  FormAlert,
} from 'styledComponents/common';
import {
  CheckBoxCircle,
  CheckListNameInputField,
} from 'styledComponents/tasks';
import { v4 as uuidv4 } from 'uuid';

import greenIcon from '../../../assets/img/icons/greenIcon.svg';
import TickWhite from '../../../assets/img/icons/tick_2.svg';
import DefaultChecklistNotFoundModal from '../DefaultChecklistNotFoundModal';
import CheckListDropdown from '../checkListDropdown';
import { clearNewDefaultSubTask } from '../tasksReducer';

const CheckList = ({
  handleAddCheckList,
  defaultCheckListOptions,
  newDefaultSubTask,
  isAddDefaultSubTaskLoading,
  addDefaultSubTask,
  selectedPublicZone,
  clearNewDefaultSubTask,
  checklistData,
}) => {
  const [saveErrors, setSaveErrors] = useState();
  const [showChecklistDropdown, setShowChecklistDropdown] = useState(false);
  const [
    isDefaultChecklistNotFoundModalOpen,
    setIsDefaultChecklistNotFoundModalOpen,
  ] = useState(false);
  const [name, setName] = useState('');
  const handleSave = () => {
    const alreadyAdded = checklistData?.filter(
      checkList => checkList.name === name,
    );
    if (alreadyAdded?.length > 0) {
      createNotification(
        'error',
        'This subtask already inserted into the checklist',
        3000,
      );
      setName('');
      return;
    }
    if (name.length == 0) {
      setSaveErrors('This Field is Required');
    } else {
      const matchedDefaultCheckListOption = defaultCheckListOptions?.filter(
        defaultCheckListOption => defaultCheckListOption.name === name,
      );
      if (matchedDefaultCheckListOption.length > 0) {
        const payload = {
          id: matchedDefaultCheckListOption[0].id,
          name: matchedDefaultCheckListOption[0].name,
          default_sub_task_id:
            matchedDefaultCheckListOption[0].default_sub_task_id,
        };
        setName('');
        handleAddCheckList(payload);
      } else {
        setIsDefaultChecklistNotFoundModalOpen(true);
      }
    }
  };

  const handleYesClick = () => {
    const alreadyAdded = checklistData?.filter(
      checkList => checkList.name === name,
    );
    if (alreadyAdded?.length > 0) {
      createNotification(
        'error',
        'This subtask already inserted into the checklist',
        3000,
      );
      setName('');
      setIsDefaultChecklistNotFoundModalOpen(false);
      return;
    }
    if (name?.length > 0 && selectedPublicZone?.value) {
      addDefaultSubTask({
        name: name,
        public_zone_id: selectedPublicZone.value,
      });
    }
  };

  const handleNoClick = () => {
    const alreadyAdded = checklistData?.filter(
      checkList => checkList.name === name,
    );
    if (alreadyAdded?.length > 0) {
      createNotification(
        'error',
        'This subtask already inserted into the checklist',
        3000,
      );
      setName('');
      setIsDefaultChecklistNotFoundModalOpen(false);
      return;
    }
    const payload = {
      id: uuidv4(),
      name: name,
      default_sub_task_id: null,
    };
    handleAddCheckList(payload);
    setName('');
    setIsDefaultChecklistNotFoundModalOpen(false);
  };

  const handleAddFromDropdown = checklistFromDropdown => {
    const alreadyAdded = checklistData?.filter(
      checkList => checkList.name === checklistFromDropdown.name,
    );
    if (alreadyAdded?.length > 0) {
      createNotification(
        'error',
        'This subtask already inserted into the checklist',
        3000,
      );
      setName('');
      setIsDefaultChecklistNotFoundModalOpen(false);
      return;
    }
    if (!checklistFromDropdown) {
      setSaveErrors('This Field is Required');
    } else {
      setName('');
      handleAddCheckList(checklistFromDropdown);
    }
    toggleCheckListDropdown();
  };
  const toggleCheckListDropdown = () =>
    setShowChecklistDropdown(!showChecklistDropdown);
  const handleChange = value => {
    setName(value);
    setSaveErrors(null);
  };
  const mainComponentRef = useRef(null);

  useEffect(() => {
    if (isAddDefaultSubTaskLoading === false && newDefaultSubTask) {
      const payload = {
        name: newDefaultSubTask.name,
        id: newDefaultSubTask.id,
        default_sub_task_id: newDefaultSubTask.id,
      };
      setName('');
      clearNewDefaultSubTask();
      handleAddCheckList(payload);
      setIsDefaultChecklistNotFoundModalOpen(false);
    }
  }, [newDefaultSubTask, isAddDefaultSubTaskLoading]);
  useEffect(() => {}, [saveErrors]);
  useEffect(() => {
    name?.length > 0 && setShowChecklistDropdown(true);
  }, [name]);

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <DefaultChecklistNotFoundModal
        isOpen={isDefaultChecklistNotFoundModalOpen}
        toggle={() =>
          setIsDefaultChecklistNotFoundModalOpen(
            !isDefaultChecklistNotFoundModalOpen,
          )
        }
        // handleCreate={handleSave}
        isLoading={isAddDefaultSubTaskLoading}
        {...{ handleNoClick, handleYesClick }}
      />
      <Container padding="12px" ref={mainComponentRef}>
        <CommonGrid
          columns="20px 1fr"
          align-items="center"
          align-content="center"
        >
          <CheckBoxCircle
            notAllowed={true}
            checked={false}
            // onClick={() => onSingleDateAllPersonSelect(i)}
          >
            <img src={TickWhite} alt="tick" width="16px" height="16px" />
          </CheckBoxCircle>
          <CommonFlex justifyContent="space-between">
            <CheckListNameInputField
              type="text"
              placeholder={t('add_an_item')}
              value={name}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleSave();
                }
              }}
              onClick={() => setShowChecklistDropdown(true)}
              onChange={e => handleChange(e.target.value)}
              border="none"
              style={
                saveErrors && {
                  border: `1px solid #fe5969`,
                  backgroundColor: `rgba(252, 87, 104, 0.05)`,
                }
              }
            />
            {/* <CommonFlex gap="15px">
              <WhiteButton
                borderColor="#4F3423"
                hoverBorderColor="#4F3423"
                type="cancel"
                width="max-content"
                onClick={() => toggleCheckListDropdown()}
              >
                <CommonFlex>
                  <img
                    src={greenIcon}
                    alt=""
                    style={{ height: '15px', width: '15px' }}
                  />
                  <div> {t('add_an_default_item')}</div>
                </CommonFlex>
              </WhiteButton>
            </CommonFlex> */}
          </CommonFlex>
        </CommonGrid>{' '}
        {saveErrors && <FormAlert>{saveErrors}</FormAlert>}
      </Container>
      {showChecklistDropdown && name?.length > 0 && (
        <CheckListDropdown
          searchText={name}
          mainComponentRef={mainComponentRef}
          setShowChecklistDropdown={setShowChecklistDropdown}
          handleAddFromDropdown={handleAddFromDropdown}
          defaultCheckListOptions={defaultCheckListOptions}
        />
      )}
    </div>
  );
};

export default CheckList;
