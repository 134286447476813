import { connect } from 'react-redux';

// import { getOrganizationMembersList } from '../../../Projects/projectsActions';
import {
  clearOfficeDetails,
  createOffice,
  getPublicZoneList,
  updateOffice,
} from '../officesActions';
import OfficeDetails from './OfficeDetails';

const mapStateToProps = state => ({
  // orgMembersList: state.project.orgMembersList,
  // memberListIsLoading: state.project.memberShortListIsLoading,
  publicZoneList: state.office.publicZoneList,

  addOrUpdateOfficeLoading: state.office.addOrUpdateOfficeLoading,
  officeDetails: state.office.officeDetails,
  officeCreateUpdateSuccess: state.office.officeCreateUpdateSuccess,
});

const mapDispatchToProps = dispatch => ({
  // getOrganizationMembersList: payload =>
  //   dispatch(getOrganizationMembersList(payload)),
  createOffice: payload => dispatch(createOffice(payload)),
  updateOffice: payload => dispatch(updateOffice(payload)),
  clearOfficeDetails: () => dispatch(clearOfficeDetails()),
  getPublicZoneList: params => dispatch(getPublicZoneList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfficeDetails);
