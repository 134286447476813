import i18next from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../../assets/img/avatar_5.svg';
import arrowWhiteIcon from '../../../assets/img/icons/arrow_right_white.svg';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent';
import AssigneeTooltip from '../../../components/Tooltip/AssigneeTooltip';
import {
  TableItem,
  TableRowAndBudgetCollapseContainer,
} from '../../../styledComponents/ProjectsNew';
import {
  CommonFlex,
  Container,
  TableText,
} from '../../../styledComponents/common';
import { CheckListStatus, SubTaskTable } from '../../../styledComponents/tasks';
import { ExpandIcon, ProjectExpandButton } from '../../Projects/projectsStyles';
import TaskEvidence from '../TaskDetails/taskEvidence';
import {
  AssigneeRoundImg,
  CollapseSection,
  SubTaskInfoSection,
} from '../tasksStyles';

const SubTasksList = ({
  selectedOrganization,
  expandedTaskId,
  setExpandedTaskId,
  visibilityChange,
  loadingTaskAssigneeId,
  toggleDeleteModal,
  isLoading,
  subTasksList,
  createTask,
  history,
}) => {
  const [duplicateTooltip, setDuplicateTooltip] = useState(null);

  const handleExpandBudgetSection = taskId => {
    if (expandedTaskId && expandedTaskId === taskId) {
      setExpandedTaskId(null);
    } else {
      setExpandedTaskId(taskId);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      {isLoading ? (
        <Container style={{ paddingBottom: '20px' }}>
          <ComponentCircleLoader />
        </Container>
      ) : subTasksList && subTasksList.length > 0 ? (
        <CommonFlex direction="column" width="100%">
          {subTasksList.map((task, index) => {
            return (
              <Container width="100%" overflow="hidden" key={task.id}>
                <SubTaskTable heading>
                  <TableItem>
                    <TableText>{t('sub_task_name')}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t('zone')}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t('location_name')}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t('assignee')}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t('status')}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t('employee_note')}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>{t('supervisor_note')}</TableText>
                  </TableItem>
                  <TableItem />
                </SubTaskTable>
                <TableRowAndBudgetCollapseContainer key={task.id}>
                  <SubTaskTable>
                    <TableItem
                    // style={{
                    //   gridTemplateColumns: 'auto auto',
                    //   alignItems: 'center',
                    //   gridColumnGap: '10px',
                    // }}
                    >
                      <TableText>{task.name}</TableText>
                    </TableItem>
                    <TableItem>
                      <TableText name>
                        {task.zone_name && task.zone_name}{' '}
                        {task.public_zone_name
                          ? `- ${task.public_zone_name}`
                          : ''}
                      </TableText>
                    </TableItem>
                    <TableItem>
                      <TableText name>
                        {task.location &&
                          task.location.name &&
                          task.location.name}
                      </TableText>
                    </TableItem>
                    <TableItem padding="10px 0 10px 10px">
                      {task.assignees &&
                      task.assignees.length > 0 &&
                      task.assignees[0].id ? (
                        <AssigneeRoundImg
                          image={task.assignees[0].avatar || avatar5}
                        >
                          <AssigneeTooltip
                            toolTipText={`${task.assignees[0].first_name}${
                              task.assignees[0].last_name
                                ? ` ${task.assignees[0].last_name}`
                                : ``
                            }`}
                            phone={task.assignees[0].phone}
                            top="-60px"
                            left="-55px"
                          />
                        </AssigneeRoundImg>
                      ) : null}
                    </TableItem>
                    <TableItem>
                      <CheckListStatus
                        status={task.status ? task.status : 'to do'}
                      >
                        {i18next.language && i18next.language === 'en'
                          ? t(task?.status).toUpperCase()
                          : t(task?.status)}
                      </CheckListStatus>
                    </TableItem>
                    <TableItem>
                      <TableText name>{task?.employee_note}</TableText>
                    </TableItem>
                    <TableItem>
                      <TableText name>{task?.supervisor_note}</TableText>
                    </TableItem>
                    <TableItem alignContent="start">
                      <CommonFlex gap="12px" wrap="wrap">
                        <ProjectExpandButton
                          margin="0"
                          onClick={() => handleExpandBudgetSection(task.id)}
                        >
                          <ExpandIcon
                            src={arrowWhiteIcon}
                            alt=""
                            open={expandedTaskId && expandedTaskId === task.id}
                          />
                        </ProjectExpandButton>{' '}
                      </CommonFlex>
                    </TableItem>
                  </SubTaskTable>
                  <CollapseSection
                    open={expandedTaskId && expandedTaskId === task.id}
                  >
                    <SubTaskInfoSection>
                      <TaskEvidence evidences={task?.evidences} />
                    </SubTaskInfoSection>
                  </CollapseSection>
                </TableRowAndBudgetCollapseContainer>
              </Container>
            );
          })}
        </CommonFlex>
      ) : (
        <NoDataComponent
          title={t('no_tasks_found')}
          padding="5vh 0"
          imageHeight="140px"
        />
      )}
    </>
  );
};

export default SubTasksList;
