import { t } from 'i18next';
import {
  LOGIN_PROFILE_CREATED_RESET,
  REDEEM_DISCOUNT_CODE,
  REDEEM_DISCOUNT_CODE_FAILURE,
  REDEEM_DISCOUNT_CODE_SUCCESS,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

// ------------------------------------
// Action handler methods
// ------------------------------------
export const handleRedeemRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const handleRedeemRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification('success', 'Coupons added successfully', 2000);
    return {
      ...state,
      isLoading: false,
      registerNeeded: false,
      invalidCode: [],
      alreadyApplied: false,
      redirect: true,
    };
  }
  return {
    ...state,
    registerNeeded: false,
    invalidCode: [],
    isLoading: false,
    alreadyApplied: false,
  };
};

export const handleRedeemRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification('error', t('No internet connection found'), 3000);
    return {
      ...state,
      isLoading: false,
      redirect: false,
    };
  }
  let invalid = [];
  if (response && response.data && response.data.code) {
    invalid = response.data.code;
  }
  let registrationNeeded = false;
  if (
    response &&
    response.data &&
    response.data.email &&
    response.data.email[0] === 'Invalid email address. Please signup'
  ) {
    registrationNeeded = true;
  }
  let alreadyAppliedCoupon = false;
  if (
    response &&
    response.data &&
    response.data.email &&
    response.data.email[0] === 'Already applied a percentage coupon'
  ) {
    alreadyAppliedCoupon = true;
  }
  return {
    ...state,
    isLoading: false,
    invalidCode: invalid,
    registerNeeded: registrationNeeded,
    alreadyApplied: alreadyAppliedCoupon,
    redirect: false,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [REDEEM_DISCOUNT_CODE]: handleRedeemRequest,
  [REDEEM_DISCOUNT_CODE_SUCCESS]: handleRedeemRequestSuccess,
  [REDEEM_DISCOUNT_CODE_FAILURE]: handleRedeemRequestFailure,
  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  redirect: false,
  isLoading: false,
  invalidCode: [],
  registerNeeded: false,
  passwordChanged: false,
  alreadyApplied: false,
};

export default function resetPasswordReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
