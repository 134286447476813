import { useLoadScript } from '@react-google-maps/api';
import moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonIcon, ColoredButtonWithIcon } from 'styledComponents/buttons';

import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import RightSlidingPanel from '../../../components/RightSlidingPanel/RightSlidingPanel';
import {
  CommonFlex,
  CommonText,
  Container,
  PageTitle,
} from '../../../styledComponents/common';
import { getDistanceFromCoords } from '../../../utils/helper';
import { DataAndMapContainer } from '../JobSites/jobSitesStyles';
import RouteMapFilter from './RouteMapFilter/filterContainer';
import RouteMapGoogleMap from './RouteMapGoogleMap/RouteMapGoogleMap';
import Timeline from './Timeline/timelineContainer';
import { MapAndAlertContainer, MapTopAlert } from './routeMapStyles';

const mapLibraries = ['geometry'];
const mapIds = [process.env.REACT_APP_JOB_SITES_MAP_ID];
const markerColors = ['#3887EE', '#ff4d4d', '#27ae60', '#7d70b3'];

const RouteMap = ({
  locationsIsLoading,

  getRouteMapLocations,
  // getSingleEmployeeClockInOutList,
  // getTaskCheckInOutList,

  routeMapLocations,
  clockInOutList,
  taskCheckInOutList,
}) => {
  const { t } = useTranslation();
  const [selectedMember, setSelectedMember] = useState();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedOffice, setSelectedOffice] = useState(null);

  const [map, setMap] = useState(null);
  // const [linePoints, setLinePoints] = useState([]);
  const [markers, setMarkers] = useState([]);

  const [taskCheckInOutMarkers, setTaskCheckInOutMarkers] = useState([]);
  const [clockInOutMarkers, setClockInOutMarkers] = useState([]);
  const [routes, setRoutes] = useState([]);

  const [eventsList, setEventsList] = useState([]);

  const [userCurrentLocation, setUserCurrentLocation] = useState(null);

  // timeline panel
  const [timelineData, setTimelineData] = useState(null);
  const [timelineIsOpen, setTimelineIsOpen] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    id: 'route-map-google-maps-script-loader',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY,
    libraries: mapLibraries,
    mapIds,
  });

  const buildMarkerIcon = (
    location,
    nextLocation,
    attendanceIndex,
    options = {},
  ) => {
    if (map) {
      const g = window.google.maps;
      let anchor;
      let fillColor =
        markerColors[attendanceIndex % markerColors.length] || markerColors[0];
      let scale = options.scale || 3;
      let fillOpacity = 1;

      const heading = g.geometry.spherical
        .computeHeading(location, nextLocation)
        .toFixed(1);

      return {
        // path,
        rotation: parseFloat(heading),
        scale,
        anchor,
        fillColor,
        fillOpacity,
        strokeColor: '#fff',
        strokeWeight: 1,
        strokeOpacity: 1,
      };
    }
    return null;
  };

  const parseLocation = location => {
    return {
      lat: parseFloat(location.lat),
      lng: parseFloat(location.long),
    };
  };
  const addLocation = (locations, location) => {
    const { lat, lng } = parseLocation(location);
    locations.push({ lat, lng });
  };
  const handleDirectionMarkers = (
    directionMarkers,
    data,
    attendanceIndex,
    index,
    remainingLocations,
    clockInTimestamp,
    clockOutTimestamp,
  ) => {
    if (index > 0 && index < remainingLocations.length - 1) {
      const lastMarkerLoc =
        directionMarkers.length > 0
          ? directionMarkers[directionMarkers.length - 1].location
          : null;
      if (
        !clockOutTimestamp ||
        (data.timestamp > clockInTimestamp &&
          data.timestamp < clockOutTimestamp)
      ) {
        if (lastMarkerLoc) {
          const dist = getDistanceFromCoords(lastMarkerLoc, data.location);
          if (dist > 150) {
            const nextLocation = parseLocation(remainingLocations[index + 1]);
            data.icon = buildMarkerIcon(
              data.location,
              nextLocation,
              attendanceIndex,
            );
            directionMarkers.push(data);
          }
        } else {
          const nextLocation = parseLocation(remainingLocations[index + 1]);
          directionMarkers.push({
            ...data,
            icon: buildMarkerIcon(data.location, nextLocation, attendanceIndex),
          });
        }
      }
    }
  };
  const handleLocations = (
    locations,
    data,
    removedIndices,
    index,
    remainingLocations,
    clockInTimestamp,
    clockOutTimestamp,
  ) => {
    if (locations.length > 0 && index < remainingLocations.length - 1) {
      const lastLoc = locations[locations.length - 1];
      const dist = getDistanceFromCoords(lastLoc, data.location);
      if (dist <= 15) {
        removedIndices.add(index);
        return;
      }
    }

    if (
      !clockOutTimestamp ||
      (data.timestamp > clockInTimestamp && data.timestamp < clockOutTimestamp)
    ) {
      locations.push(data.location);
      removedIndices.add(index);
    }
  };

  const calculateRoute = useMemo(() => {
    const processLocations = () => {
      const polylines = [];
      const directionMarkers = [];

      let remainingLocations = [...routeMapLocations];

      for (const [attendanceIndex, clockInOut] of clockInOutList.entries()) {
        // const {
        const clockin_timestamp = clockInOut.checkin_timestamp;
        const clockout_timestamp = clockInOut.checkout_timestamp;
        const clockin_lat = clockInOut.checkin_lat;
        const clockin_long = clockInOut.checkin_long;
        const clockout_lat = clockInOut.checkout_lat;
        const clockout_long = clockInOut.checkout_long;
        // } = clockInOut;
        if (!clockin_timestamp) {
          continue;
        }
        const locations = [];
        const removedIndices = new Set();

        addLocation(locations, { lat: clockin_lat, long: clockin_long });

        remainingLocations.forEach((location, index) => {
          const { id, timestamp } = location;
          const locationData = {
            id,
            location: parseLocation(location),
            timestamp,
          };

          handleDirectionMarkers(
            directionMarkers,
            locationData,
            attendanceIndex,
            index,
            remainingLocations,
            clockin_timestamp,
            clockout_timestamp,
          );
          handleLocations(
            locations,
            locationData,
            removedIndices,
            index,
            remainingLocations,
            clockin_timestamp,
            clockout_timestamp,
          );
        });

        if (clockout_timestamp) {
          addLocation(locations, { lat: clockout_lat, long: clockout_long });
        }

        remainingLocations = remainingLocations.filter(
          (_, index) => !removedIndices.has(index),
        );
        polylines.push({ points: locations });
      }

      return { polylines, directionMarkers };
    };

    return clockInOutList.length > 0 && routeMapLocations.length > 0
      ? processLocations()
      : [];
  }, [clockInOutList, routeMapLocations]);

  useEffect(() => {
    const { polylines, directionMarkers } = calculateRoute;
    setRoutes(polylines?.length > 0 ? polylines : []);
    setMarkers(directionMarkers?.length > 0 ? directionMarkers : []);
    // setRoutes(calculateRoute);
  }, [calculateRoute]);

  useEffect(() => {
    let events = [];

    // const taskMarkers =
    //   taskCheckInOutList?.map(event => ({
    //     location: parseLocation(event),
    //     timestamp: event.timestamp,
    //     event: event.event,
    //   })) || [];
    // if (taskMarkers.length > 0) {
    //   events = [...events, ...taskCheckInOutList];
    //   setTaskCheckInOutMarkers(taskMarkers);
    // } else {
    //   setTaskCheckInOutMarkers([]);
    // }
    const attendanceMarkers =
      clockInOutList?.reduce((markers, event) => {
        if (event.checkin_timestamp) {
          markers.push({
            location: {
              lat: parseFloat(event.checkin_lat),
              lng: parseFloat(event.checkin_long),
            },
            timestamp: event.checkin_timestamp,
            event: 'checkin',
          });
        }
        if (event.checkout_timestamp) {
          markers.push({
            location: {
              lat: parseFloat(event.checkout_lat),
              lng: parseFloat(event.checkout_long),
            },
            timestamp: event.checkout_timestamp,
            event: 'checkout',
          });
        }
        return markers;
      }, []) || [];
    setClockInOutMarkers(attendanceMarkers);

    if (attendanceMarkers.length > 0) {
      events = [...events, ...attendanceMarkers];
    }

    setEventsList(events.sort((a, b) => a.timestamp - b.timestamp));
  }, [taskCheckInOutList, clockInOutList]);

  // useEffect(() => {
  //   console.log(clockInOutList);
  // }, [clockInOutList]);

  useEffect(() => {
    fetch('https://hutils.loxal.net/whois')
      .then(resp => resp.json())
      .then(res => {
        if (res && res.ip) {
          setUserCurrentLocation({
            lat: res.latitude,
            lng: res.longitude,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (map && userCurrentLocation) {
      if (!routes || (routes && !routes.length)) {
        map.panTo(userCurrentLocation);
      }
    }
  }, [map, userCurrentLocation]);

  const toggleTimeline = value => {
    setTimelineIsOpen(value);
    if (!value) {
      setTimelineData(null);
    }
  };

  return (
    <div className="content">
      <RightSlidingPanel
        isOpen={timelineIsOpen}
        closePanel={() => toggleTimeline(false)}
        width="530px"
      >
        <Timeline
          eventsList={eventsList}
          selectedDate={selectedDate}
          selectedMember={selectedMember}
        />
      </RightSlidingPanel>
      <PageTitle>{t('route_map')}</PageTitle>
      <CommonFlex justifyContent="space-between">
        <RouteMapFilter
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
          selectedOffice={selectedOffice}
          setSelectedOffice={setSelectedOffice}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          getRouteMapLocations={getRouteMapLocations}
        />
        <ColoredButtonWithIcon
          style={{ alignSelf: 'center' }}
          onClick={() => toggleTimeline(true)}
        >
          {t('see_details')}
        </ColoredButtonWithIcon>
      </CommonFlex>

      <MapAndAlertContainer>
        {locationsIsLoading ? (
          <MapTopAlert fontSize="15px" height="48px">
            <ComponentCircleLoader padding="10px 0" size={16} color="#fff" />
          </MapTopAlert>
        ) : !routeMapLocations ||
          (routeMapLocations && !routeMapLocations.length) ? (
          <MapTopAlert fontSize="15px" height="48px">
            {t('There_is_no_location_data_for_this_user')}
          </MapTopAlert>
        ) : null}
        {loadError ? (
          <div>Map cannot be loaded right now! Please try again later</div>
        ) : isLoaded ? (
          <RouteMapGoogleMap
            map={map}
            setMap={setMap}
            // linePoints={linePoints}
            routes={routes}
            markers={markers}
            selectedTimesheet={null}
            taskMarkers={clockInOutMarkers}
            // taskMarkers={taskCheckInOutMarkers}
            // clockInOutMarkers={clockInOutMarkers}
            clockInOutMarkers={taskCheckInOutMarkers}
          />
        ) : (
          <ComponentCircleLoader />
        )}
      </MapAndAlertContainer>
    </div>
  );
};

export default RouteMap;
