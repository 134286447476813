import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CommonFlex, Container, FormAlert } from 'styledComponents/common';
import {
  CheckListNameInputField,
  TaskStatusButton,
} from 'styledComponents/tasks';

const ApprovalSection = ({ task_id, updateTaskStatusRequest, percentage }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [saveErrors, setSaveErrors] = useState();
  const [name, setName] = useState('');
  const [creating, setCreating] = useState(false);
  const handleSave = status => {
    status === 'approved' &&
      updateTaskStatusRequest({
        approval_status: 'approved',
        supervisor_note: name,
      });
    status === 'rejected' &&
      updateTaskStatusRequest({
        approval_status: 'rejected',
        supervisor_note: name,
      });

    setName('');
    setCreating(false);
  };
  const handleCreateNotCompleted = () => {
    history.push(`/user/create-not-completed-task?id=${task_id}`);
  };
  const handleChange = value => {
    setName(value);
    setSaveErrors(null);
  };

  const cancelFunction = () => {
    setCreating(false);
  };

  useEffect(() => {}, [saveErrors]);

  return (
    <>
      <Container padding="12px" marginTop="0">
        <CheckListNameInputField
          type="text"
          placeholder={t('write_a_comment')}
          value={name}
          onChange={e => handleChange(e.target.value)}
          border="none"
          style={
            saveErrors && {
              border: `1px solid #fe5969`,
              backgroundColor: `rgba(252, 87, 104, 0.05)`,
            }
          }
        />
        {saveErrors && <FormAlert>{saveErrors}</FormAlert>}
      </Container>
      <CommonFlex gap="10px" wrap="wrap" justifyContent="center">
        <TaskStatusButton
          status={'rejected'}
          onClick={() => handleSave('rejected')}
          cursor={'pointer'}
        >
          {i18n.language && i18n.language === 'en'
            ? t('reject').toUpperCase()
            : t('reject')}
        </TaskStatusButton>
        <TaskStatusButton
          status={'approved'}
          onClick={() => handleSave('approved')}
          cursor={'pointer'}
        >
          {i18n.language && i18n.language === 'en'
            ? t('approve').toUpperCase()
            : t('approve')}
        </TaskStatusButton>
        {percentage !== 100 && (
          <TaskStatusButton
            status={'pending'}
            onClick={() => handleCreateNotCompleted()}
            cursor={'pointer'}
          >
            {i18n.language && i18n.language === 'en'
              ? t('create_not_completed').toUpperCase()
              : t('create_not_completed')}
          </TaskStatusButton>
        )}
      </CommonFlex>
    </>
  );
};

export default ApprovalSection;
