//Effects
import { all, put, takeLatest } from 'redux-saga/effects';

import {
  GET_ROUTE_MAP_LOCATIONS,
  GET_ROUTE_MAP_LOCATIONS_FAILURE,
  GET_ROUTE_MAP_LOCATIONS_SUCCESS,
  GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST,
  GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST_FAILURE,
  GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST_SUCCESS,
  GET_TASK_CHECK_IN_OUT_LIST,
  GET_TASK_CHECK_IN_OUT_LIST_FAILURE,
  GET_TASK_CHECK_IN_OUT_LIST_SUCCESS,
} from '../../../modules/constants';
//Service
import { defaultApi } from '../../../utils/axiosApi';

export function* getRouteMapLocationsRequest({ payload }) {
  if (!payload) return;

  const { employee, start, end } = payload;

  try {
    const url = `albait/locations/?from=${start}&to=${end}&employee=${employee}`;

    const response = yield defaultApi(url, 'GET');

    yield put({
      type: GET_ROUTE_MAP_LOCATIONS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_ROUTE_MAP_LOCATIONS_FAILURE,
      payload: err,
    });
  }
}

export function* getSingleEmployeeClockInOutList({ payload }) {
  if (!payload) return;

  const { employee, start, end } = payload;

  try {
    const url = `albait/attendaces/?from=${start}&to=${end}&employee_id=${employee}&platform=web`;

    const response = yield defaultApi(url, 'GET');

    yield put({
      type: GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST_SUCCESS,
      payload: { data: response, user_id: employee },
    });
  } catch (err) {
    yield put({
      type: GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getTaskCheckInOutList({ payload }) {
  if (!payload) return;

  const { employee, start, end } = payload;

  try {
    const url = `tasks/checkin/?from=${start}&to=${end}&assignee=${employee}`;

    const response = yield defaultApi(url, 'GET');

    yield put({
      type: GET_TASK_CHECK_IN_OUT_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_TASK_CHECK_IN_OUT_LIST_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_ROUTE_MAP_LOCATIONS, getRouteMapLocationsRequest),
    takeLatest(
      GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST,
      getSingleEmployeeClockInOutList,
    ),
    takeLatest(GET_TASK_CHECK_IN_OUT_LIST, getTaskCheckInOutList),
  ]);
}
